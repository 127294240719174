export const extrapayDictPT = {
  extrapayArea: "Área Extrapay",
  conversionExpiration: "Data de expiração",
  conversionRate: "Taxa de conversão",
  extrapayRegulation: "*Clique no cartão para ver o regulamento",
  noConversions: "Não possui conversões disponíveis",
  convert: "Converter",
  convertExtracoinToWallet: "Converter Extracredit para a carteira",
  errorConversionIsZero: "Erro ao converter, valor de conversão é zero",
  successConversion: "Conversão realizada com sucesso",
  errorExpiredConversion: "Erro ao converter, conversão expirada",
};
