const shoppingCartDictEN = {
  productType: "Product type:",
  soldBy: "Sold by:",
  pickUpInStore: "Pick up in store",
  orderResume: "Order Summary",
  shippingOptions: "Shipping options",
  checkCatalog: "Check our catalog",
  emptyCartText: "Your shopping cart is empty.",
  emptyCartSuggestion: "Add items to your cart and enjoy the marketplace that always rewards you.",
  percentualCreditInfo: "Percentage credit information",
  shop: "Shop",
  searchProducts: "Search products...",
  searchByTitleInThisSubcategory: "Search in this subcategory...",
  byCap: "By",
  product: "Product",
  noResultsFound: "No results found.",
  shoppingCart: "Shopping cart",
  cart: "Cart",
  subtotal: "Subtotal",
  onlyOneSeller: "You can only buy products from one seller.",
  items: "items",
  platformFee: "Platform fee",
  total: "Total",
  walletBalance: "Your current wallet balance",
  amountToPayWithWalletBalance: "Pay with your wallet balance",
  amountToPayWithExtracredit: "Pay with Extracredit",
  sendYourTransferReceipt: "Send your transfer receipt here",
  youWillNeedToTransfer: "You will need to transfer",
  toTheBankAccount: "to the bank account",
  andUploadTheReceipt: "and upload the receipt",
  payWithBankTransfer: "Pay by bank transfer",
  finishOrder: "Finish order",
  searching: "Searching",
  inSubcategory: "in subcategory",
  inAllProducts: "in all products",
  productMaximumExtracoinPercent: "Maximum Extracredit percentage (Product)",
  userMaximumExtracoinPercent: "Maximum Extracredit percentage (User)",
  checkout: "Checkout",
  amountToPayAtCheckout: "Amount to pay at checkout",
  orderData: "Order data",
  howWillPay: "How you will pay",
  otherMethods: "Other methods",
  creditRequested: "Credit requested",
  moreDetails: "more details",
  productNotFound: "Product not found. Try searching in our catalog.",
  downloadableAndNonDownloadable:
    "Downloadable and non-downloadable products can't be in the same cart.",
  balanceAvailable: "Extracredit balance available",
  freeExtracoinExplanation1: "As a newly-registered",
  freeExtracoinExplanation2:
    ", you'll be granted a full Extracredit payment for free in your first three orders, meaning you'll pay the minimum purchase amount without spending your Extracredit credits.",
  itemFavorited: "Item added to favorites.",
  itemRemovedFromFavorites: "Item removed from favorites.",
  info: "Info",
  searchForShipping: "Search shipping options for this mailing address",
  shippingQuotesFor: "Shipping quotes for",
  pleasePickASendMethod: 'Click the "Shipping options" button and then select a shipping method',
  shippingCost: "Shipping cost",
  searchAddress: "Auto Fill with ZIP Code",
  shippingResponsibilitySeller: "Shipping is the responsibility of the seller",
  plataformFeeExplanation: "The platform fee is 5% of the total value of the products.",
  cashbackExplanation:
    "Any amount that is not paid in extracredit will have 50% of it converted into cashback, returning to your wallet in extracredit.",
  thankYouForYourPurchase: "Thank you for your purchase",
  pleaseFillAllFieldsAddressOrSearchShippingMethod:
    "Please fill in all address fields or click on shipping options.",
  deliveryAddress: "Delivery address",
  pickUpAddress: "Pick up your order at",
  orderId: "Order ID",
  orderDate: "Order Date",
  orderTotal: "Order Total",
  extraconomyExplain:
    "You have the flexibility to use your Extracredit balance and/or wallet balance to pay for your order. Choose below how much you'd like to use from each to complete your purchase.",
  orderInformation: "Order Information",
  goToMyShoppings: "Go to my purchases",
  errorDontHavePhoneNumber:
    "You do not have a registered phone number. Fill in your phone number and Country Phone Code to continue.",
  insufficientFunds: "insufficient funds",
  errorCheckout:
    "There was an error completing your order. Please contact support via the email below. And don't forget to provide him with the information below.",
  contactEmail: "Get in touch",
  yourOrderWillBeProcessed: "Your order will be processed once the transfer has been confirmed.",
  weWillNotifyYou: "We will notify you by e-mail after confirmation."
};

export default shoppingCartDictEN;
