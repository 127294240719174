import localeText from "../../../../locale";

const LandingFeatures = ({ features }) => {
  return (
    <div className="grid lg:pr-5 lg:pl-5 mt-6">
      {features.map((feature, i) => (
        <div key={`${feature.title}-${i}`} className="col-12 md:col-12 lg:col-4 p-3">
          <div>
            <i className={`${feature.icon}`} style={{ fontSize: "4em", color: "#5fb463" }}></i>
            <h3 className="text-primary font-bold text-2xl">{localeText(feature.title)}</h3>
            <p className="text-gray-500 text-lg lg:text-xl mx-auto" style={{ maxWidth: "30ch" }}>
              {localeText(feature.desc)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LandingFeatures;
