import { useEffect, useState } from "react";
import ProductService from "../service/ProductService";
import { parseProduct } from "../utilities/parsers/product";

const useRelatedProducts = ({ productId, onError }) => {
  const [loading, setLoading] = useState(true);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const productService = new ProductService();

  const loadRelatedProducts = async () => {
    setLoading(true);
    const resRelated = await productService.getRelatedProducts(productId);

    if (resRelated.messageCode !== 200001) {
      onError(resRelated.messageCode);
      if (resRelated.messageCode === 500000) console.log(resRelated);
      setLoading(false);
      return;
    } else setRelatedProducts(resRelated.result.map(parseProduct));

    setLoading(false);
  };

  useEffect(() => {
    if (productId) loadRelatedProducts(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return { loading, relatedProducts };
};

export default useRelatedProducts;
