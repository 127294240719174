const planListDictIT = {
  specialCustomer: "Customer",
  physicalPerson: "Persona fisica",
  legalPerson: "Persona Giuridica",
  for: "per",
  free: "Gratuita",
  joinPlan: "Entra!",
  instantlyEarnPlanList: "Ricevi ",
  forYourPurchase: " di Extrabonus",
  fisrtSpecialCustomerExplanation: "Puoi acquistare beni e servizi sulla nostra piattaforma.",
  secondSpecialCustomerExplanation:
    "Se scarichi la nostra app ufficiale per iscriverti, guadagnerai crediti Extracredit gratuiti.",
  businessPartner: "Partner",
  taxes: "tax",
  businessPartnerExplanation: "Puoi registrare tutti i tuoi profili",
  businessPartnerExplanationCont: "Possibilità di vendita con vetrina",
  businessPartnerExplanationCont2: "Rinnovo annuale di ",
  areaManager: "Manager",
  areaManagerExplanation:
    "Il piano più completo e vantaggioso per aumentare automaticamente i tuoi clienti e il tuo potere economico attraverso la nostra tecnologia all'avanguardia.",
  areaManagerExplanationCont: "Vinci la tua campagna pubblicitaria iscrivendoti adesso!",
  taxExplanation: "* imposta sulle vendite in accordo con il tuo Paese.",
  sellerExplanation:
    "Apri il tuo negozio elettronico di prodotti e/o servizi in modo semplice e inizia a fare velocemente il tuo business.",
  sellerExplanation2:
    "Iscriviti ora alla nostra piattaforma esclusiva e innovativa senza pagamenti mensili.",
  sellerExplanationRegister:
    "Apri il tuo negozio elettronico di prodotti e/o servizi in modo semplice e inizia a fare velocemente il tuo business. Ideale per chi ha l'esigenza di offrire prodotti e servizi in modo veloce, pratico e semplice attraverso una potente piattaforma. Pagando una quota di registrazione di 3500 EUR, potrai usufruire dei seguenti vantaggi:",
  sellerExplanationRegister2:
    "Ricevi subito un credito del valore di 3.500,00 EUR per i tuoi acquisti futuri (+ 1.500,00 EUR se sei uno dei primi 1000 membri nel tuo Paese a registrare la tua azienda come Venditore).",
  sellerExplanationRegister3:
    "Acquista il tuo spazio e-commerce per pubblicizzare e vendere i tuoi prodotti e servizi.",
  knowmore: "saperne di più",
  sellPlans: "Piano di vendita",
  silverPro1: "Ricevi ",
  canSellWithEcommerce: "Puoi vendere con e-commerce",
  geolocalizedStore: "Negozio on-line geolocalizzato",
  allInclusive: "All inclusive",
  noMonthlyPayments: "Nessuna mensilità",
  unlimitedAds: "Annunci illimitati",
  getsPersonalCommerce: "Ottiene e-commerce personale",
  openYourOnlineShop: "Apri la tua vetrina on-line",
  earnCommission: "Guadagna commissioni",
  onlineShop: "Negozio on-line",
  activeGeolocation: "Geolocalizzazione attiva",
  personalEcommerce: "E-commerce personale",
  goToDropShipping: "Go to Drop-shipping",
  onlineShowcase: "Vetrina on-line",
  activeCommission: "Commissione attiva",
};

export default planListDictIT;
