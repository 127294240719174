import React from "react";
import HeaderSection from "../../../Shop/components/HeaderSection/HeaderSection";
import useRelatedProducts from "../../../../hooks/useRelatedProducts";
import Loading from "../../../../components/Loading/Loading";
import localeText from "../../../../locale";
import ProductCard from "../ProductCard/ProductCard";
import Carousel from "../../../../components/Carousel/Carousel";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";

const ProductTemplate = ({ product }) => {
  return (
    <div className="product-item w-full">
      <div className="product-item-content w-full flex justify-content-center">
        <ProductCard product={product} />
      </div>
    </div>
  );
};

const RelationedProducts = ({ productInfo }) => {
  const { showErrorToast } = useToastContext();
  const { loading, relatedProducts } = useRelatedProducts({
    productId: productInfo.data.product.id,
    onError: (code) => showErrorToast({ code }),
  });

  if (loading) return <Loading />;
  if (!relatedProducts || relatedProducts.length === 0) return null;

  return (
    <>
      <HeaderSection classNameTitle="pb-3" disableBoxModel>
        {localeText("relatedProducts")}
      </HeaderSection>
      <Carousel
        items={relatedProducts.map((product) => (
          <ProductTemplate product={product} />
        ))}
      />
    </>
  );
};

export default RelationedProducts;
