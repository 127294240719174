const statementTableDictES = {
  walletStatement: "Extracto de billetera",
  extracoinStatement: "Extracto de Extracredit",
  extrabonusStatement: "Extracto de Extrabonus",
  bonusStatement: "Extracto de la renta diaria vitalicia",
  from: "De",
  toFrom: "Hasta",
  check: "Ver",
  statement: "Extracto",
  description: "Descripción",
  status: "Status",
  dateTransaction: "Fecha de la transacción",
  emptyDates: "Por favor elija un periodo de dos fechas.",
  completed: "Completa",
  pending: "Pendiente",
  pendingVoucher: "Voucher pendiente",
  canceled: "Cancelada",
};

export default statementTableDictES;
