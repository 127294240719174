import { useEffect, useState } from "react";
import GetUserInfoService from "../service/GetUserInfoService";
import localeText from "../locale";

const useStoreName = ({ username, onError }) => {
  const [storeName, setStoreName] = useState("");
  const [loadingStoreName, setLoadingStoreName] = useState(true);

  const getUserInfoService = new GetUserInfoService();

  const loadStoreName = async (cancel) => {
    try {
      if (username) {
        setLoadingStoreName(true);

        const res = await getUserInfoService.getUserShop(username);
        if (cancel) return;
        if (res.messageCode !== 200001) {
          onError(res.messageCode);
          if (res.messageCode === 500000) console.log(res);
        } else {
          if (!res.result.shopConfiguration) setStoreName(localeText("shop"));
          else setStoreName(res.result.shopConfiguration.title);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingStoreName(false);
    }
  };

  useEffect(() => {
    let cancel = false;
    loadStoreName(cancel);
    return () => {
      setLoadingStoreName(false);
      setStoreName("");
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  return {
    storeName,
    loadingStoreName,
  };
};

export default useStoreName;
