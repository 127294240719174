import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import localeText from "../../../../../locale";
import useAskQuestion from "../hooks/useAskQuestion";

const AskModal = ({
  visible,
  setAsFalse,
  headerText,
  idProduct,
  setQuestions,
}) => {
  const handleClose = () => {
    if (isLoading) return;
    setAsFalse();
    setText("");
  };
  const { text, setText, isLoading, postAsk } = useAskQuestion({
    idProduct,
    setAsFalse: handleClose,
    setQuestions,
  });

  return (
    <Dialog
      header={headerText}
      visible={visible}
      className="modal-review-rating"
      onHide={handleClose}
      draggable={false}
    >
      <div>
        <InputTextarea
          value={text}
          className="w-full"
          style={{ resize: "none" }}
          onChange={(e) => setText(e.target.value)}
          rows={5}
          cols={30}
        />
        <div className="flex justify-content-end mt-2">
          <Button onClick={postAsk} loading={isLoading}>
            <span className="ml-2">{localeText("sendQuestion")}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AskModal;
