import showErrorToast from "../../../../utilities/Toast/showErrorToast";
import useShopSections from "../../hooks/useShopSections";
import PrimaryBanner from "../PrimaryBanner/PrimaryBanner";
import SecondaryBanner from "../SecondaryBanner/SecondaryBanner";
import SectionProducts from "../SectionProducts/SectionProducts";

const ShopSections = ({ toast }) => {
  const { shopSections, isLoading } = useShopSections({ onError: showErrorToast(toast.current) });

  if (isLoading) {
    return (
      <div className="text-center my-8">
        <i className="pi pi-spin pi-spinner text-8xl text-400"></i>
      </div>
    );
  }

  if (!shopSections?.length) return null;

  if (shopSections.length === 1) {
    return (
      <>
        <SectionProducts section={shopSections[0]} />
        <div className="flex flex-column gap-4">
          <PrimaryBanner />
          <SecondaryBanner />
        </div>
      </>
    );
  }

  shopSections.sort((a, b) => a.levelsection - b.levelsection);

  return shopSections.map((section, index) => (
    <section key={index}>
      <SectionProducts section={section} />
      {index === 0 && <PrimaryBanner />}
      {index === 1 && <SecondaryBanner />}
    </section>
  ));
};

export default ShopSections;
