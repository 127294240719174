export const extrapayDictES = {
  extrapayArea: "Área Extrapay",
  convertionExpiration: "Fecha de expiración",
  conversionRate: "Tasa de conversión",
  extrapayRegulation: "*Haz clic en la tarjeta para ver el reglamento",
  noConversions: "No hay conversiones disponibles",
  convert: "Convertir",
  convertExtracoinToWallet: "Convertir Extracredit a la billetera",
  errorConversionIsZero: "Error al convertir, el valor de conversión es cero",
  successConversion: "Conversión exitosa",
  errorExpiredConversion: "Error al convertir, conversión caducada",
};
