import { Dropdown } from "primereact/dropdown";
import React from "react";
import localeText from "../../../../../../locale";

const SellerTemplate = (seller) => {
  if (seller.title === "all")
    return (
      <div className="flex align-items-center">
        <div>{localeText(seller.title)}</div>
      </div>
    );

  return (
    <div className="flex align-items-center">
      <div>{seller.title}</div>
    </div>
  );
};

const SelectedSellerTemplate = (seller, props) => {
  if (props?.value) {
    const option = props?.options?.find((o) => o.user.username === props.value);

    if (option?.title === "all")
      return (
        <div className="flex align-items-center">
          <div>{localeText("all")}</div>
        </div>
      );

    return (
      <div className="flex align-items-center">
        <div>{option?.title}</div>
      </div>
    );
  }

  return <span>{props.placeholder}</span>;
};

const DropdownSearch = ({
  isDisabled,
  searchParams,
  storeNames,
  setSearchParams,
}) => {
  return (
    <div className="mb-3">
      <h4 className="text-lg mb-2">{localeText("store")}</h4>
      <Dropdown
        className={`w-full ${isDisabled}`}
        onChange={(e) => setSearchParams((prev) => ({ ...prev, seller: e.value.user.username }))}
        value={searchParams.seller}
        filter
        optionLabel="title"
        placeholder={localeText("selectStore")}
        options={storeNames}
        itemTemplate={SellerTemplate}
        valueTemplate={SelectedSellerTemplate}
      />
    </div>
  );
};

export default DropdownSearch;
