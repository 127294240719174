import { Dialog } from "primereact/dialog";
import React from "react";
import Comment from "../../pages/ProductInfo/components/Comment/Comment";
import "./styles.css";
import Loading from "../Loading/Loading";
import { Rating } from "primereact/rating";
import localeText from "../../locale";

const ReviewsModal = ({
  comments,
  visible,
  setAsFalse,
  headerText,
  isLoading,
  ratingOfStore,
  storeName,
}) => {
  return (
    <Dialog
      header={headerText}
      visible={visible}
      className="modal-review-rating"
      onHide={setAsFalse}
      draggable={false}
    >
      {isLoading ? (
        <Loading classNameIcon={"text-4xl"} />
      ) : (
        <div>
          {ratingOfStore && (
            <div className="flex my-2 flex-column justify-content-center align-items-center gap-1">
              <h3 className="text-2xl mb-0 text-center">{storeName}</h3>
              <div className="flex justify-content-center align-items-center">
                <Rating value={Math.ceil(ratingOfStore.rate)} readOnly stars={5} cancel={false} />

                {ratingOfStore.rate > 0 ? (
                  <p className="font-bold text-md text-gray-800 inline-block">
                    {parseFloat(ratingOfStore.rate.toFixed(2))}
                  </p>
                ) : (
                  <p className="font-bold text-xl">{localeText("noRatings")}</p>
                )}
              </div>
            </div>
          )}
          <div className="flex flex-column gap-5 mt-1">
            {comments.map((comment, i) => (
              <Comment
                key={i}
                comments={comment.comments}
                dateorder={comment.dateorder}
                rate={comment?.rateproduct ? comment?.rateproduct : comment?.rateseller}
                username={comment.username}
              />
            ))}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ReviewsModal;
