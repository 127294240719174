const productsListDictIT = {
  productImage: "Immagine Principale",
  title: "Titolo",
  priceUnique: "Prezzo",
  productState: "Stato",
  productStatus: "Status",
  published: "Pubblicato",
  unpublished: "Non pubblicato",
  planNotAllowed: "Il tuo piano attuale non ha accesso a questa risorsa.",
  upgradeToBusinessPartner: "Passa a Partner",
  upgradeToAreaManager: "Passa a Manager",
  assignAttributes: "Assegnare Attributi",
  goBackProductList: "Torna all'elenco dei prodotti",
  noVariations:
    "Questo prodotto non ha variazioni. Desideri generare automaticamente variazioni per questo prodotto?",
  generateVariations: "Genera Variazioni",
  manageVariations: "Gestisci le variazioni del prodotto",
  manageVariationsShort: "Gestisci le Variazioni",
  image: "Immagine",
  stock: "Scorta",
  delete: "Elimina",
  pleaseChooseImage: "Scegli un'immaginee.",
  theValuesOfTheseAttributes:
    "I valori di questi attributi verranno utilizzati per generare variazioni:",
  generateVariationsWithThese: "Genera variazioni con questi attributi?",
  goBackToVariationsList: "Torna all'elenco delle varianti",
  confirmChanges: "Conferma le Modifiche",
  pressThePencilIconTo: "Premi il pulsante della matita per modificare l'immagine della variazione",
  variationUpdatedSuccessfully: "Variazione del prodotto aggiornata correttamente!",
  variationMustHaveAValidPrice: "La variazione deve avere un prezzo valido.",
  variationDeletedSuccessfully: "Variazione eliminata correttamente.",
  deleteThisVariation: "Sei sicuro di voler eliminare questa variazione?",
  preview: "Anteprima",
  selectTwoAttributes: "Seleziona",
  and: "e",
  selectOneAttribute: "Selezion",
  unavailable: "Non disponibile",
  youMustFirstGenerateVariations:
    "Questo è un prodotto variabile. Devi prima generare variazioni per pubblicarlo.",
  clickHereToGenerateVariations: "Fare clic qui per generare variazioni per questo prodotto",
  publish: "Pubblicare",
  productPublishedSuccessfully: "Prodotto pubblicato con successo!",
  productUnpublishedSuccessfully: "Prodotto rimosso dal tuo negozio con successo.",
  seller: "Venditore",
  itemAlreadyInCart: "L'articolo è già nel carrello.",
  unpublish: "Annullare la pubblicazione",
  visibility: "Visibilità",
  downloadableProductExplanation:
    "Questo è un prodotto scaricabile. Puoi controllare una versione di esempio prima dell'acquisto per avere accesso al link di download effettivo.",
  searchByTitle: "Cerca per titolo",
  searchBySeller: "Cerca per venditore",
  searchByBuyer: "Cerca per acquirente",
  imported: "Importato",
  importOrigin: "Origine",
  archived: "Archiviare",
  archivedProduct: "Archiviato",
};

export default productsListDictIT;
