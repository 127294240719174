const message_IT = {
    "data": [
        {
            "code": 500000,
            "message": "Errore di sistema imprevisto"
        },
        {
            "code": 500001,
            "message": "Sponsor non trovato"
        },
        {
            "code": 500002,
            "message": "Errore di pagamento"
        },
        {
            "code": 500003,
            "message": "Erro no cálculo de câmbios"
        },
        {
            "code": 500004,
            "message": "Errore nel calcolo dei tassi di cambio"

        },
        {
            "code": 500005,
            "message": "Gateway di pagamento non trovato"
        },
        {
            "code": 500006,
            "message": "Piano non trovato"
        },
        {
            "code": 500007,
            "message": "La quantità è nulla o zero"
        },
        {
            "code": 500008,
            "message": "LValore è nullo o non c'è abbastanza saldo nel portafoglio"
        },
        {
            "code": 500009,
            "message": "Questo tipo di utente non può essere uno sponsor"
        },
        {
            "code": 500010,
            "message": "ID del piano deve corrispondere a un Partner"
        },
        {
            "code": 500011,
            "message": "Sponsor utente non trovato"
        },
        {
            "code": 500012,
            "message": "Utente non trovato"
        },
        {
            "code": 500013,
            "message": "Autenticazione utente fallita"
        },
        {
            "code":  500014,
            "message": "Errore durante la decodifica del token di sicurezza"
        },
        {
            "code": 500015,
            "message": "Questo token di sicurezza non appartiene all'utente della richiesta"
        },
        {
            "code": 500016,
            "message": "Nome utente non disponibile"
        },
        {
            "code": 500017,
            "message": "Esiste già un account associato a questa email"
        },
        {
            "code": 500018,
            "message": "campo valuta è nullo"
        },
        {
            "code": 500019,
            "message": "Errore nel caricamento del file"
        },
        {
            "code": 500020,
            "message": "Non è possibile confermare il pagamento del bonifico"
        },
        {
            "code": 500021,
            "message": "Un Cliente speciale non può trasferire Extracredit"
        },
        {
            "code": 500022,
            "message": "Saldo insufficiente nel portafoglio Extracredit"
        },
        {
            "code": 500023,
            "message": "Saldo insufficiente nel portafoglio"
        },
        {
            "code": 500024,
            "message": "Esiste già un account associato a questo numero di documento"
        },
        {
            "code": 500025,
            "message": "Questa email non corrisponde a questo utente"
        },
        {
            "code": 500026,
            "message": "Piano utente non trovato"
        },
        {
            "code": 500027,
            "message": "Voucher non valido"
        },
        {
            "code": 500028,
            "message": "Il pagamento del voucher è consentito solo per le registrazioni di Manager"
        },
        {
            "code": 500029,
            "message": "ID transazione non trovato"
        },
        {
            "code": 500030,
            "message": "Questa transazione non appartiene a questo utente"
        },
        {
            "code": 500031,
            "message": "Questa transazione non è in attesa di conferma"
        },
        {
            "code": 500032,
            "message": "Categoria non trovata con questo ID"
        },
        {
            "code": 500033,
            "message": "Attributo non trovato con questo ID"
        },
        {
            "code": 500034,
            "message":"ID attributo non corrisponde all'utente della richiesta"
        },
        {
            "code": 500035,
            "message": "Un cliente speciale non può creare prodotti"
        },
        {
            "code": 500036,
            "message": "Sottocategoria non trovata"
        },
        {
            "code": 500037,
            "message": "Prodotto non trovato"
        },
        {
            "code": 500038,
            "message": "ID prodotto non corrisponde all'utente della richiesta"
        },
        {
            "code": 500039,
            "message": "Questo prodotto ha già questo attributo"
        },
        {
            "code": 500040,
            "message": "L'Valore minimo per creare coupon è 50"
        },
        {
            "code": 500042,
            "message": "Il campo Lingua è nullo"
        },
        {
            "code": 500041,
            "message": "Il coupon non è valido"
        },
        {
            "code": 500043,
            "message": "Questo coupon non appartiene allo sponsor fornito"
        },
        {
            "code": 500044,
            "message": "Cliente speciale non può creare coupon"
        },
        {
            "code": 500045,
            "message": "Il prodotto non è associato a questo attributo"
        },
        {
            "code": 500046,
            "message": "Il prodotto ha già delle variazioni registrate"
        },
        {
            "code": 500047,
            "message": "Non puoi creare varianti con lo stesso attributo"
        },
        {
            "code": 500048,
            "message": "Tutti gli attributi devono avere almeno un valore associato"
        },
        {
            "code": 500049,
            "message": "Il marchio non è associato a questa sottocategoria"
        },
        {
            "code": 500050,
            "message": "Campo della sottocategoria non trovato"
        },
        {
            "code": 500051,
            "message": "Marca non trovata"
        },
        {
            "code": 500052,
            "message": "Il tipo non è associato alla sottocategoria"
        },
        {
            "code": 500053,
            "message": "Tipo non trovato"
        },
        {
            "code": 500054,
            "message": "Il genere di prodotto non è associato a questa sottocategoria"
        },
        {
            "code": 500055,
            "message": "Genere prodotto non trovato"
        },
        {
            "code": 500056,
            "message": "Variante prodotto non trovata"
        },
        {
            "code": 500057,
            "message": "Il prodotto può avere solo 2 attributi"
        },
        {
            "code": 500058,
            "message": "Il prodotto non ha attributi registrati"
        },
        {
            "code": 500059,
            "message": "Questo prodotto ha delle variazioni. Non è possibile modificare il prezzo totale"
        },
        {
            "code": 500060,
            "message": "Questa variazione non corrisponde a questo prodotto"
        },
        {
            "code": 500061,
            "message": "Il campo della lingua è vuoto o nullo"
        },
        {
            "code": 500062,
            "message": "L'ordine deve contenere almeno un articolo"
        },
        {
            "code": 500063,
            "message": "Stato consentito: PUBBLICATO o NON PUBBLICATO"
        },
        {
            "code": 500064,
            "message": "Acquirente e venditore devono essere diversi"
        },
        {
            "code": 500065,
            "message": "Ordine non trovato"
        },
        {
            "code": 500066,
            "message": "Questo ordine non è di questo utente"
        },
        {
            "code": 500067,
            "message": "Il prodotto nell'articolo non appartiene al venditore dell'ordine"
        },
        {
            "code": 500068,
            "message": "Articolo non trovato in ordine"
        },
        {
            "code": 500069,
            "message": "Questo prodotto ha delle variazioni, si prega di specificare una idvariation"
        },
        {
            "code": 500070,
            "message": "La percentuale di cashback deve essere compresa tra il 1% e il 15% per i partner commerciali"
        },
        {
            "code": 500071,
            "message": "La percentuale di cashback deve essere compresa tra il 1% e il 35% per gli Manager."
        },
        {
            "code": 500072,
            "message": "La percentuale di extracredit è superiore a quanto richiesto dal venditore"
        },
        {
            "code": 500073,
            "message": "Devi comunicare come vuoi pagare (portafoglio ed extracredito)."
        },
        {
            "code": 500074,
            "message": "Prodotto esaurito"
        },
        {
            "code": 500075,
            "message": "Impossibile modificare/eliminare un articolo da un ordine che non è aperto"
        },
        {
            "code": 500076,
            "message": "È stato superato l'Valore massimo possibile di extracredit. Si prega di rivedere il tuo ordine!"
        },
        {
            "code": 500077,
            "message": "Questo utente non è il venditore di questo ordine"
        },
        {
            "code": 500078,
            "message": "Questo ordine non può essere spedito"
        },
        {
            "code": 500079,
            "message": "Questo utente non è l'acquirente di questo ordine"
        },
        {
            "code": 500080,
            "message": "Questa richiesta non può essere ricevuta"
        },
        {
            "code": 500081,
            "message": "Carriera non trovata"
        },
        {
            "code": 500082,
            "message": "Il portafoglio di origine ha un saldo insufficiente nel portafoglio per pagare la commissione"
        },
        {
            "code": 500083,
            "message": "Il portafoglio di destinazione non ha abbastanza saldo per pagare la commissione"
        },
        {
            "code": 500084,
            "message": "Il portafoglio di origine non ha abbastanza saldo per il trasferimento"
        },
        {
            "code": 500085,
            "message": "Il portafoglio di destinazione non dispone di saldo sufficiente per il trasferimento"
        },
        {
            "code": 500086,
            "message": "Codice di trasferimento non trovato"
        },
        {
            "code": 500087,
            "message": "Questo utente non ha alcuna relazione con questo Trasferimento"
        },
        {
            "code": 500088,
            "message": "L'origine e la destinazione dell'utente sono le stesse"
        },
        {
            "code": 500089,
            "message": "Il codice di trasferimento di conferma non è corretto"
        },
        {
            "code": 500090,
            "message": "L'utente di origine non ha ancora eseguito il commit della transazione"
        },
        {
            "code": 500091,
            "message": "Puoi ordinare solo gli ordini che hai contrassegnato come ricevuti"
        },
        {
            "code": 500092,
            "message": "Hai già valutato questo ordine"
        },
        {
            "code": 500093,
            "message": "Le tariffe devono essere comprese tra 1 e 5"
        },
        {
            "code": 500094,
            "message": "Password corrente non è corretta"
        },
        {
            "code": 500095,
            "message": "A nova senha deve ser diferente da senha atual"
        },
        {
            "code": 500096,
            "message": "La nuova password deve essere diversa dalla password corrente"
        },
        {
            "code": 500097,
            "message": "Questo numero di telefono è già confermato"
        },
        {
            "code": 500098,
            "message": "Codice non valido - numero di telefono di conferma"
        },
        {
            "code": 500099,
            "message": "Solo i  Customers possono passare ad Manager"
        },
        {
            "code": 500100,
            "message": "Esiste già un pagamento associato a questo ID transazione"
        },
        {
            "code": 500101,
            "message": "Impossibile confermare la transazione di pagamento"
        },
        {
            "code": 500102,
            "message": "Solo i Customers possono passare a Partner"
        },
        {
            "code": 500103,
            "message": "L'utente non è attivo. Non puoi richiedere ritiri."
        },
        {
            "code": 500104,
            "message": "L'utente mittente non ha alcuna relazione con questo ordine."
        },
        {
            "code": 500105,
            "message": "L'utente ricevente non ha alcuna relazione con questo ordine."
        },
        {
            "code": 500106,
            "message": "Messaggio di chat non trovato."
        },
        {
            "code": 500107,
            "message": "L'utente non è il destinatario di questo messaggio di chat."
        },
        {
            "code": 500108,
            "message": "Questo utente potrebbe non vedere i messaggi per questo ordine."
        },
        {
            "code": 500109,
            "message": "Solo gli Manager possono nominare un Promoter."
        },
        {
            "code": 500110,
            "message": "L'ID del piano deve essere Promoter."
        },
        {
            "code": 500111,
            "message": "Promoter non può creare prodotti."
        },
        {
            "code": 500112,
            "message": "L'amministratore non può creare prodotti."
        },
        {
            "code": 500113,
            "message": "Questo ruolo richiede l'autorizzazione di amministratore."
        },
        {
            "code": 500114,
            "message": "La latitudine/longitudine del campo è obbligatoria."
        },
        {
            "code": 500115,
            "message": "Il campo origine della registrazione è obbligatorio."
        },
        {
            "code": 500116,
            "message": "Se il prodotto è per il download, è necessario fornire i campi del tipo di download , dell'URL di download e dell'URL di download di esempio."
        },
        {
            "code": 500117,
            "message": "Se almeno un elemento è un prodotto scaricabile, tutti gli elementi devono essere scaricabili."
        },
        {
            "code": 500118,
            "message": "Questo ordine non ha elementi scaricabili."
        },
        {
            "code": 500119,
            "message": "Questo dispositivo è già registrato."
        },
        {
            "code": 500120,
            "message": "Questo dispositivo non è registrato."
        },
        {
            "code": 500121,
            "message": "Questo dispositivo non è di questo utente."
        },
        {
            "code": 500122,
            "message": "Il prodotto scaricabile non può creare variazioni."
        },
        {
            "code": 500123,
            "message": "Il prodotto scaricabile non può ricevere attributi."
        },
        {
            "code": 500124,
            "message": "Prelievo minimo violato consentito."
        },
        {
            "code": 500125,
            "message": "Solo gli Manager possono creare prodotti con informazioni tecniche."
        },
        {
            "code": 500126,
            "message": "ID informazioni tecniche non trovato."
        },
        {
            "code": 500127,
            "message": "Fields weight, height, width or length not found."
        },
        {
            "code": 500128,
            "message": "Questo prodotto è già stato preferito da questo utente."
        },
        {
            "code": 500129,
            "message": "Questo prodotto non è stato aggiunto ai preferiti di questo utente."
        },
        {
            "code": 500130,
            "message": "Ritiro non trovato."
        },
        {
            "code": 500131,
            "message": "Questo prelievo è già stato elaborato."
        },
        {
            "code": 500132,
            "message": "Questo utente è già bloccato."
        },
        {
            "code": 500133,
            "message": "Questo utente è già sbloccato."
        },
        {
            "code": 500134,
            "message": "Ricerca non trovata."
        },
        {
            "code": 500135,
            "message": "Questa ricerca è già stata confermata."
        },
        {
            "code": 500136,
            "message": "Esiste già un Brand con questa descrizione."
          },
          {
              "code": 500137,
              "message": "Questo tipo è già associato a questa sottocategoria."
          },
          {
              "code": 500138,
              "message": "Questo marchio è già associato a questa sottocategoria."
          },
          {
              "code": 500139,
              "message": "Configurazione del marketplace non trovata."
          },
          {
              "code": 500140,
              "message": "Questo prodotto non può essere spedito. Per favore, contatta il venditore."
          },
          {
              "code": 500141,
              "message": "Non possiamo ancora spedire da questo paese."
          },
          {
              "code": 500142,
              "message": "Non siamo riusciti a trovare un corriere per il tuo ordine."
          },
          {
              "code": 500143,
              "message": "Questo venditore non può ancora spedire."
          },
          {
              "code": 500144,
              "message": "Questo prodotto non può essere spedito al momento."
          },
          {
              "code": 500146,
              "message": "I prodotti scaricabili non possono essere spediti."
          },
          {
              "code": 500147,
              "message": "I servizi non possono essere spediti."
          },
          {
              "code": 500148,
              "message": "Questo ordine non ha ancora un pacco assegnato. Si prega di attendere alcuni minuti e riprovare."
          },
          {
              "code": 500149,
              "message": "C'è già un ritiro prenotato per questo pacco."
          },
          {
              "code": 500150,
              "message": "Solo il venditore può prenotare un ritiro."
          },
          {
              "code": 500151,
              "message": "Conversione non trovata."
          },
          {
              "code": 500152,
              "message": "Conversione scaduta."
          },
          {
              "code": 500153,
              "message": "Questa conversione non appartiene all'utente richiesto."
          },
          {
              "code": 500154,
              "message": "Questa conversione è stata utilizzata."
          },
          {
              "code": 500155,
              "message": "Questo utente ha già registrato l'indirizzo. Per modificare, contatta l'assistenza."
          },
          {
              "code": 500156,
              "message": "Questo ordine contiene un servizio. Tutti gli articoli devono essere servizi."
          },
          {
              "code": 500157,
              "message": "Questo ordine non è associato a un pacco."
          },
          {
              "code": 500158,
              "message": "I partner non possono creare più di 50 prodotti pubblicati!"
          },
          {
              "code": 500159,
              "message": "I partner non possono creare più di 50 prodotti inediti!"
          },
          {
            "code": 500162,
            "message": "Puoi rinnovare il tuo abbonamento solo da 10 giorni prima della data di scadenza!"
          },
          {
              "code": 500163,
              "message": "Il rinnovo non può essere pagato con questo metodo di pagamento!"
          },
          {
              "code": 500164,
              "message": "Questo metodo può pagare solo il rinnovo!"
          },
          {
              "code": 500165,
              "message": "Questo buono non può essere utilizzato per pagare questo piano!"
          },
          {
              "code": 500166,
              "message": "La password finanziaria non corrisponde."
          },
          {
              "code": 500167,
              "message": "L'identificazione del Piano deve essere il Venditore."
          },
          {
              "code": 500168,
              "message": "La percentuale di cashback deve essere compresa tra il 1% e il 25% per i venditori."
          },
          {
              "code": 500169,
              "message": "Un prodotto non può essere disponibile e scaricabile allo stesso tempo."
          },
          {
            "code": 500170,
            "message": "Stai tentando di aggiungere un servizio a un ordine che contiene articoli non di servizio."
          },
          {
            "code": 500171,
            "message": "Domanda sul prodotto non trovata."
          },
          {
            "code": 500172,
            "message": "This user can not answer this question."
          },
          {
            "code": 500173,
            "message": "Devi comunicare come vuoi pagare (portafoglio ed extracredito)."
          },
          {
            "code": 500174,
            "message": "Non puoi chiedere informazioni sul tuo prodotto."
          },
          {
            "code": 500175,
            "message": "Notifica non trovata."
          },
          {
            "code": 500176,
            "message": "Questa notifica non appartiene all'utente richiesto."
          },
          {
            "code": 500177,
            "message": "Non è stato possibile inviare le tue notifiche perché il tuo saldo extracredit è insufficiente, ma il tuo prodotto è registrato correttamente."
          },
          {
            "code" : 500178,
            "message": "Se il tuo ordine include almeno un articolo con spedizione gratuita, anche tutti gli articoli devono avere la spedizione gratuita."
          },
          {
              "code" : 500179,
              "message": "Non puoi creare prodotti."
          },
          {
              "code" : 500180,
              "message": "Non è possibile eseguire l'aggiornamento a SilverPro."
          },
          {
              "code" : 500181,
              "message": "Non puoi aggiornare a GeoGold."
          },
          {
              "code" : 500182,
              "message": "Non è possibile eseguire l'aggiornamento a StartSeller."
          },
          {
              "code" : 500183,
              "message": "Condividi contenuto non trovato."
          },
          {
              "code" : 500184,
              "message": "Questo prodotto è già stato condiviso da questo utente."
          },
          {
              "code" : 500185,
              "message": "Portafoglio Extrabonus non trovato."
          },
          {
              "code" : 500186,
              "message": "Questo ordine non può essere ritirato all'indirizzo del venditore."
          },
          {
              "code" : 500187,
              "message": "Chiave pubblica non trovata."
          },
          {
              "code" : 500188,
              "message": "ID transazioneCrypto non trovato."
          },
          {
              "code" : 500189,
              "message": "Errore di pagamento. Non siamo riusciti a confermare la transazione del tuo portafoglio crittografico."
          },
          {
            "code" : 500190,
            "message": "Se il prodotto è importato, è necessario fornire il campo OriginProductImported."
          },
          {
            "code" : 500191,
            "message": "Se il prodotto è importato da Banggood devi fornire il campo ProductIdBanggood."
          },
          {
            "code" : 500192,
            "message": "Questo prodotto non è assegnato a questo attributo."
          },
          {
            "code" : 500193,
            "message": "Questo attributo è assegnato in almeno una variante! Elimina prima le varianti."
          },
          {
            "code" : 500194,
            "message": "Modifica il tuo prodotto e imposta la percentuale di cashback prima della pubblicazione."
          },
          {
            "code" : 500195,
            "message": "La percentuale di cashback deve essere compresa tra il 1% e il 5% per i clienti."
          },
          {
            "code": 500198,
            "message": "Questo slug non è disponibile."
          }
    ]
}

export default message_IT;
