import { AdvancedImage } from "@cloudinary/react";
import { Galleria } from "primereact/galleria";
import React, { useEffect, useState } from "react";
import ProductViews from "../../../../components/ProductViews/ProductViews";
import { cloudinaryInstance } from "../../../../utilities/Cloudinary/cloudinaryInstance";
import useScrollGallery from "./hooks/useScrollGallery";

const responsiveOptions = [
  {
    breakpoint: "1500px",
    numVisible: 2,
  },
];

const GalleryImages = ({ images = [], imagesVariations = [], currentImageVariation, views }) => {
  const { galleryRef, galleryContainerRef, gridStyle } = useScrollGallery();
  const [activeIndex, setActiveIndex] = useState(0);
  const galleryImages = [...images, ...imagesVariations];

  useEffect(() => {
    if (currentImageVariation) {
      setActiveIndex(galleryImages.indexOf(currentImageVariation));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImageVariation]);

  const handleImageChange = ({ index }) => {
    setActiveIndex(index);
  };
  return (
    <div className="h-full gallery" ref={galleryContainerRef}>
      <div ref={galleryRef}>
        <Galleria
          className="relative"
          style={gridStyle}
          value={galleryImages}
          responsiveOptions={responsiveOptions}
          activeIndex={activeIndex}
          onItemChange={handleImageChange}
          showItemNavigators
          numVisible={3}
          item={(item) => (
            <>
              <AdvancedImage
                cldImg={cloudinaryInstance.image(item).format("webp")}
                className="border-round product-selected-image gallery-img md:w-full md:h-full"
                width="600px"
                height="600px"
              />
              <ProductViews views={views} />
            </>
          )}
          thumbnail={(item) => (
            <AdvancedImage
              cldImg={cloudinaryInstance.image(item).format("webp").quality(10)}
              className="border-round product-selected-image gallery-thumbnail"
              width={90}
              height={90}
            />
          )}
        />
      </div>
    </div>
  );
};

export default GalleryImages;
