import axios from 'axios'
import apiURI from './Api';

export default class ShareService {
  async getSharePermission(productid, token, username) {

    const response = await axios({
      method: 'get',
      url: `${apiURI}/share/get/${productid}/username/${username}`,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postShareCreate(productid, token, username) {
    const bodyJson = {
      productid,
      token,
      username,
    }
   
    const response = await axios({
      method: 'post', data: bodyJson,
      url: `${apiURI}/share/create`,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }

  async postShareClick(productid, token) {
    const bodyJson = {
      productid,
      token,
    }

    const response = await axios({
      method: 'post', data: bodyJson,
      url: `${apiURI}/share/click`,
    });

    return response.data;
  }
}