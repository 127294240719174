const shopDictES = {
  seeProduct: "Ver producto",
  firstBannerText: "El marketplace que siempre te premia",
  secondaryBannerText: "Únete a la Extraconomy",
  secondaryBannerButtonText: "Descubre nuestros planes",
  secondaryBannerText2: "Aumenta tu poder económico",
  searchQueryText: "Has buscado: ",
  subCategoryFilter: "Subcategorías",
  noProductsInCategory: "No tiene productos",
  noProductsInSearch: "No se encontraron productos",
  noSubcategories: "No tiene subcategorías",
  filters: "Filtros",
  downloadable: "Descargable",
  brazil: "Brasil",
  mexico: "México",
  italy: "Italia",
  brl: "BRL",
  mxn: "MXN",
  eur: "EUR",
  usd: "USD",
  gbp: "GBP",
  try: "TRY",
  results: "Resultados",
  result: "Resultado",
  selectStore: "Selecciona una tienda",
  store: "Tienda",
  filter: "Filtrar",
  registerAsCustomerFree: "Regístrate como Customer de forma gratuita",
  register2: "Registrarse",
};

export default shopDictES;
