const productsListDictES = {
  productImage: "Imagen Principal",
  title: "Título",
  priceUnique: "Precio",
  productState: "Estado",
  productStatus: "Situacción",
  published: "Publicado",
  unpublished: "No publicado",
  planNotAllowed: "Su suscripción actual no tiene acceso a esta función.",
  upgradeToBusinessPartner: "Actualize para Partner",
  upgradeToAreaManager: "Actualize para Manager",
  assignAttributes: "Asociar Atributos",
  goBackProductList: "Volver a la lista de productos",
  noVariations:
    "Este producto no tiene variaciones. Desea generar variaciones automáticamente para este producto?",
  generateVariations: "Administrar Variaciones",
  manageVariations: "Administrar variaciones del producto",
  manageVariationsShort: "Administrar Variaciones",
  image: "Imagen",
  stock: "Stock",
  delete: "Eliminar",
  pleaseChooseImage: "Por favor elija una imagen.",
  theValuesOfTheseAttributes:
    "Los valores de estos atributos se utilizarán para generar variaciones:",
  generateVariationsWithThese: "Generar variaciones con estos atributos?",
  goBackToVariationsList: "Volver a la lista de variaciones",
  confirmChanges: "Confirmar Cambios",
  pressThePencilIconTo: "Presione el botón del lapicero para editar la imagen de variación",
  variationUpdatedSuccessfully: "Variación del producto atualizada con éxito!",
  variationMustHaveAValidPrice: "La variación debe tener un precio válido.",
  variationDeletedSuccessfully: "Variación eliminada con éxito.",
  deleteThisVariation: "Está seguro de que desea eliminar esta variación?",
  preview: "Previa",
  selectTwoAttributes: "Seleccione",
  and: "e",
  selectOneAttribute: "Seleccione",
  unavailable: "Indisponible",
  youMustFirstGenerateVariations:
    "Este es un producto variable. Primero debe generar variaciones para publicarlo.",
  clickHereToGenerateVariations: "Haga clic aquí para generar variaciones para este producto",
  publish: "Publicar",
  productPublishedSuccessfully: "Producto publicado con éxito!",
  productUnpublishedSuccessfully: "Producto despublicado de tu tienda con éxito.",
  seller: "Vendedor",
  itemAlreadyInCart: "Artículo ya está en el carrito.",
  unpublish: "No publicar",
  visibility: "Visibilidad",
  downloadableProductExplanation:
    "Este es un producto descargable. Puede consultar una versión de muestra antes de comprar para tener acceso al enlace de descarga real.",
  searchByTitle: "Buscar por título",
  searchBySeller: "Buscar por vendedor",
  searchByBuyer: "Buscar por comprador",
  imported: "Importado",
  importOrigin: "Origen",
  archived: "Archivar",
  archivedProduct: "Archivado",
};

export default productsListDictES;
