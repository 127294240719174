

const Loading = ({
  classNameContainer,
  classNameIcon,
  stylesContainer,
  stylesIcon,
  disableMargin,
}) => {
  return (
    <div
      className={`${classNameContainer} text-center ${disableMargin ? "" : "my-8"} `}
      style={stylesContainer}
    >
      <i
        className={`pi pi-spin pi-spinner text-400 ${classNameIcon}`}
        style={stylesIcon}
      ></i>
    </div>
  );
};

export default Loading;
