import { RadioButton } from "primereact/radiobutton";
import localeText from "../../../../locale";
import { formatPrice } from "../../../../utilities/formatPrice";

const Shipping = ({ shippingQuotes, postalCode, sendMethod, setSendMethod }) => {
  return (
    <div className="card border-1 border-400 surface-200">
      <div className="flex align-items-center">
        <i className="pi pi-truck text-700 text-4xl mr-2  "></i>
        <p className="text-800">
          {localeText("shippingQuotesFor")} {postalCode}
        </p>
      </div>

      <div className="mt-4 overflow-y-scroll" style={{ maxHeight: "300px" }}>
        {shippingQuotes.prices.map((shippingMethod) => {
          return (
            <div key={`shipping${shippingMethod.idsendcloudmethod}`}>
              <div className="card flex justify-content-between align-items-center border-1 border-300 mr-1">
                <ul className="list-none m-0 p-1">
                  <li>
                    <p>{shippingMethod.carrierMethod}</p>
                  </li>
                  <li>
                    <p className="text-primary font-bold">
                      {shippingMethod.price === "free"
                        ? localeText("free")
                        : `${shippingMethod.currency} ${formatPrice(shippingMethod.price)}`}
                    </p>
                  </li>
                </ul>
                <RadioButton
                  value={shippingMethod}
                  onChange={(e) => setSendMethod(e.value)}
                  checked={sendMethod?.idsendcloudmethod === shippingMethod.idsendcloudmethod}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Shipping;
