const validateUserInfoDictEN = {
  pleaseEnterValidEmail: "Please enter a valid Email address.",
  thereIsAnExisting: "There is an existing account associated with this email.",
  usernameWhitespace: "User name can't have whitespace characters.",
  thereIsAnDocNumber: "There is an existing account associated with this document number.",
  invalidCPF: 'Invalid Document number',
  invalidCNPJ: 'Invalid CNPJ',
  invalidCodiceFiscale: 'Invalid Codice Fiscale',
  invalidPartitaIVA: 'Invalid Partita IVA',
  invalidDocumentNumber: 'Invalid fiscal document number',
  unexpectedErrorRegisteringSC: 'Unexpected error while registering Customer',
  invalidVoucher: 'Invalid voucher.',
  passwordFieldsMismatched: 'Password fields are mismatched.',
  pleaseFillOutAll: 'Please fill out all fields.',
  pleaseAgreeTerms: 'Please agree to the terms and conditions.',
  pleaseTypeLongerPassword: 'Please type a password longer than 6 characters.',
  pleaseTypeShorterPassword: 'Please type a password shorter than 19 characters.',
  invalidPassword: 'Invalid password. Must only have alphanumeric characters and - _ symbols.',
  noSpecialCharacters: 'Username can only have letters and/or numbers.',
  invalidRFC: 'Invalid RFC.',
}

export default validateUserInfoDictEN