const confirmDocumentDictES = {
  confirmID: 'Confirmar documento de identidad',
  confirmDocumentPic: "Considere enviar una foto de su documento de identificación para que podamos confirmar su cuenta.",
  confirmDocumentPicNow: "Cargue una foto de su documento de identificación para desbloquear el sitio y confirmar su cuenta.",
  documentUploadedSuccessfully: 'Foto del documento enviada con éxito!',
  youCanCloseThisWindow: 'Usted puede cerrar esta ventana ahora.',
  photo: 'Foto',
  required: 'obligatorio',
}

export default confirmDocumentDictES;