import React from "react";
import { AdvancedImage } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { cloudinaryInstance } from "../../utilities/Cloudinary/cloudinaryInstance";
import "./PopupImage.css";

const PopupImage = (props) => {
  const { onClick, image, urlRedirect } = props;

  function redirectToNewPage() {
    window.location.href = urlRedirect;
  }

  const widthRatio = window.innerWidth / 383;

  const imageWidth = window.innerWidth < 750 ? Number((300 * widthRatio).toFixed(0)) : 550;

  const aspectRatio = 300 / 370;

  return (
    <div className="popup-div_image">
      <div
        style={{
          width: imageWidth,
          aspectRatio: aspectRatio,
        }}
      >
        <button className="button-div_closeButton" onClick={onClick}>
          x
        </button>
        <AdvancedImage
          cldImg={cloudinaryInstance
            .image(image)
            .format("webp")
            .resize(fill().width(imageWidth).aspectRatio(aspectRatio))}
          className="border-round border-1 border-300 w-full"
          onClick={redirectToNewPage}
        />
      </div>
    </div>
  );
};

export default PopupImage;
