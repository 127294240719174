const productInfoDictPT = {
  price: "Preço",
  initialPrice: "Preço inicial",
  percentualOfCredit: "Percentual do crédito: ",
  minimumPurchaseAmount: "Valor mínimo de compra",
  noDescription: "Não foi cadastrado pelo vendedor ou não possui.",
  promocionalPrice: "Preço promocional: ",
  comparePrices: "Comparar preços",
  descriptionProduct: "Descrição",
  characteristics: "Características",
  productReviews: "Avaliações do produto",
  storeReviews: "Avaliações da loja",
  showMore: "Ver mais",
  thisProductIsYours: "Este produto é seu",
  errorThisProductIsYours: "Você não pode comprar seu próprio produto",
  questionsAndAnswers: "Perguntas e respostas",
  question: "Pergunta",
  doQuestion: "Faça uma pergunta",
  doQuestionToSeller: "Perguntar ao vendedor",
  sendQuestion: "Enviar pergunta",
  askQuestionSuccess: "Pergunta enviada com sucesso!",
  answerQuestionSuccess: "Resposta enviada com sucesso!",
  reply: "Responder",
  askQuestionTooLong: "A pergunta deve ter no máximo 200 caracteres",
  askQuestionEmpty: "A pergunta não pode estar vazia",
  answerQuestionTooLong: "A resposta deve ter no máximo 200 caracteres",
  answerQuestionEmpty: "A resposta não pode estar vazia",
  waitingResponseFromSeller: "Aguardando resposta do vendedor",
  askQuestionHasPhoneNumber: "A pergunta não pode conter número de telefone",
  answerQuestionHasPhoneNumber: "A resposta não pode conter número de telefone",
  freeToItaly: "Frete grátis para Itália",
};

export default productInfoDictPT;
