

const HeaderSection = ({ classNameContainer, classNameTitle, disableBoxModel = false, children }) => {
  return (
    <div className={`xl:mb-5 mb-3 ${classNameContainer}`}>
      <h4
        className={`${
          disableBoxModel ? "" : "pb-3 mx-3"
        } font-bold lg:text-5xl md:text-4xl text-2xl text-primary ${classNameTitle}`}
        style={{ borderBottom: "2px solid #ebebeb" }}
      >
        {children}
      </h4>
    </div>
  );
};

export default HeaderSection;
