const attributesDictPT = {
  edit: 'Editar',
  attributeSearch: 'Pesquisar Atributo',
  addNewAttribute: 'Adicionar novo atributo',
  attributeName: 'Nome do atributo',
  add: 'Adicionar',
  attributeCreated: 'Atributo criado com sucesso!',
  pleaseFillOutField: 'Por favor preencha o campo.',
  goBackToAttributeList: 'Voltar para lista de atributos',
  attributeValues: 'Valores do atributo',
  attribute: 'Atributo',
  value: 'Valor',
  listOfValues: 'Valores de',
  addNewValues: 'Adicionar novos valores',
  valuesCreated: 'Valores criados e adicionados ao atributo com sucesso!',
  // Assign Attributes
  assign: 'Associar',
  assignAttributesToProduct: 'Associar atributos ao produto',
  pressARowToSelect: 'Pressione uma linha para selecionar um atributo.',
  selectAtLeastOneAttribute: 'Selecione pelo menos um atributo.',
  canOnlyHaveUpTo2Attributes: 'Você só pode selecionar até dois atributos.',
  attributeAssignedSuccessfully: 'Atributo(s) associado(s) com sucesso!',
  confirmAssignAttributes: "Você está prestes a associar atributos a este produto, e isso o transformará em um produto variável. Deseja prosseguir com esta ação?",
  attributeEnterExplanation: 'Pressione as teclas Enter ou "," para adicionar um valor (você pode adicionar múltiplos valores)',
}

export default attributesDictPT;