import { BreadCrumb } from "primereact/breadcrumb";
import { locale } from "../../../../utilities/locale/locale";

import { useHistory } from "react-router-dom";

const BreadCrumbShop = ({
  selectedCategory,
  selectedSubcategory,
  setSelectedCategory,
  setProducts,
  setSelectedSubcategory,
}) => {
  const history = useHistory();

  const clearCategory = () => {
    setSelectedSubcategory(null);
    history.push(`/shop?category=${selectedCategory.id}`);
  };

  const clearSubCategory = () => {
    setProducts([]);
    setSelectedCategory(null);
    history.push("/shop");
  };

  let items = [{ label: selectedCategory.descriptionCurrentLocale, command: clearCategory }];

  if (selectedSubcategory) {
    const labelSubcategory = {
      pt: selectedSubcategory?.descriptionPortuguese,
      en: selectedSubcategory?.descriptionEnglish,
      it: selectedSubcategory?.descriptionItalian,
      es: selectedSubcategory?.descriptionSpanish,
    };

    items.push({ label: labelSubcategory[locale] });
  }

  const home = {
    icon: "pi pi-home",
    command: clearSubCategory,
  };

  return (
    <BreadCrumb
      className="border-none px-0"
      style={{ backgroundColor: "inherit" }}
      model={items}
      home={home}
    />
  );
};

export default BreadCrumbShop;
