const confirmDocumentDictIT = {
  confirmID: 'Conferma documento di identità',
  confirmDocumentPic: "Considera l'idea di caricare una foto del tuo documento di identità in modo che possiamo confermare il tuo account.",
  confirmDocumentPicNow: "Carica una foto del tuo documento di identità per sbloccare il sito e conferma il tuo account.",
  documentUploadedSuccessfully: 'Foto del documento inviato con successo!',
  youCanCloseThisWindow: 'Puoi chiudere questa finestra ora.',
  photo: 'Foto',
  required: 'obbligatorio',
}

export default confirmDocumentDictIT;