const planListDictPT = {
  specialCustomer: "Customer",
  physicalPerson: "Pessoa física",
  legalPerson: "Pessoa jurídica",
  for: "Por",
  free: "Grátis",
  joinPlan: "Eu quero!",
  instantlyEarnPlanList: "Receba ",
  forYourPurchase: " de Extrabonus",
  fisrtSpecialCustomerExplanation: "Você pode comprar bens e serviços em nossa plataforma.",
  secondSpecialCustomerExplanation:
    "Se você baixar nosso aplicativo oficial para se inscrever, ganhará créditos Extracredit gratuitos.",
  businessPartner: "Partner",
  taxes: "tax",
  businessPartnerExplanation: "Pode cadastrar todos os perfis",
  businessPartnerExplanationCont: "Pode vender com vetrine",
  businessPartnerExplanationCont2: "Renovação anual de",
  areaManager: "Manager",
  areaManagerExplanation:
    "O plano mais completo e benéfico para aumentar automaticamente seus clientes e seu poder econômico através de nossa tecnológia de ponta.",
  areaManagerExplanationCont: "Ganhe sua campanha publicitária inscrevendo-se agora!",
  taxExplanation: "* imposto sobre vendas de acordo com seu País.",
  canSellWithEcommerce: "Pode vender com e-commerce",
  sellerExplanation2:
    "Inscreva-se agora na nossa plataforma exclusiva e inovadora sem pagamentos mensais.",
  sellerExplanationRegister:
    "Abra a sua loja eletrônica de produtos e/ou serviços de forma simples e comece a fazer seus negócios rapidamente. Ideal para quem precisa oferecer produtos e serviços de forma rápida, prática e simples através de uma plataforma poderosa. Por uma taxa de inscrição de BRL 3500, você desfruta dos seguintes benefícios:",
  sellerExplanationRegister2:
    "Receba imediatamente um crédito no valor de BRL 3.500,00 para as suas futuras compras (+ BRL 1.500,00 se você for um dos primeiros 1000 membros em seu país a registrar sua empresa como Seller).",
  sellerExplanationRegister3:
    "Adquira o seu espaço de e-commerce para dar a conhecer e vender os seus produtos e serviços.",
  sellPlans: "Plano de vendas",
  silverPro1: "Receba ",
  geolocalizedStore: "Loja geolocalizada",
  allInclusive: "Tudo incluído",
  noMonthlyPayments: "Sem pagamentos mensais",
  unlimitedAds: "Anúncios ilimitados",
  getsPersonalCommerce: "Obtenha sua loja pessoal",
  openYourOnlineShop: "Abra sua loja online",
  earnCommission: "Ganhe comissão",
  onlineShop: "Loja online",
  activeGeolocation: "Geolocalização ativa",
  personalEcommerce: "E-commerce pessoal",
  goToDropShipping: "Possui Dropshipping",
  onlineShowcase: "Vitrine online",
  activeCommission: "Comissão ativa",
};

export default planListDictPT;
