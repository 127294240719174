import regulacaoBR from "../../assets/static/regulamento-launchpad-br.pdf";
import regulacaoEN from "../../assets/static/regulamento-launchpad-en.pdf";
import regulacaoES from "../../assets/static/regulamento-launchpad-es.pdf";
import regulacaoIT from "../../assets/static/regulamento-launchpad-it.pdf";

export const regulamentos = {
  pt: regulacaoBR,
  en: regulacaoEN,
  es: regulacaoES,
  it: regulacaoIT,
};
