const appTopbarDictES = {
  welcome: "Bienvenido",
  editProfile: "Editar Perfil",
  logout: "Salir",
  cancelAccount: "Cancelar Cuenta",
  writeCancelToConfirm: 'Escribe "CANCELAR" para confirmar',
  confirmYourPassword: "Confirma tu contraseña",
  accountCanceledSuccessfully: "Cuenta cancelada con éxito! Serás desconectado en breve.",
  changePassword: "Cambiar contraseña",
  currentPassword: "Contraseña actual",
  newPassword: "Nueva Contraseña",
  passwordChangedSuccessfully: "Contraseña actualizada con éxito!",
  upgradeAccount: "Actualizar Plan",
  upgradeTo: "Actualizar para",
  learnMoreAboutThePlan: "Conozca más sobre el plan",
  upgradeWithVoucher: "Actualizar plan con voucher",
  including: "incluyendo",
};

export default appTopbarDictES;

