const registerUserDictEN = {
  referralUsername: "Referral username",
  nameOfUserRefferalCodeInvalid: "Invalid referral username.",
  doYouHave: "Do you have a referral?",
  yes: "Yes",
  no: "No",
  voucher: "Voucher code",
  doYouHaveVoucher: "Do you have a voucher?",
  username: "Username",
  password: "Password",
  confirmPassword: "Confirm password",
  firstName: "First name",
  surname: "Last name",
  documentNumber: "Document number",
  selectCurrency: "Select a currency",
  terms: "I agree to the terms and conditions.",
  marketingEmail: "Receive emails about updates and promotions?",
  recaptcha: "Reverify ReCaptcha",
  signUp: "Sign up",
  proceedToPayment: "Proceed to Payment",
  payWithExtracoin: "Pay with extracoin",
  coupon: "Registration coupon",
  uploadDocPic: "Upload document photo",
  on: "on",
  salePromoterRefExplanation:
    "You can only register as a Promoter through an Manager referral link.",
  // toast,
  referralUserNotFound: "Referral user not found.",
  invalidCoupon: "Invalid credit coupon.",
  couponDoesNotBelong: "The coupon does not belong to this referral user",
  paymentCheckout: "Payment checkout",
  phoneConfirmation: "Phone confirmation",
  phoneConfirmCodeExplanationPt1: "We have sent a message via SMS to",
  phoneConfirmCodeExplanationPt2:
    "with your confirmation code, please insert the code below to finish your registration",
  phoneConfirmCode: "Confirm code",
  pleaseUploadDocPic: "Please upload a photo of your ID",
  shippingRules: "Warning: It is not yet possible to use the shipping quote!",
  shippingRulesExplanation:
    "Our shipping service is available only to users who ship from addresses in Italy!",
  iKnow: "I know",
  alreadyHaveAccount: "Already have an account?",
  emailCanBeSpam: "Check your spam folder, the email may be there.",
  doubts: "Doubts?",
  payNow: "Pay now",
};

export default registerUserDictEN;
