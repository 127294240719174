const validateUserInfoDictIT = {
  pleaseEnterValidEmail: "Si prega di inserire un indirizzo email valido.",
  thereIsAnExisting: "Esiste un account esistente associato a questa email.",
  usernameWhitespace: "Il nome utente non può contenere spazi bianchi.",
  thereIsAnDocNumber: "Esiste un account esistente associato a questo numero di documento.",
  invalidCPF: 'Codice Fiscale non valido',
  invalidCNPJ: 'Partita IVA non valida',
  invalidCodiceFiscale: 'Codice Fiscale non valido',
  invalidPartitaIVA: 'Partita IVA non valida',
  invalidDocumentNumber: 'Numero di documento fiscale non valido',
  unexpectedErrorRegisteringSC: 'Errore imprevisto durante la registrazione di Customer',
  invalidVoucher: 'Voucher non valido.',
  passwordFieldsMismatched: 'I campi della password non corrispondono.',
  pleaseFillOutAll: 'Si prega di compilare tutti i campi.',
  pleaseAgreeTerms: 'Si prega di accettare i termini e le condizioni.',
  pleaseTypeLongerPassword: 'Si prega di digitare una password più lunga di 6 caratteri.',
  pleaseTypeShorterPassword: 'Digita una password più corta di 19 caratteri.',
  invalidPassword: 'Password non valida. Deve contenere solo caratteri alfanumerici e - _ simboli.',
  noSpecialCharacters: 'Il nome utente può contenere solo lettere e/o numeri.',
  invalidRFC: 'RFC non valido.',
}

export default validateUserInfoDictIT