import { Link } from "react-router-dom";
import localeText from "../../../../locale";
// import { formatPrice } from "../../../../utilities/formatPrice";
// import usePlanPrices from "../../hooks/usePlanPrices";

const LandingPlanList = () => {
  // const { priceBusinessPartner, priceAreaManager, priceSeller, currency } = usePlanPrices();

  return (
    <div className="grid justify-content-center mt-6">
      <div className="col-12 xl:col-4">
        <div className="pricing-box bg-white border-round-xl" style={{ height: "100%" }}>
          <p className="font-semibold lg:text-xl mt-5 text-color capitalize mb-0">
            {localeText("profile")}
          </p>
          <h3 className="font-bold lg:text-4xl text-green-600 mx-auto mb-0 uppercase">
            {localeText("businessPartner")}
          </h3>

          <div
            style={{ width: "10%", height: "2px", backgroundColor: "#495057", margin: "0 auto" }}
          />

          <p className="font-semibold mt-0 lg:text-xl text-color capitalize">
            {localeText("physicalPerson")}
          </p>
          <span className="font-bold text-green-600 mx-auto text-color mb-0 block">
            {localeText("sellPlans")}
          </span>
          <span
            className="font-bold mx-auto text-white mb-0 block mx-auto w-6rem border-round-md"
            style={{ backgroundColor: "#495057" }}
          >
            ProSilver
          </span>
          {/* <h3 className="font-bold uppercase lg:text-4xl mt-5 text-primary border-bottom pb-6">
            {currency} {formatPrice(priceBusinessPartner)}
          </h3> */}

          <div className="font-bold uppercase lg:text-4xl mt-0 text-primary border-bottom pb-5" />

          <ul className="m-0 p-0 px-1">
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              {/* <p className="text-primary font-bold">
                {localeText("instantlyEarnPlanList")}
                <span className="text-green-600">{currency} 150</span>
                {localeText("forYourPurchase")}
              </p> */}
              <p className="font-bold">{localeText("onlineShowcase")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="font-bold">{localeText("network")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              {/* <p className="text-color font-semibold">
                {localeText("businessPartnerExplanationCont2")} {currency} 15
              </p>*/}
              <p className="text-color font-semibold">{localeText("activeCommission")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-color font-semibold">{localeText("noMonthlyPayments")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
          </ul>
          <Link to="/businesspartner" className="inline-block">
            <button type="button" className="p-button">
              <span className="p-button-text text-xl font-bold uppercase p-1">
                {localeText("joinPlan")}
              </span>
            </button>
          </Link>
        </div>
      </div>

      <div className="col-12 xl:col-4">
        <div
          className="pricing-box bg-primary xl:text-gray-400 text-white border-round-xl"
          style={{ height: "100%" }}
          id="plans"
        >
          <p className="font-semibold lg:text-xl mt-5 capitalize mb-0">{localeText("profile")}</p>
          <h3 className="font-bold lg:text-4xl text-green-600 mx-auto mb-0 uppercase">Seller</h3>
          <div style={{ width: "10%", height: "2px", backgroundColor: "#fff", margin: "0 auto" }} />

          <p className="font-semibold text-white lg:text-lg capitalize mt-1">
            {localeText("legalPerson")}/{localeText("physicalPerson")}
          </p>
          <span className="font-medium mx-auto mb-0 block text-white">
            {localeText("sellPlans")}
          </span>
          <span className="font-bold mx-auto text-gray-800 mb-0 block mx-auto w-6rem border-round-md bg-white">
            StartSeller
          </span>
          {/* <h3 className="font-bold uppercase lg:text-4xl mt-5 text-white border-bottom pb-6">
            {currency} {formatPrice(priceSeller)}
          </h3> */}

          <div className="font-bold uppercase lg:text-4xl mt-0 text-primary border-bottom pb-5" />

          <ul className="m-0 p-0 px-1">
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-white font-bold">
                {localeText("personalEcommerce")}
                {/* <span className="text-green-600">{currency} 350</span>
                {localeText("forYourPurchase")} */}
              </p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-white font-semibold">{localeText("goToDropShipping")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-white font-semibold">{localeText("network")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
            <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
              <p className="text-white font-semibold">{localeText("noMonthlyPayments")}</p>
              <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
            </li>
          </ul>

          <Link to="/seller" className="inline-block mt-4">
            <button type="button" className="p-button">
              <span className="p-button-text text-xl font-bold uppercase p-1">
                {localeText("joinPlan")}
              </span>
            </button>
          </Link>
        </div>
      </div>

      <div className="col-12 xl:col-4">
        <div className="pricing-box bg-white border-round-xl" style={{ height: "100%" }}>
          <p className="font-semibold lg:text-xl mt-5 text-color capitalize mb-0">
            {localeText("profile")}
          </p>
          <h3 className="font-bold lg:text-4xl text-green-600 mx-auto mb-0 uppercase">
            {localeText("areaManager")}
          </h3>

          <div
            style={{ width: "10%", height: "2px", backgroundColor: "#495057", margin: "0 auto" }}
          />

          <p className="font-semibold lg:text-xl text-color capitalize mt-0">
            {localeText("legalPerson")}
          </p>
          <span className="font-bold text-green-600 mx-auto text-color mb-0 block">
            {localeText("sellPlans")}
          </span>

          <span className="font-bold mx-auto text-white mb-0 block mx-auto w-6rem border-round-md bg-yellow-500">
            GeoGold
          </span>

          <div className="font-bold uppercase lg:text-4xl mt-0 text-primary border-bottom pb-5" />

          {/* <h3 className="font-bold uppercase lg:text-4xl mt-5 text-primary border-bottom pb-6">
            {currency} {formatPrice(priceAreaManager)}
          </h3> */}

          <div className="h-full ">
            <ul className="m-0 p-0 px-1">
              <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
                <p className="font-bold">
                  {localeText("onlineShop")}
                  {/* <span className="text-green-600">{currency} 1500</span>
                  {localeText("forYourPurchase")} */}
                </p>
                <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
              </li>
              <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
                <p className="text-color font-bold">{localeText("activeGeolocation")}</p>
                <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
              </li>
              <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
                <p className="text-color font-semibold">{localeText("allInclusive")}</p>
                <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
              </li>
              <li className="list-none text-left w-full flex justify-content-between align-items-center gap-1">
                <p className="text-color font-semibold">{localeText("noMonthlyPayments")}</p>
                <i className="pi pi-check text-green-600 font-bold" style={{ fontSize: "1rem" }} />
              </li>
            </ul>

            <Link to="/areamanager" className="inline-block">
              <button type="button" className="p-button">
                <span className="p-button-text text-xl font-bold uppercase p-1">
                  {localeText("joinPlan")}
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPlanList;
