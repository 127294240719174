import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";
import { Navigation } from "swiper";

const defaultBreakpoints = {
  374: {
    slidesPerView: 2,
    spaceBetween: 0,
  },
  680: {
    slidesPerView: 3,
    spaceBetween: 0,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 0,
  },
  1300: {
    slidesPerView: 5,
    spaceBetween: 0,
  },
  1800: {
    slidesPerView: 6,
    spaceBetween: 0,
  },
};

const Carousel = ({ items = [], breakpoints }) => {
  return (
    <Swiper
      className="custom-swiper"
      slidesPerView={"auto"}
      spaceBetween={0}
      navigation={true}
      modules={[Navigation]}
      breakpoints={breakpoints ? breakpoints : defaultBreakpoints}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index} className="flex justify-content-center align-items-center custom-slide">
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
