import { useEffect, useRef, useState } from "react";

const useScrollGallery = () => {
  const galleryContainerRef = useRef(null);
  const galleryRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setScrollY(0);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 768) {
        const container = galleryContainerRef.current?.getBoundingClientRect();
        const gallery = galleryRef.current?.getBoundingClientRect();

        if (container === undefined || gallery === undefined) return;

        if (container.top < 0 && container.bottom - gallery.height > 0) {
          setScrollY(-container.top + 15);
        } else if (container.top < 0 && container.bottom - gallery.height < 0) {
          return;
        } else {
          setScrollY(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const gridStyle = {
    transform: `translateY(${scrollY ? `${scrollY}px` : "0"})`,
  };

  return { galleryContainerRef, gridStyle, galleryRef };
};

export default useScrollGallery;
