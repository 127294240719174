import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import localeText from '../../locale';

const ReCaptchaButton = props => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading1, setLoading1] = useState(false);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    setLoading1(true);

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    try {
      const token = await executeRecaptcha('yourAction');
      // Do whatever you want with the token
      props.setToken(token);
      setLoading1(false);
    }
    catch (e) {
      setLoading1(false);
      console.log(e);
      return;
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <>
      {props.token !== 'Execute recaptcha not yet available' &&
        <Button label={localeText('recaptcha')}
          icon="pi pi-check" loading={loading1} onClick={handleReCaptchaVerify}
          iconPos='right' className="p-button-outlined p-button-primary" />}
    </>
  );
};

export default ReCaptchaButton