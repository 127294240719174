const planIntroTextDictIT = {
  // Partner Intro
  registerAsBusinessPartner: "Registrati adesso come PARTNER",
  businessPartnerDescriptionPt1: "Per un'iscrizione di solo ",
  businessPartnerDescriptionPt2: "riceverai i seguenti vantaggi: ",
  instantlyEarn: "Guadagna istantaneamente ",
  worthOfExtracredit: " di crediti Extracredit (+ ",
  listBusinessPartner1:
    "se sei stato uno dei primi 1000 iscritti del tuo Paese a registrarsi come Partner)",
  listBusinessPartner2:
    "Ottieni la tua vetrina personale per offrire o richiedere prodotti e servizi",
  businessPartnerLearnMore: " sui vantaggi di essere un Partner",
  alreadyABusinessPartnerPt1: "Hai un'impresa? Considera il piano di ",
  alreadyABusinessPartnerPt2: " più adatto alle persone giuridiche.",
  // Manager Intro
  registerAsAreaManager: "Registrati adesso come MANAGER",
  textManager1: "Piano di vendita acquisito: GeoGold",
  textManager2: "Indicato per Persone Giuridiche e Grandi Attività",
  textManager3:
    "Con questo profilo sarai abilitato nell'offrire consulenza e nel vendere e/o acquistare beni e servizi sulla nostra piattaforma.",
  textManager4: "PROFILO GEOLOCALIZZATO",
  textManager5: "Ricevi subito",
  textManager6:
    "di Extrabonus da utilizzare come sconto nelle varie azioni di marketing che potrai effettuare sulla piattaforma per aumentare la tua visibilità e le tue vendite.",
  textManager7:
    "Ottieni il tuo negozio on-line geolocalizzato per offrire prodotti e servizi con annunci illimitati",
  textManager8: "Puoi inserire video nei tuoi annunci di vendita",
  textManager9:
    "Puoi creare automaticamente il tuo circolo economico di clienti, venditori e promotori attraverso le vendite e la geolocalizzazione",
  textManager10:
    "Riceverai ricompense per ogni attività svolta nel tuo circolo economico e in base allo sviluppo territoriale",
  textManager11: "Per un'iscrizione di solo",
  textManager12: "riceverai i seguenti vantaggi:",
  areaManagerDescriptionPt1:
    "L'Manager è il piano più vantaggioso e redditizio offerto dalla nostra piattaforma. Per un'iscrizione di ",
  areaManagerDescriptionPt2:
    "puoi registrare la tua azienda per lavorare con noi e ricevere i seguenti vantaggi:",
  listAreaManager1:
    "se sei uno dei primi 1000 iscritti del tuo Paese a registrare la tua azienda come Manager)",
  listAreaManager2:
    "Ottieni il tuo spazio e-commerce per far conoscere e vendere i tuoi prodotti e servizi",
  listAreaManager3: "Ricevi automaticamente nuovi clienti nel tuo circolo economico",
  areaManagerLearnMore: "  sui numerosi vantaggi di essere un Manager",
  // Customer Intro
  registerAsSpecialCustomer: "Registrati gratuitamente come CUSTOMER",
  specialCustomerDescription: "Inizia ad acquistare beni e servizi ricevendo sempre un cashback.",
  specialCustomerDescription2:
    "Utilizza i tuoi crediti per nuovi acquisti o convertili in denaro o in servizi attraverso il nostro programma fedeltà Extrapay.",
  specialCustomerDescription3:
    "Scarica la nostra App per iscriverti e ricevere subito un credito promozionale da utilizzare per i tuoi primi acquisti.",
  listSpecialCustomer1: "Potrai anche vendere!!",
  listSpecialCustomer2:
    "Carica i tuoi primi annunci di prodotti e servizi di forma gratuita ed inizia a guadagnare!",
  listSpecialCustomer3Pt1: "Scarica la nostra App ufficiale per iscriverti e guadagnare ",
  listSpecialCustomer3Pt2: "di crediti Extracredit gratuiti.",
  alreadyASpecialCustomer: "Sei già Customer? Considera di aggiornare il tuo profilo a ",
  or: " o ",
  taxExplanation: "* Imposta sulle vendite in accordo con il tuo Paese.",
  // Promoter Intro
  registerAsSalePromoter: "Registrati adesso come Promoter",
  customerLearnMore: "sul profilo Customer",
  // Seller Intro
  registerAsSeller: "Registrati adesso come SELLER",
  acquiredSalesPlanStartSeller: "Piano di vendita acquisito: StartSeller",
  desc1Seller: "Indicato per Persone Fisiche/Giuridiche, Servizi, Medie Attività e Drop-Shipper",
  desc2Seller:
    "Con questo profilo sarai abilitato nell'offrire consulenza, nel vendere e/o acquistare beni e servizi sulla nostra piattaforma.",
  desc3Seller: "Per un'iscrizione di solo",
  desc4Seller: "riceverai i seguenti vantaggi:",
  desc5Seller: "Ricevi subito ",
  desc6Seller:
    "di Extrabonus da utilizzare come sconto nelle varie azioni di marketing che potrai effettuare sulla piattaforma per aumentare la tua visibilità e le tue vendite.",
  desc7Seller:
    "Ottieni il tuo e-commerce personale per offrire prodotti e servizi con annunci fino a 500 annunci annuali",
  desc8Seller: "Hai un'impresa? Considera il profilo",
  desc9Seller: "più adatto alle persone giuridiche e alle grandi attività.",
  desc10Seller:
    "Puoi creare il tuo circolo economico di clienti, venditori e promotori attraverso le tue attività di vendita e consulenza",
  desc11Seller: "Riceverai ricompense per ogni attività svolta nel tuo circolo economico",
  desc12Seller:
    "This is a GEOLOCALIZZATO PROFILE and automatically verifies new clients. Eats? The user who will write to the App will increase the manager's economic circle and continue to increase the possibility of gaining revenue and credit aggiuntivi.",
  alreadyAsSeller: "Sei già un Seller? Considera di aggiornare il tuo piano a",
  discorverPlus: "su tutte le opportunità di essere Manager!",
  AcquiredSalesPlan: "Piano di vendita acquisito",
  suitableForIndividualsServicesAndSmallBusinesses:
    "Indicato per Persone Fisiche, Servizi e Piccole Attività",
  descPartner:
    "Con questo profilo sarai abilitato nell'offrire consulenza e nel vendere e/o acquistare beni e servizi sulla nostra piattaforma. ",
  register1: "Ricevi subito",
  register2:
    " di Extrabonus da utilizzare come sconto nelle varie azioni di marketing che potrai effettuare sulla piattaforma per aumentare la tua visibilità e le tue vendite.",
  textpartner2:
    "Ottieni la tua vetrina personale per offrire prodotti e servizi fino a 50 annunci annui",
  textpartner3:
    "Puoi creare il tuo circolo economico di clienti, venditori e promotori attraverso le tue attività di vendita e consulenza",
  textpartner4: "Riceverai ricompense per ogni attività svolta nel tuo circolo economico",
  NoMonthlyPaymentRequired: "Nessuna mensilità richiesta.",
  AnnualRenewal: "Rinnovo annuale",
  // promoter
  suitableFor:
    "Indicato per Persone Fisiche, Consulenti e Affiliati Commerciali, Persone Giuridiche",
  promoterResgisterText1:
    "Con questo profilo sarai abilitato nell'offrire consulenza e a sviluppare il network di aziende e privati.",
  promoterResgisterText2: "Nell'iscrizione gratuita otterrai:",
  promoterResgisterText3_1: "Subito",
  promoterResgisterText3_2:
    "di Extracredit da utilizzare come sconto nei tuoi acquisti sulla piattaforma.",
  promoterResgisterText4: "Un contratto di incaricato alle vendite.",
  promoterResgisterText5:
    "Puoi creare il tuo circolo economico di clienti e venditori attraverso le tue attività di consulenza",
  promoterResgisterText6:
    "Ricevere commissioni in denaro ed in crediti per ogni attività diretta e indiretta svolta nel tuo circolo economico",
  promoterResgisterText7: "Formazione e attività mensilità richiesta.",
  promoterResgisterText8: "Nessun rinnovo annuale richiesto.",
  promoterResgisterText9: "Scopri di piu' sui vantaggi di essere un Promoter",
  promoterResgisterText10: "Vuoi vendere anche tu? Considera i piani di vendita di",
  register3:
    "di Extrabonus da utilizzare come sconto nelle varie azioni di marketing che potrai effettuare sulla piattaforma per aumentare la tua visibilità e le tue vendite.",
};

export default planIntroTextDictIT;
