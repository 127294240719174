import { useEffect, useState } from "react";
import GetUserInfoService from "../service/GetUserInfoService";
import useApiStatus from "./useApiStatus";

const useRatingStore = ({ username, onError }) => {
  const { isLoading, setIsLoading, hasError, setHasError } = useApiStatus();
  const [ratingOfStore, setRatingOfStore] = useState([]);

  const getUserInfoService = new GetUserInfoService();

  const loadRatingStore = async (username) => {
    try {
      setIsLoading(true);
      const res = await getUserInfoService.getUserRating(username);
      if (res.messageCode !== 200001) {
        setHasError(true);
        onError(res.messageCode);
      } else {
        setHasError(false);
        setRatingOfStore(res.result);
      }
    } catch (e) {
      setHasError(true);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (username) loadRatingStore(username); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  return {
    isLoading,
    ratingOfStore,
    hasError,
  };
};

export default useRatingStore;
