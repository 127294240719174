const editProfileInfoDictEN = {
  choose: "Choose",
  cancel: "Cancel",
  dragImgFile: "Drag image file here",
  zipCode: "Zip Code",
  country: "Country",
  state: "State",
  city: "City",
  publicPlace: "Public Place",
  district: "District",
  number: "Number",
  countryPhoneCode: "Country Phone Code",
  regionalPhoneCode: "Regional Phone Code",
  phoneNumber: "Phone Number",
  changeProfilePic: "Change Profile Picture",
  confirmProfileChanges: "Confirm Profile Changes",
  // toast
  pleaseChoosePfp: "Please choose a profile picture first.",
  fileSizeTooBig: "File size is too big.",
  unexpectedErrorUploadImg: "Unexpected error while uploading image.",
  usernameCantBeEmpty:
    "The user name must contain only lowercase letters and numbers. It cannot be empty or contain spaces.",
  usernameNotAvailable: "Username not available.",
  unexpectedErrorChangingPfp: "Unexpected error while changing profile.",
  sucessfullyEditedProfile: "Successfully edited profile.",
  unexpectedSystemBehaviour: "Unexpected system behaviour.",
  canRetriveOnSaturdays: "Can the shipping service retrieve the order on Saturdays?",
  quantityOfDaysToRetrive:
    "Quantity of days for the shipping service to retrieve your order and take it to its destination",
};

export default editProfileInfoDictEN;
