import { InputNumber } from "primereact/inputnumber";

const InputNumberStyled = ({ labelTitle, htmlFor, ...rest }) => {
  return (
    <div>
      <div>
        <label htmlFor={htmlFor}>{labelTitle}:</label>
      </div>
      <div className="mt-2">
        <InputNumber
          label={labelTitle}
          name={htmlFor}
          buttonLayout="horizontal"
          className="sm:align-self-start sm:w-max w-full"
          inputClassName="text-center w-full"
          decrementButtonClassName="p-button-danger px-5 py-4 bg-white text-red-500 border-gray-400 hover:border-red-50"
          incrementButtonClassName="p-button-success px-5 py-4 bg-white text-green-500 border-gray-400 hover:border-green-500"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          {...rest}
        />
      </div>
    </div>
  );
};

export default InputNumberStyled;
