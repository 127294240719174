export const CartLogo = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.939326 1.73224C0.790626 1.88094 0.707088 2.08262 0.707088 2.29291C0.707088 2.50321 0.790626 2.70489 0.939326 2.85359C1.08803 3.00229 1.28971 3.08583 1.5 3.08583H2.8839C3.04354 3.08583 3.22115 3.15877 3.44211 3.5658C3.67238 3.98409 4.32667 6.04489 4.49143 6.64374L4.49186 6.64531L4.49292 6.65376L5.82501 11.1448L6.6285 14.0754C6.79712 14.6897 7.16281 15.2316 7.66935 15.6178C8.17589 16.004 8.79525 16.2131 9.43223 16.2131H15.7206C16.3525 16.213 16.9672 16.0069 17.4716 15.6262C17.976 15.2454 18.3426 14.7107 18.5158 14.1029L20.4484 7.32721C20.6895 6.48249 20.0551 5.64306 19.1777 5.64306H5.96456C5.92928 5.6431 5.89401 5.64452 5.85884 5.64729C5.7288 5.20749 5.06282 3.22221 4.83341 2.80355C4.48981 2.17556 3.8872 1.5 2.88284 1.5H1.5C1.28971 1.5 1.08803 1.58354 0.939326 1.73224ZM8.15723 13.6557L7.36326 10.76L7.34846 10.704L6.31873 7.22889H18.8277L16.9913 13.6684C16.9125 13.9446 16.7458 14.1876 16.5165 14.3607C16.2872 14.5337 16.0078 14.6273 15.7206 14.6273H9.43223C8.83702 14.6273 8.31581 14.2287 8.15723 13.6557ZM10.367 20.882C10.7636 20.4855 10.9863 19.9477 10.9863 19.3869C10.9863 18.8261 10.7636 18.2883 10.367 17.8918C9.9705 17.4952 9.43269 17.2724 8.87191 17.2724C8.31113 17.2724 7.77331 17.4952 7.37678 17.8918C6.98025 18.2883 6.75748 18.8261 6.75748 19.3869C6.75748 19.9477 6.98025 20.4855 7.37678 20.882C7.77331 21.2786 8.31113 21.5013 8.87191 21.5013C9.43269 21.5013 9.9705 21.2786 10.367 20.882ZM9.24569 19.0131C9.34482 19.1122 9.40052 19.2467 9.40052 19.3869C9.40052 19.5271 9.34482 19.6615 9.24569 19.7607C9.14656 19.8598 9.01211 19.9155 8.87191 19.9155C8.73171 19.9155 8.59726 19.8598 8.49813 19.7607C8.39899 19.6615 8.3433 19.5271 8.3433 19.3869C8.3433 19.2467 8.39899 19.1122 8.49813 19.0131C8.59726 18.914 8.73171 18.8583 8.87191 18.8583C9.01211 18.8583 9.14656 18.914 9.24569 19.0131Z"
        fill="#4CAF50"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3869 19.3869C18.3869 19.9477 18.1641 20.4855 17.7676 20.882C17.371 21.2786 16.8332 21.5013 16.2724 21.5013C15.7116 21.5013 15.1738 21.2786 14.7773 20.882C14.3808 20.4855 14.158 19.9477 14.158 19.3869C14.158 18.8261 14.3808 18.2883 14.7773 17.8918C15.1738 17.4952 15.7116 17.2724 16.2724 17.2724C16.8332 17.2724 17.371 17.4952 17.7676 17.8918C18.1641 18.2883 18.3869 18.8261 18.3869 19.3869ZM16.6462 19.0131C16.7453 19.1122 16.801 19.2467 16.801 19.3869C16.801 19.5271 16.7453 19.6615 16.6462 19.7607C16.5471 19.8598 16.4126 19.9155 16.2724 19.9155C16.1322 19.9155 15.9978 19.8598 15.8986 19.7607C15.7995 19.6615 15.7438 19.5271 15.7438 19.3869C15.7438 19.2467 15.7995 19.1122 15.8986 19.0131C15.9978 18.914 16.1322 18.8583 16.2724 18.8583C16.4126 18.8583 16.5471 18.914 16.6462 19.0131Z"
        fill="#4CAF50"
      ></path>
      <path
        d="M16.801 19.3869C16.801 19.2467 16.7453 19.1122 16.6462 19.0131C16.5471 18.914 16.4126 18.8583 16.2724 18.8583C16.1322 18.8583 15.9978 18.914 15.8986 19.0131C15.7995 19.1122 15.7438 19.2467 15.7438 19.3869C15.7438 19.5271 15.7995 19.6615 15.8986 19.7607C15.9978 19.8598 16.1322 19.9155 16.2724 19.9155C16.4126 19.9155 16.5471 19.8598 16.6462 19.7607C16.7453 19.6615 16.801 19.5271 16.801 19.3869Z"
        fill="#4CAF50"
      ></path>
      <path
        d="M9.40052 19.3869C9.40052 19.2467 9.34482 19.1122 9.24569 19.0131C9.14656 18.914 9.01211 18.8583 8.87191 18.8583C8.73171 18.8583 8.59726 18.914 8.49813 19.0131C8.39899 19.1122 8.3433 19.2467 8.3433 19.3869C8.3433 19.5271 8.39899 19.6615 8.49813 19.7607C8.59726 19.8598 8.73171 19.9155 8.87191 19.9155C9.01211 19.9155 9.14656 19.8598 9.24569 19.7607C9.34482 19.6615 9.40052 19.5271 9.40052 19.3869Z"
        fill="#4CAF50"
      ></path>
    </svg>
  );
};
