

const ProductVariations = ({
  productInfo,
  attribute1Values,
  attribute2Values,
  currentAttribute1Value,
  currentAttribute2Value,
  checkAndSetCurrentAttribute1Value,
  checkAndSetCurrentAttribute2Value,
}) => {
  return (
    <>
      {productInfo.data.productVariations.length > 0 && (
        <>
          {productInfo.data.productVariations.length > 0 && (
            <div className="mb-4">
              <div className="my-2">
                {productInfo.data.attributes[0].attribute}:<br></br>
              </div>
              <div className="flex align-items-center flex-wrap">
                {attribute1Values.map((value) => {
                  return (
                    <div key={value.id}>
                      {currentAttribute1Value && value.id === currentAttribute1Value.id ? (
                        <div
                          className="bg-primary text-0 p-2 p-1 text-sm flex justify-content-center align-items-center mx-2"
                          style={{
                            borderRadius: ".5em",
                            outline: "2px solid #08790a",
                            outlineOffset: "2px",
                          }}
                          id={`at${value.id}`}
                        >
                          {value.attributeValue}
                        </div>
                      ) : (
                        <div
                          className="bg-primary text-0 p-2 p-1 text-sm flex justify-content-center align-items-center mx-2 cursor-pointer"
                          style={{ borderRadius: ".5em" }}
                          id={`at${value.id}`}
                          onClick={(e) => checkAndSetCurrentAttribute1Value(e, value)}
                        >
                          {value.attributeValue}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {productInfo.data.attributes[1] && (
                <>
                  <div className="my-2">
                    {productInfo.data.attributes[1].attribute}:<br></br>
                  </div>
                  <div className="flex align-items-center flex-wrap">
                    {attribute2Values.map((value) => {
                      return (
                        <div key={value.id}>
                          {currentAttribute2Value && value.id === currentAttribute2Value.id ? (
                            <div
                              className="bg-primary text-0 p-2 p-1 text-sm flex justify-content-center align-items-center mx-2"
                              style={{
                                borderRadius: ".5em",
                                outline: "2px solid #08790a",
                                outlineOffset: "2px",
                              }}
                              id={`at${value.id}`}
                            >
                              {value.attributeValue}
                            </div>
                          ) : (
                            <div
                              className="bg-primary text-0 p-2 p-1 text-sm flex justify-content-center align-items-center mx-2 cursor-pointer"
                              style={{ borderRadius: ".5em" }}
                              id={`at${value.id}`}
                              onClick={(e) => checkAndSetCurrentAttribute2Value(e, value)}
                            >
                              {value.attributeValue}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProductVariations;
