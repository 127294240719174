import { useEffect, useState } from "react";
import GetUserInfoService from "../service/GetUserInfoService";
import useApiStatus from "./useApiStatus";
import localeText from "../locale";

const useGetStoresNames = ({ onError }) => {
  const { isLoading, setIsLoading, hasError, setHasError } = useApiStatus();
  const [storeNames, setStoreNames] = useState([]);

  const getUserInfoService = new GetUserInfoService();

  const loadStoreNames = async () => {
    try {
      setIsLoading(true);
      const res = await getUserInfoService.getAllUserShop();
      if (res.messageCode !== 200001) {
        setHasError(true);
        onError(res.messageCode);
      } else {
        setHasError(false);
        setStoreNames([
          { id: 0, title: localeText("all"), user: { username: "ALL" } },
          ...res.result,
        ]);
      }
    } catch (e) {
      setHasError(true);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadStoreNames();
    return () => {
      setIsLoading(false);
      setHasError(false);
      setStoreNames([]);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    storeNames,
    hasError,
  };
};

export default useGetStoresNames;
