export const inputs = [
  {
    title: "currency",
    inputs: [
      { id: 1, value: "ALL", description: "all", type: "currency" },
      { id: 2, value: "EUR", description: "eur", type: "currency" },
      { id: 3, value: "USD", description: "usd", type: "currency" },
      { id: 4, value: "BRL", description: "brl", type: "currency" },
      { id: 5, value: "MXN", description: "mxn", type: "currency" },
      { id: 6, value: "TRY", description: "try", type: "currency" },
    ],
  },
  {
    title: "type",
    inputs: [
      { id: 1, value: "ALL", description: "all", type: "type" },
      { id: 2, value: "SERVICE", description: "service", type: "type" },
      { id: 3, value: "DOWNLOADABLE", description: "downloadable", type: "type" },
      { id: 4, value: "PRODUCT", description: "product", type: "type" },
    ],
  },
  {
    title: "country",
    inputs: [
      { id: 1, value: "ALL", description: "all", type: "area" },
      { id: 2, value: "IT", description: "italy", type: "area" },
      { id: 3, value: "BR", description: "brazil", type: "area" },
      { id: 4, value: "MX", description: "mexico", type: "area" },
    ],
  },
];
