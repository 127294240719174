import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import localeText from "../../../../locale";

const questions = [
  {
    title: "faqTitle1",
    content: "faqAnswer1",
  },
  {
    title: "faqTitle2",
    content: "faqAnswer2",
  },
  {
    title: "faqTitle3",
    content: "faqAnswer3",
  },
  {
    title: "faqTitle4",
    content: "faqAnswer4",
  },
  {
    title: "faqTitle5",
    content: "faqAnswer5",
  },
  {
    title: "faqTitle6",
    content: "faqAnswer6",
  },
  {
    title: "faqTitle7",
    content: "faqAnswer7",
  },
  {
    title: "faqTitle8",
    content: "faqAnswer8",
  },
  {
    title: "faqTitle9",
    content: "faqAnswer9",
  },
  {
    title: "faqTitle10",
    content: "faqAnswer10",
  },
];

const LandingFaq = () => {
  return (
    <div className="bg-green-600">
      <Wrapper classnames="bg-green-600 py-7 lg:py-8 px-2 w-full text-center">
        <h1
          className="text-4xl lg:text-5xl font-bold text-white mx-auto mb-8"
          style={{ maxWidth: "20ch" }}
        >
          {localeText("frequentlyAskedQuestion")}
        </h1>

        <Accordion
          className="lg:w-7 sm:w-10 w-11 mx-auto overflow-hidden"
          activeIndex={0}
          style={{ borderRadius: "1rem" }}
        >
          {questions.map((question, i) => (
            <AccordionTab
              key={i}
              header={<p className="text-left">{localeText(question.title)}</p>}
            >
              <p className="m-0 text-left">{localeText(question.content)}</p>
            </AccordionTab>
          ))}
        </Accordion>
      </Wrapper>
    </div>
  );
};

export default LandingFaq;
