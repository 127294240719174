import { formatPrice } from "../../../../utilities/formatPrice";

const MinimalPrice = ({ productInfo, currentVariation, currency }) => {
  const { product, attributes } = productInfo?.data;
  const { promotionalPriceUnique, priceUnique, percentExtraCoin } = product;

  const cashback = percentExtraCoin / 100;

  if (!(attributes.length === 0 || currentVariation)) return null;

  return (
    <p className="text-green-500 lg:text-xl text-base m-0">
      Cashback:{" "}
      <strong className="text-green-500 p-0">
        {attributes.length === 0 ? (
          <>
            {promotionalPriceUnique ? (
              <>
                {currency} {formatPrice(promotionalPriceUnique * cashback)}
              </>
            ) : (
              <>
                {currency} {formatPrice(priceUnique * cashback)}
              </>
            )}
          </>
        ) : (
          <>
            {currency} {formatPrice(currentVariation.price * cashback)}
          </>
        )}
      </strong>
    </p>
  );
};

export default MinimalPrice;
