function handleLocale(locale) {
  let localeISO = 'en-US';

  switch (locale) {
    case 'pt':
      localeISO = 'pt-BR';
      break;
    case 'it':
      localeISO = 'it';
      break;
    case 'es':
      localeISO = 'es-MX';
      break;
    default:
      break;
  }

  return localeISO;
}

export default handleLocale;