import React from "react";
import DropdownSearch from "../DropdownSearch/DropdownSearch";
import SearchInputsMap from "../SearchInputsMap/SearchInputsMap";

const AllSearchInputs = ({
  isLoading,
  searchParams,
  setSearchParams,
  storeNames,
  isAdvancedFilter,
}) => {
  const isDisabled = isLoading ? "opacity-80 pointer-events-none" : "";
  return (
    <>
      <DropdownSearch
        isDisabled={isDisabled}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        storeNames={storeNames}
      />

      <SearchInputsMap
        isDisabled={isDisabled}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isAdvancedFilter={isAdvancedFilter}
      />
    </>
  );
};

export default AllSearchInputs;
