const validateUserInfoDictPT = {
  pleaseEnterValidEmail: "Por favor insira um endereço de e-mail válido.",
  thereIsAnExisting: "Já existe uma conta associada a este e-mail.",
  usernameWhitespace: "O nome de usuário não pode ter caracteres de espaço em branco.",
  thereIsAnDocNumber: "Existe uma conta existente associada a este número de documento.",
  invalidCPF: 'CPF inválido',
  invalidCNPJ: 'CNPJ inválido',
  invalidCodiceFiscale: 'Codice Fiscale inválido',
  invalidPartitaIVA: 'Partita IVA inválido',
  invalidDocumentNumber: 'Número de documento fiscal inválido',
  unexpectedErrorRegisteringSC: 'Erro inesperado ao registrar Customer',
  invalidVoucher: 'Voucher inválido.',
  passwordFieldsMismatched: 'Os campos de senha são incompatíveis.',
  pleaseFillOutAll: 'Por favor preencha todos os campos.',
  pleaseAgreeTerms: 'Por favor concorde com os termos e condições.',
  pleaseTypeLongerPassword: 'Digite uma senha com mais de 6 caracteres.',
  pleaseTypeShorterPassword: 'Digite uma senha com menos de 19 caracteres.',
  invalidPassword: 'Senha inválida. Deve ter apenas caracteres alfanuméricos e símbolos - _',
  noSpecialCharacters: 'O nome de usuário só pode ter letras e/ou números.',
  invalidRFC: 'RFC inválido.',
}

export default validateUserInfoDictPT