const userDashboardHomeDictIT = {
  balance: "Saldo",
  bonus: "Rendita giornaliera",
  inbound: "Credito",
  outbound: "Debito",
  every: "Ogni",
  AmountIsAutomaticallyTransferredToWallet:
    " l'importo viene automaticamente trasferito sul portafoglio principale.",
  currentlyYouCan: "Attualmente puoi utilizzare fino al ",
  ofYourExtracoin: " del saldo Extracredit nel pagamento dei tuoi acquisti.",
  extracoinOnlyBetweenUsers: "Extracredit è solo per acquisti e trasferimenti tra utenti!",
  indirects: "Indiretti",
  stats: "Statistiche",
  plan: "Piano",
  subscription: "Sottoscrizione",
  daysLeft: " giorni rimasti",
  activation: "Attivazione",
  seeAllDirects: "Vedi tutte le dirette",
  youDontHaveAny: "Non hai ancora utenti diretti nella tuo circolo economico.",
  days: "giorni",
  months: "mesi",
  year: "anno",
  numberOfUsersInNetwork: "Numero di utenti aggiunti alla tuo circolo economico",
  date: "Data",
  monthNames: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "April",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],
  quantity: "Quantità",
  referralLinks: "Link di riferimento",
  copy: "Copia",
  copiedToClipboard: "Copiato negli appunti.",
  copied: "Copiato",
  notActive: "Utente inattivo",
  balanceCommission: "commissioni",
  balanceSale: "vendite",
  message: "Messaggio",
  imAware: "Lo so",
  alert: "Avvertire",
  notEnabled:
    "Il tuo account non può spedire prodotti in questo momento. Modifica l'indirizzo del tuo profilo con un indirizzo valido.",
  inAnalysis: "Il tuo indirizzo di ritiro del pacco fornito è attualmente in fase di analisi.",
  enabled: "L'indirizzo di ritiro del pacco fornito è idoneo alla spedizione di prodotti.",
  thisIsPickupAddress:
    "Questo indirizzo verrà utilizzato dalle compagnie di spedizione per ritirare i tuoi pacchi",
  youDonthaveFinancialPassword: "Non hai una password finanziaria!",
  wantCreateFinancialPassword: "Vuoi creare una password finanziaria?",
  passwordCreatedSuccessfully: "Password creata con successo! Controlla la tua email!",
  youAlreadyHaveAFinancialPassword: "Hai già una password finanziaria!",
  youWantToCreateNewFinancialPassword: "Vuoi creare una nuova password finanziaria?",
  createNewFinancialPassword: "Crea nuova password finanziaria",
  renewSubscriptionValue: "Rinnova il valore della tua sottoscrizione",
  referralLinksExplanation:
    "Utilizza questi link per registrare nuovi utenti nei vari piani e incrementare automaticamente la tua cerchia economica guadagnando commissioni e entrate giornaliere secondo il nostro piano compensi.",
  profile: "Profilo",
  activationRequirements: "Per l'attivazione è necessaria 1 registrazione o 1 acquisto al mese.",
  safePayment: "Pagamento sicuro",
  balanceForExternalAds: "Saldo per annunci esterni",
  youCanUseThisAmountToMakeExternalAds: "Puoi utilizzare questo saldo per fare annunci esterni.",
};

export default userDashboardHomeDictIT;
