const extracoinTransferDictES = {
  extracoinTransfer: 'Transferencia de Extracredit',
  amount: 'Valor',
  usernameNotFound: 'Usuario no localizado.',
  receivingUsername: 'Usuario destinatario',
  amountCantBeZero: "El valor no puede ser cero.",
  attention: 'Atención',
  confirmTransactionExplanation: 'Al confirmar esta transacción, usted aceESa pagar una tarifa de',
  doYouAgree: 'Está de acuerdo?',
  transactionConfirmed: 'Transacción concluida con éxito! Espere la confirmación del destinatario.',
  transactionReceived: 'Transacción recibida con éxito!',
  sender: 'Remitente',
  acceESed: 'AceESa',
  toAgreeAndReceiveInsertCode: 'Para aceESar y recibir la transacción, por favor ingrese el código que se envió a su dirección de correo electrónico',
  seeStatement: 'Ver extracto',
  showingReceivedRequests: 'Solicitudes recibidas',
  showingSentRequests: 'Solicitudes enviadas',
  addressee: 'Destinatario',
  acceptTransaction: 'Aceptar',
  financialPasswordCantBeEmpty: 'La contraseña financiera no puede estar vacía.',
  codeCantBeEmpty: 'El código no puede estar vacío.',
}

export default extracoinTransferDictES;
