const message_EN = {
    "data": [
        {
            "code": 500000,
            "message": "Internal Error + Exception Message"
        },
        {
            "code": 500001,
            "message": "Referall not found"
        },
        {
            "code": 500002,
            "message": "Payment Error"
        },
        {
            "code": 500003,
            "message": "Error in Exchange Currencies"
        },
        {
            "code": 500004,
            "message": "Payment Method Not Found"

        },
        {
            "code": 500005,
            "message": "Payment Gateway Not Found"
        },
        {
            "code": 500006,
            "message": "Plan Not Found"
        },
        {
            "code": 500007,
            "message": "Amount is null or zero"
        },
        {
            "code": 500008,
            "message": "Amount is null or insufficient balance in Wallet"
        },
        {
            "code": 500009,
            "message": "This kind of user can not be referall"
        },
        {
            "code": 500010,
            "message": " ID of Plan must be Partner"
        },
        {
            "code": 500011,
            "message": "User Referall not found"
        },
        {
            "code": 500012,
            "message": " User not found"
        },
        {
            "code": 500013,
            "message": "Fail in attempt to authenticate user"
        },
        {
            "code": 500014,
            "message": "Error in decode JWT Token"
        },
        {
            "code": 500015,
            "message": "This JWT token does not belongs to the user in request"
        },
        {
            "code": 500016,
            "message": "Username is not available"
        },
        {
            "code": 500017,
            "message": "There is an existing account associated with this email"
        },
        {
            "code": 500018,
            "message": "Field Currency is empty or null"
        },
        {
            "code": 500019,
            "message": "Error in upload File"
        },
        {
            "code": 500020,
            "message": "Transfer payment could not be confirmed"
        },
        {
            "code": 500021,
            "message": "Customer can not transfer Extracredit"
        },
        {
            "code": 500022,
            "message": "Insufficient Balance in Extracredit Wallet"
        },
        {
            "code": 500023,
            "message": "Insufficient Balance in Wallet"
        },
        {
            "code": 500024,
            "message": "There is an existing account associated with this Document Number"
        },
        {
            "code": 500025,
            "message": "This E-mail does not corresponds to this user"
        },
        {
            "code": 500026,
            "message": "User plan not found"
        },
        {
            "code": 500027,
            "message": "Invalid Voucher"
        },
        {
            "code": 500028,
            "message": "Voucher Payment is valid only to Manager Register"
        },
        {
            "code": 500029,
            "message": "ID of transaction not found"
        },
        {
            "code": 500030,
            "message": "This transaction does not belongs to this user"
        },
        {
            "code": 500031,
            "message": "This transaction is not pending of tranfer confirmation"
        },
        {
            "code": 500032,
            "message": "Category not found with correspondent ID"
        },
        {
            "code": 500033,
            "message": "Attribute not found with correspondent ID"
        },
        {
            "code": 500034,
            "message": "The Attribute ID does not correspondent to the user in request"
        },
        {
            "code": 500035,
            "message": "SpecialCostumer can not create products"
        },
        {
            "code": 500036,
            "message": "Subcategory not found"
        },
        {
            "code": 500037,
            "message": "Product not found"
        },
        {
            "code": 500038,
            "message": "The Product ID does not correspondent to the user in request"
        },
        {
            "code": 500039,
            "message": "The Product is already associated to this Attribute"
        },
        {
            "code": 500040,
            "message": "Amount minimum to create a cupom is 50"
        },
        {
            "code": 500042,
            "message": "Field Language is empty or null"
        },
        {
            "code": 500041,
            "message": "Coupon is invalid"
        },
        {
            "code": 500043,
            "message": "The coupon does not belongs to the referall user"
        },
        {
            "code": 500044,
            "message": "Customer can not create coupons"
        },
        {
            "code": 500045,
            "message": "The Product is not associated to this Attribute"
        },
        {
            "code": 500046,
            "message": "The Product has already variations registered"
        },
        {
            "code": 500047,
            "message": "You can not create a variation with same attibutes"
        },
        {
            "code": 500048,
            "message": "All attributes must have at least one value assigned"
        },
        {
            "code": 500049,
            "message": "Brand is not associate to the subcategory"
        },
        {
            "code": 500050,
            "message": "Field subcategory not found"
        },
        {
            "code": 500051,
            "message": "Brand not found"
        },
        {
            "code": 500052,
            "message": "Type is not associate to the subcategory"
        },
        {
            "code": 500053,
            "message": "Type not found"
        },
        {
            "code": 500054,
            "message": "GenderProduct is not associate to the subcategory"
        },
        {
            "code": 500055,
            "message": "GenderProduct not found"
        },
        {
            "code": 500056,
            "message": "Product Variation not found"
        },
        {
            "code": 500057,
            "message": "Product may have only 2 attributes"
        },
        {
            "code": 500058,
            "message": "Product does not have any attribute registered"
        },
        {
            "code": 500059,
            "message": "This product has variations. You can not set amount total"
        },
        {
            "code": 500060,
            "message": "This variation does not corresponds to this product"
        },
        {
            "code": 500061,
            "message": "Field Language is empty or null"
        },
        {
            "code": 500062,
            "message": "The order must have at least one item"
        },
        {
            "code": 500063,
            "message": "Status permitted: PUBLISHED or UNPUBLISHED"
        },
        {
            "code": 500064,
            "message": "Buyer and Seller must be differents"
        },
        {
            "code": 500065,
            "message": "Order not found"
        },
        {
            "code": 500066,
            "message": "This order does not belongs to this user"
        },
        {
            "code": 500067,
            "message": "The product in the item does not belongs to the order seller"
        },
        {
            "code": 500068,
            "message": "Item not found in the Order"
        },
        {
            "code": 500069,
            "message": "This product has variations, please specify a idvariation"
        },
        {
            "code": 500070,
            "message": "Percent of cashback must be between 1% and 15% for Partners."
        },
        {
            "code": 500071,
            "message": "Percent of cashback must be between 1% and 35% for Managers."
        },
        {
            "code": 500072,
            "message": "Extracredit percentage is above what is requested by the seller"
        },
        {
            "code": 500073,
            "message": "You must inform how you want to pay (wallet and extracredit)."
        },
        {
            "code": 500074,
            "message": "Out of stock -> Produto"
        },
        {
            "code": 500075,
            "message": "Unable to modify/delete an item from an order that is not open"
        },
        {
            "code": 500076,
            "message": "The maximum possible extracredit amount has been exceeded. Please review your order!"
        },
        {
            "code": 500077,
            "message": "This user is not the seller of this order"
        },
        {
            "code": 500078,
            "message": "This order can not be sent"
        },
        {
            "code": 500079,
            "message": "This user is not the buyer of this order"
        },
        {
            "code": 500080,
            "message": "This order can not be received"
        },
        {
            "code": 500081,
            "message": "Career not found"
        },
        {
            "code": 500082,
            "message": "The origin Wallet has insufficient balance in Wallet to pay the fee"
        },
        {
            "code": 500083,
            "message": "The destiny Wallet has insufficient balance in Wallet to pay the fee"
        },
        {
            "code": 500084,
            "message": "The origin Wallet Extracredit has insufficient balance in Wallet to transfer"
        },
        {
            "code": 500085,
            "message": "The destiny Wallet Extracredit has insufficient balance in Wallet to transfer"
        },
        {
            "code": 500086,
            "message": "Transfer ID not found"
        },
        {
            "code": 500087,
            "message": "This user has no relationship with this Transfer"
        },
        {
            "code": 500088,
            "message": "User origin and destiny are the same"
        },
        {
            "code": 500089,
            "message": "Confirmation transfer code is not correct"
        },
        {
            "code": 500090,
            "message": "The origin user still not confirmed the transaction"
        },
        {
            "code": 500091,
            "message": "You can only rate orders that you tagged as received"
        },
        {
            "code": 500092,
            "message": "You have already rated this order"
        },
        {
            "code": 500093,
            "message": "Rates must be between 1 and 5"
        },
        {
            "code": 500094,
            "message": "Current password is incorrect"
        },
        {
            "code": 500095,
            "message": "The new password must be different from the current password"
        },
        {
            "code": 500096,
            "message": "Phone confirmation token not found"
        },
        {
            "code": 500097,
            "message": "This phone number is already confirmed"
        },
        {
            "code": 500098,
            "message": "Invalid code - confirmation phone number"
        },
        {
            "code": 500099,
            "message": "Only Customers can upgrade to Manager"
        },
        {
            "code": 500100,
            "message": "There is already a payment associated to this transactionid"
        },
        {
            "code": 500101,
            "message": "The payment transaction could not be confirmed"
        },
        {
            "code": 500102,
            "message": "Only Customers can upgrade to Partner"
        },
        {
            "code": 500103,
            "message": "User is not active. You can not request withdraws."
        },
        {
            "code": 500104,
            "message": "The sender user does not have relation with this order."
        },
        {
            "code": 500105,
            "message": "The receiver user does not have relation with this order."
        },
        {
            "code": 500106,
            "message": "Chat message not found."
        },
        {
            "code": 500107,
            "message": "User is not the receiver of this Chat message."
        },
        {
            "code": 500108,
            "message": "This user may not see the messages of this order."
        },
        {
            "code": 500109,
            "message": "Only Managers can indicate a Promoter."
        },
        {
            "code": 500110,
            "message": "Id of Plan must be Promoter."
        },
        {
            "code": 500111,
            "message": "Promoter can not create products."
        },
        {
            "code": 500112,
            "message": "Admin can not create products."
        },
        {
            "code": 500113,
            "message": "This role requires admin permission."
        },
        {
            "code": 500114,
            "message": "Field latitude/longitude are required."
        },
        {
            "code": 500115,
            "message": "Field origin of register is required."
        },
        {
            "code": 500116,
            "message": "If the product is downloadable, you must supply the fields downloadtype, urldownload and urldownloadsample."
        },
        {
            "code": 500117,
            "message": "If at least one item is a downloadable product, all the items must be downloadable."
        },
        {
            "code": 500118,
            "message": "This order does not have downloadable items."
        },
        {
            "code": 500119,
            "message": "This device is already registered."
        },
        {
            "code": 500120,
            "message": "This device is not registered."
        },
        {
            "code": 500121,
            "message": "This device does not belongs to this user."
        },
        {
            "code": 500122,
            "message": "Product downloadable can not create variations."
        },
        {
            "code": 500123,
            "message": "Product downloadable can not receive attributes."
        },
        {
            "code": 500124,
            "message": "Violated minimum withdraw allowed."
        },
        {
            "code": 500125,
            "message": "Only Managers can create products with technical informations."
        },
        {
            "code": 500126,
            "message": "Technical Information id not found."
        },
        {
            "code": 500127,
            "message": "Fields weight, height, width or length not found."
        },
        {
            "code": 500128,
            "message": "This product has already been favorited by this user."
        },
        {
            "code": 500129,
            "message": "This product has not been favorited by this user."
        },
        {
            "code": 500130,
            "message": "Withdraw not found."
        },
        {
            "code": 500131,
            "message": "This withdraw has been already processed."
        },
        {
            "code": 500132,
            "message": "This user is already blocked."
        },
        {
            "code": 500133,
            "message": "This user is already unblocked."
        },
        {
            "code": 500134,
            "message": "Search not found."
        },
        {
            "code": 500135,
            "message": "This search has been already confirmed."
        },
        {
          "code": 500136,
          "message": "There is already a Brand with this description."
        },
        {
            "code": 500137,
            "message": "This type is already associated to this subcategory."
        },
        {
            "code": 500138,
            "message": "This brand is already associated to this subcategory."
        },
        {
            "code": 500139,
            "message": "Marketplace configuration not found."
        },
        {
            "code": 500140,
            "message": "This product can not be shipped. Please, contact the seller."
        },
        {
            "code": 500141,
            "message": "We can not ship from this country yet."
        },
        {
            "code": 500142,
            "message": "We could not find a carrier for your order."
        },
        {
            "code": 500143,
            "message": "This seller can not ship yet."
        },
        {
            "code": 500144,
            "message": "This product can not be shipped at the moment."
        },
        {
            "code": 500146,
            "message": " Downloadable products can not be shipped."
        },
        {
            "code": 500147,
            "message": "Services can not be shipped."
        },
        {
            "code": 500148,
            "message": "This order does not have still a parcel assigned. Please wait a few minutes and try again."
        },
        {
            "code": 500149,
            "message": "There is already a pickup booked to this parcel."
        },
        {
            "code": 500150,
            "message": "Only the seller can book a pickup."
        },
        {
            "code": 500151,
            "message": " Conversion not found."
        },
        {
            "code": 500152,
            "message": "Conversion expired."
        },
        {
            "code": 500153,
            "message": "This Conversion does not belongs to the user in request."
        },
        {
            "code": 500154,
            "message": "This conversion has been used."
        },
        {
            "code": 500155,
            "message": "This user has already registered the address. To modify, please contact support."
        },
        {
            "code": 500156,
            "message": "This order contains a service. All items must be services."
        },
        {
            "code": 500157,
            "message": "This order is not associated to a parcel."
        },
        {
            "code": 500158,
            "message": "Partners can not create more than 50 published products!."
        },
        {
            "code": 500159,
            "message": "Partners can not create more than 50 unpublished products!"
        },
        {
          "code": 500162,
          "message": "You can only renew your subscription from 10 days before its expiration date!"
        },
        {
            "code": 500163,
            "message": "Renew can not be paid by this payment method!"
        },
        {
            "code": 500164,
            "message": "This method only can pay renewal!"
        },
        {
            "code": 500165,
            "message": "This voucher can not be used to pay this plan!"
        },
        {
            "code": 500166,
            "message": "Financial password does not match."
        },
        {
            "code": 500167,
            "message": "Id of Plan must be Seller."
        },
        {
            "code": 500168,
            "message": "Percent of cashback must be between 1% and 25% for Sellers."
        },
        {
            "code": 500169,
            "message": "A product can not be service and downloadable at same time."
        },
        {
          "code": 500170,
          "message": "You are trying to add a service a order that has non-service items."
        },
        {
          "code": 500171,
          "message": "Product Question not found."
        },
        {
          "code": 500172,
          "message": "This user can not answer this question."
        },
        {
          "code": 500173,
          "message": "You must inform how you want to pay (wallet and extracredit)."
        },
        {
          "code": 500174,
          "message": "You can not ask about your own product."
        },
        {
          "code": 500175,
          "message": "Notification not found."
        },
        {
          "code": 500176,
          "message": "This Notification does not belongs to the user in request."
        },
        {
          "code": 500177,
          "message": "Your notifications could not be sent because your extracredit balance is insufficient, but your product is registered correctly."
        },
        {
          "code" : 500178,
          "message": "If your order has at least one item with free shipping, all items must be free shipping also."
        },
        {
            "code" : 500179,
            "message": "You can not create products."
        },
        {
            "code" : 500180,
            "message": "You can not upgrade to SilverPro."
        },
        {
            "code" : 500181,
            "message": "You can not upgrade to GeoGold."
        },
        {
            "code" : 500182,
            "message": "You can not upgrade to StartSeller."
        },
        {
            "code" : 500183,
            "message": "Share Content not found."
        },
        {
            "code" : 500184,
            "message": "This product has been already shared by this user."
        },
        {
            "code" : 500185,
            "message": "Wallet Extrabonus not found."
        },
        {
            "code" : 500186,
            "message": "This order can not be picked up at the seller address."
        },
        {
            "code" : 500187,
            "message": "Public Key not found."
        },
        {
            "code" : 500188,
            "message": "Transaction idCrypto not found."
        },
        {
            "code" : 500189,
            "message": "Payment error. We could not confirm your crypto wallet transaction."
        },
        {
            "code" : 500190,
            "message": "If the product is imported, you must supply the field OriginProductImported."
        },
        {
          "code" : 500191,
          "message": "If the product is imported from Banggood you must supply the field ProductIdBanggood."
        },
        {
          "code" : 500192,
          "message": "This product is not assigned to this attribute."
        },
        {
          "code" : 500193,
          "message": "This attribute is assigned in at least one variation! Delete the variations first."
        },
        {
          "code" : 500194,
          "message": "Edit your product and set the percent of cashback before publish."
        },
        {
          "code" : 500195,
          "message": "Percent of cashback must be between 1% and 5% for Customers."
        },
        {
          "code": 500198,
          "message": "This slug is not available."
        }
    ]
}

export default message_EN;
