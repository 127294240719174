const attributesDictES = {
  edit: 'Editar',
  attributeSearch: 'buscar Atributo',
  addNewAttribute: 'Agregar nuevo atributo',
  attributeName: 'Nombre del atributo',
  add: 'Agregar',
  attributeCreated: 'Atributo creado con éxito!',
  pleaseFillOutField: 'Por favor complete el campo.',
  goBackToAttributeList: 'Volver a lista de atributos',
  attributeValues: 'Valores del atributo',
  attribute: 'Atributo',
  value: 'Valor',
  listOfValues: 'Valores de',
  addNewValues: 'Agregar nuevos valores',
  valuesCreated: 'Valores creados y agregados al atributo con éxito!',
  // Assign Attributes
  assign: 'Asociar',
  assignAttributesToProduct: 'Asociar atributos al producto',
  pressARowToSelect: 'Presione una línea para seleccionar un atributo.',
  selectAtLeastOneAttribute: 'Seleccione al menos un atributo.',
  canOnlyHaveUpTo2Attributes: 'Usted sólo puede seleccionar hasta dos atributos.',
  attributeAssignedSuccessfully: 'Atributo(s) asociado(s) con éxito!',
  confirmAssignAttributes: "Usted está a punto de asociar atributos a este producto, y eso lo convertirá en un producto variable. Desea continuar con esta acción?",
  attributeEnterExplanation: 'Presiona las teclas Enter o "," para agregar un valor (puedes agregar múltiples valores)',
}

export default attributesDictES;