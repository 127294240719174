import axios from 'axios'
import apiURI from './Api';

export default class SearchService {
  async postRegisterSearch(search, username) {
    const bodyJson = {
      search: search,
      user: { username }
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/search/register`, data: bodyJson
    });

    return response.data;
  }

  async postConfirmSearch(searchId, productId) {
    const bodyJson = {
      idsearch: searchId,
      idproduct: productId
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/search/confirm`, data: bodyJson
    });

    return response.data;
  }
}