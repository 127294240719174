const userStoreDictPT = {
  setUpStore: "Configure sua loja",
  userStoreExplanation:
    "Seus produtos publicados serão exibidos na página da sua loja. Para configurá-la, forneça um título, uma descrição e um logotipo:",
  storeSetUpSuccessfully: "Loja configurada com sucesso!",
  editYourStore: "Edite sua loja",
  storeEditedSuccessfully: "Loja editada com sucesso!",
  thisUserHasntSetUpStore: "Este usuário ainda não configurou uma loja.",
  ratings: "avaliações",
  rating: "avaliação",
  noRatings: "Sem avaliações de clientes", //'Avaliações dos Clientes',
  logoWrongAspectRatio:
    "O logotipo da loja deve estar na proporção de 4:1 (por exemplo, 1200x300).",
  cropImage: "Recortar imagem",
  addCategory: "Adicionar categoria",
  slug: "Link personalizado da loja",
  linkStore: "Link da loja",
};

export default userStoreDictPT;
