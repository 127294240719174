const attributesDictEN = {
  edit: 'Edit',
  attributeSearch: 'Attribute Search',
  addNewAttribute: 'Add new attribute',
  attributeName: 'Attribute name',
  add: 'Add',
  attributeCreated: 'Attribute created successfully!',
  pleaseFillOutField: 'Please fill out the field.',
  goBackToAttributeList: 'Go back to attribute list',
  attributeValues: 'Attribute values',
  attribute: 'Attribute',
  value: 'Value',
  listOfValues: 'Values of',
  addNewValues: 'Add new values',
  valuesCreated: 'Values created and added to the attribute successfully!',
  // Assign Attributes
  assign: 'Assign',
  assignAttributesToProduct: 'Assign attributes to product',
  pressARowToSelect: 'Press a row to select an attribute.',
  selectAtLeastOneAttribute: 'Please select at least one attribute.',
  canOnlyHaveUpTo2Attributes: 'You can only select up to two attributes.',
  attributeAssignedSuccessfully: 'Attribute(s) assigned successfully!',
  confirmAssignAttributes: "You're about to assign attributes to this product, and this will turn it into a variable product. Do you want to proceed with this action?",
  attributeEnterExplanation: 'Press the Enter or "," keys to add a value (you can add multiple values)',
}

export default attributesDictEN;