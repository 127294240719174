import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import localeText from "../../../../locale";
import { locale } from "../../../../utilities/locale/locale";
import useProductTechnicalInfo from "../../../../hooks/useProductTechnicalInfo";
import Loading from "../../../../components/Loading/Loading";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";

const ProductDescription = ({ productInfo }) => {
  const { showErrorToast } = useToastContext();
  const [activeTab, setActiveTab] = useState(0);
  const { product } = productInfo.data;
  const { loading: loadingTechnicalInfo, technicalInfo } = useProductTechnicalInfo({
    productInfo,
    onError: (code) => showErrorToast({ code }),
  });

  let localDescription = null;
  let genderDescription = null;
  if (product) {
    const localeDescription = {
      pt: product?.type?.descriptionPortuguese,
      en: product?.type?.descriptionEnglish,
      it: product?.type?.descriptionItalian,
      es: product?.type?.descriptionSpanish,
    };

    localDescription = localeDescription[locale] ? localeDescription[locale] : null;

    const localeGenderDescription = {
      pt: product?.gender?.descriptionPortuguese,
      en: product?.gender?.descriptionEnglish,
      it: product?.gender?.descriptionItalian,
      es: product?.gender?.descriptionSpanish,
    };

    genderDescription = localeGenderDescription[locale] ? localeGenderDescription[locale] : null;
  }

  const hasDescription =
    !!(product?.brand?.description || product?.model || localDescription || genderDescription) &&
    !loadingTechnicalInfo;

  const hasTechnicalInfo = !!technicalInfo?.length && !loadingTechnicalInfo;

  const hasDescriptionORTechnicalInfo = hasDescription || hasTechnicalInfo;

  useEffect(() => {
    if (hasDescription) setActiveTab(0);
    else if (hasTechnicalInfo) setActiveTab(1);
  }, [hasDescription, hasTechnicalInfo]);

  return (
    <>
      {hasDescriptionORTechnicalInfo && (
        <div className={`border-solid border-round border-1 border-gray-300 overflow-hidden`}>
          <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
            {hasDescription && (
              <TabPanel
                headerClassName="flex align-items-center lg:m-3 m-2"
                contentClassName="lg:p-6 p-2"
                headerTemplate={
                  <p
                    onClick={() => setActiveTab(0)}
                    className={`font-bold lg:text-lg text-md lg:ml-6 mx-3 lg:py-4 py-2 cursor-pointer hover:text-primary ${
                      activeTab === 0 ? "text-primary selectedInfo" : "text-gray-900"
                    }`}
                  >
                    {localeText("descriptionProduct")}
                  </p>
                }
              >
                {loadingTechnicalInfo && <Loading classNameIcon="text-5xl" />}
                {!loadingTechnicalInfo && product && (
                  <>
                    {product?.brand?.description && (
                      <div className="mb-2 mt-0 pt-0">
                        <p className="m-0">
                          <b>{localeText("brand")}</b>
                        </p>
                        <p className="py-0 mb-0 w-full">{product.brand.description}</p>
                      </div>
                    )}

                    {product?.model && (
                      <div className="mb-2">
                        <p className="m-0">
                          <b>{localeText("model")}</b>
                        </p>
                        <p className="py-0 mb-0 w-full">{product.model}</p>
                      </div>
                    )}

                    {localDescription && (
                      <div className="my-2">
                        <p className="m-0">
                          <b>{localeText("type")}</b>
                        </p>
                        <p className="py-0 mb-0 w-full">{localDescription}</p>
                      </div>
                    )}

                    {genderDescription && (
                      <div className="my-2">
                        <p className="m-0">
                          <b>{localeText("gender")}</b>
                        </p>
                        <p className="py-0 mb-0 w-full">{genderDescription}</p>
                      </div>
                    )}
                  </>
                )}
              </TabPanel>
            )}
            {technicalInfo.length > 0 && (
              <TabPanel
                headerClassName="lg:m-2 m-1 py-2"
                contentClassName="lg:p-6 p-2"
                headerTemplate={
                  <p
                    onClick={() => setActiveTab(1)}
                    className={`font-bold lg:text-lg text-md  mx-3 lg:py-4 py-2 cursor-pointer hover:text-primary ${
                      activeTab === 1 ? "text-primary selectedInfo" : "text-gray-900"
                    } ${hasDescriptionORTechnicalInfo ? "" : "lg:ml-6"}`}
                  >
                    {localeText("characteristics")}
                  </p>
                }
              >
                <div className="flex flex-column justify-content-start mb-4 lg:w-8">
                  <p className="font-bold">{localeText("productTechnicalInfo")}</p>
                  {technicalInfo.map((technicalInfo) => (
                    <div
                      key={`techinf${technicalInfo?.id}}`}
                      className="flex flex-column justify-content-start mb-2"
                    >
                      <b className="py-0 mb-0">{technicalInfo?.key}</b>
                      <p className="py-0 mb-0">{technicalInfo?.value}</p>
                    </div>
                  ))}
                </div>
              </TabPanel>
            )}
          </TabView>
        </div>
      )}
    </>
  );
};

export default ProductDescription;
