export const formatDate = (dateStamp) => {
  const date = new Date(dateStamp);
  let dd = date.getUTCDate();
  if (dd.toString().length === 1) dd = "0" + dd;

  let mm = date.getUTCMonth() + 1;
  if (mm.toString().length === 1) mm = "0" + mm;

  const yy = date.getUTCFullYear();

  return `${dd}/${mm}/${yy}`;
};

export const formatDateTime = (dateStamp) => {
  const date = new Date(dateStamp);
  let dd = date.getUTCDate();
  if (dd.toString().length === 1) dd = "0" + dd;

  let mm = date.getUTCMonth() + 1;
  if (mm.toString().length === 1) mm = "0" + mm;

  const yy = date.getUTCFullYear();

  let hh = date.getUTCHours();
  if (hh.toString().length === 1) hh = "0" + hh;

  let min = date.getUTCMinutes();
  if (min.toString().length === 1) min = "0" + min;

  return `${dd}/${mm}/${yy} - ${hh}:${min}`;
};
