const shopDictPT = {
  seeProduct: "Ver produto",
  firstBannerText: "O marketplace que te recompensa sempre",
  secondaryBannerText: "Faça parte da Extraconomy",
  secondaryBannerButtonText: "Conheça nossos planos",
  secondaryBannerText2: "Aumente seu poder econômico",
  searchQueryText: "Você pesquisou por:",
  subCategoryFilter: "Subcategorias",
  noProductsInCategory: "Não possui produtos",
  noProductsInSearch: "Nenhum produto encontrado",
  noSubcategories: "Não possui subcategorias",
  filters: "Filtros",
  downloadable: "Baixável",
  brazil: "Brasil",
  mexico: "México",
  italy: "Itália",
  brl: "BRL",
  mxn: "MXN",
  eur: "EUR",
  usd: "USD",
  gbp: "GBP",
  try: "TRY",
  results: "Resultados",
  result: "Resultado",
  selectStore: "Selecione uma loja",
  store: "Loja",
  filter: "Filtrar",
  registerAsCustomerFree: "Cadastre-se como Customer gratuitamente",
  register2: "Cadastre-se",
};

export default shopDictPT;
