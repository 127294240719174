import { Sidebar } from "primereact/sidebar";
import React from "react";
import Loading from "../../../../components/Loading/Loading";
import localeText from "../../../../locale";
import AllSearchInputs from "./components/AllSearchInputs/AllSearchInputs";

const FiltersInSearch = ({
  searchParams,
  setSearchParams,
  visible,
  onHide,
  isLoading,
  storeNames,
  isLoadingStoreNames,
}) => {

  return (
    <>
      <Sidebar visible={visible} position="right" onHide={onHide} style={{ width: "13.5rem" }}>
        <h5>{localeText("filters")}</h5>
        <hr />
        {isLoadingStoreNames ? (
          <Loading classNameIcon="text-4xl" />
        ) : (
          <div>
            <AllSearchInputs
              isLoading={isLoading}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              storeNames={storeNames}
            />
          </div>
        )}
      </Sidebar>

      <div className="lg:block hidden py-4 mr-3 bg-white border-gray-300 border-solid border-1 border-round justify-self-start min-width">
        <h5 className="px-4">{localeText("filters")}</h5>
        <hr />
        {isLoadingStoreNames ? (
          <Loading classNameIcon="text-4xl" />
        ) : (
          <div className="px-4">
            <AllSearchInputs
              isLoading={isLoading}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              storeNames={storeNames}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FiltersInSearch;
