import { createContext } from "react";
import { useRef } from "react";
import localeText from "../../locale";
import { Toast } from "primereact/toast";
import { useContext } from "react";

export const ToastContext = createContext(null);

const ToastProvider = ({ children }) => {
  const toast = useRef(null);

  const state = {
    showSuccessToast: ({ detail }) => {
      toast.current.show({
        severity: "success",
        summary: localeText("success"),
        detail: localeText(detail),
        life: 6000,
      });
    },
    showInfoToast: ({ summary, detail }) => {
      toast.current.show({
        severity: "info",
        summary: localeText(summary),
        detail: detail ? localeText(detail) : "",
        life: 6000,
      });
    },
    showErrorToast: ({ code = 500000, detail }) => {
      let msg;
      if (detail) {
        msg = localeText(detail);
      } else if (localeText("data")?.find((msg) => msg.code === code)) {
        msg = localeText("data")?.find((msg) => msg.code === code).message;
      } else {
        msg = localeText("errorOfSystem");
      }

      toast.current.show({
        severity: "error",
        summary: localeText("error"),
        detail: msg,
        life: 6000,
      });
    },
  };

  return (
    <ToastContext.Provider value={state}>
      <Toast ref={toast} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};

export default ToastProvider;
