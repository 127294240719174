const networkTableDictES = {
  showingFirstToLast: 'Mostrando {first} la {last} de {totalRecords}',
  profilePic: 'Foto de Perfil',
  name: 'Nombre',
  level: 'Nivel',
  searchByUsername: 'Buscar por nombre de usuario',
  searchByName: 'Buscar por nombre',
  searchByCountry: 'Buscar por país',
  searchByEmail: 'Buscar por email',
  searchByPlan: 'Buscar por plano',
}

export default networkTableDictES