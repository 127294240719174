export const extrapayDictIT = {
  extrapayArea: "Area Extrapay",
  convertionExpiration: "Data di scadenza",
  conversionRate: "Tasso di conversione",
  extrapayRegulation: "*Fare clic sulla carta per vedere il regolamento",
  noConversions: "Non ci sono conversioni disponibili",
  convert: "Convertire",
  convertExtracoinToWallet: "Converti Extracredit al portafoglio",
  errorConversionIsZero: "Errore nella conversione, il valore della conversione è zero",
  successConversion: "Conversione riuscita",
  errorExpiredConversion: "Errore nella conversione, conversione scaduta",
};
