const withdrawIntentDictEN = {
  paypalEmail: "Insert PayPal email below",
  amountWithdrawn: "Withdrawn amount from wallet",
  amountDeposited: "Deposited amount in PayPal",
  withdrawFee: "withdraw fee",
  paypalFee: "PayPal will apply a fee of",
  onTop: "on the amount transferred",
  requestWithdraw: "Request withdraw",
  amountWithdrawnSuccessfully: "Amount withdrawn successfully!",
  invalidemail: "invalid email",
  withdrawRequests: "Withdraw requests",
  requestDate: "Request date",
  processDate: "Process date",
  processing: "Processing",
  amountToDeposit: "Amount to deposit",
  amountRequested: "Amount requested",
  processRequest: "Process request",
  process: "Process",
  withdrawProcessedSuccessfully: "Withdraw request processed successfully!",
  askFinancialPassword: "Enter your financial password",
  financialPasswordIsWrong: "Incorrect financial password",
  withdrawWith: "Withdraw with",
  bank: "Bank",
  bankName: "Bank name",
  bankAccountNumber: "Bank account number",
  accountOwner: "Account owner name",
  allFieldsRequired: "All fields are required",
};

export default withdrawIntentDictEN;
