import { AdvancedImage } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";
import FavoriteButton from "../../../../components/FavoriteButton/FavoriteButton";
import ProductViews from "../../../../components/ProductViews/ProductViews";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";
import useConfirmSearch from "../../../../hooks/useConfirmSearch";
import localeText from "../../../../locale";
import { cloudinaryInstance } from "../../../../utilities/Cloudinary/cloudinaryInstance";
import { PLATFORM_PRODUCT_FEE } from "../../../../utilities/constants";
import { formatPrice } from "../../../../utilities/formatPrice";
import "../../../Shop/Shop.css";
import "./ProductCard.css";

import { Button } from "primereact/button";
import useProduct from "../../hooks/useProduct";
import { MiniAddToCart } from "./MiniAddToCart/MiniAddToCart";

const ProductCard = ({ product }) => {
  const { showErrorToast } = useToastContext();
  const { confirmSearch } = useConfirmSearch({
    productId: product?.id,
    onError: (code) => showErrorToast({ code }),
  });

  const {
    productInfo,
    loadingProductInfo,
    currentAttribute1Value,
    currentAttribute2Value,
    currentVariation,
    productQty,
  } = useProduct({ id: product?.id });

  const cashback = product?.percentExtraCoin / 100;
  const cashbackValue =
    (product?.promotionalPriceUnique ? product?.promotionalPriceUnique : product?.priceUnique) *
    cashback;
  const promotionalNormalPrice = product?.promotionalPriceUnique
    ? product?.promotionalPriceUnique * PLATFORM_PRODUCT_FEE + product?.promotionalPriceUnique
    : null;

  const normalPrice = product?.priceUnique * PLATFORM_PRODUCT_FEE + product?.priceUnique;

  const freightType = product?.shippingOption;
  const freightText =
    freightType === "PICKUP"
      ? localeText("paidOrWithdrawn")
      : freightType === "FREESENDCLOUD"
      ? localeText("freeToItaly")
      : freightType === "FREE"
      ? `${localeText("freight")} ${localeText("free")}`
      : `${localeText("freight")} ${localeText("paid")}`;

  const freightTextColor =
    freightType === "FREESENDCLOUD"
      ? "text-green-500"
      : freightType === "FREE"
      ? "text-green-500"
      : "text-primary";

  if (!product) return null;
  return (
    <div
      key={`pdis${product.id}`}
      className="block z-3 text-900 lg:flex md:flex align-items-center flex-column flex-wrap relative surface-0 p-0 mx-1 border-round border-1 border-300 overflow-hidden"
      style={{ width: "100%" }}
      // onClick={() => confirmSearch()}
    >
      <div
        style={{
          top: 5,
          borderRadius: 10,
        }}
        className="flex justify-content-end lg:px-2 px-1 align-items-center absolute z-4 w-full"
      >
        <ProductViews
          views={product?.views}
          className="productCard-vizualization md:text-sm text-xs"
        />
        <FavoriteButton productId={product?.id} className="text-red-600 text-2xl z-5" />
      </div>

      <div className="flex justify-content-center cursor-pointer" onClick={() => confirmSearch()}>
        <AdvancedImage
          cldImg={cloudinaryInstance
            .image(product?.imageid1)
            .resize(fill().width(250).height(250))
            .format("webp")}
          className="productCardImg"
        />
      </div>
      <div className="w-full mr-auto lg:px-4 px-3 pb-4">
        <div className="flex justify-content-between">
          <div></div>
          {product?.service ? (
            <span
              style={{ marginTop: "-21px", opacity: 0 }}
              className="text-0 p-1 text-xl border-round mr-3 text-right border-circle"
            >
              <i className="pi pi-download text-0 p-2" style={{ opacity: 0 }}></i>
            </span>
          ) : (
            <>
              {product?.downloadable ? (
                <span
                  style={{ marginTop: "-21px", backgroundColor: "#0288D1" }}
                  className="text-0 p-1 text-xl border-round mr-3 text-right border-circle"
                >
                  <i className="pi pi-download text-0 p-2"></i>
                </span>
              ) : (
                <>
                  {product?.stateofproduct ? (
                    <span
                      style={{
                        marginTop: "-16px",
                        backgroundColor: product?.stateofproduct === "NEW" ? "#0288D1" : "#f7b005",
                      }}
                      className={`text-0 py-2 px-3 border-round text-right font-bold text-xs ${
                        product?.stateofproduct === "NEW" ? "bg-green-500" : "bg-primary"
                      }`}
                    >
                      {product?.stateofproduct === "NEW" && localeText("new").toUpperCase()}
                      {product?.stateofproduct === "USED" && localeText("used").toUpperCase()}
                    </span>
                  ) : (
                    <span
                      style={{ marginTop: "-21px", opacity: 0 }}
                      className="absolute text-0 p-1 text-xl border-round mr-3 text-right border-circle"
                    >
                      <i className="pi pi-download text-0 p-2" style={{ opacity: 0 }}></i>
                    </span>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <h6
          title={product.title}
          className="mx-0 mt-1 mb-0 p-0 font-bold text-800 text-primary lg:text-lg text-base productCard-title"
        >
          {product?.title}
        </h6>

        {!promotionalNormalPrice && <span className="p-1"></span>}

        <div className="mx-0 p-0">
          <span
            className={`font-bold block border-round ${
              promotionalNormalPrice ? "line-through md:text-base text-sm" : "md:text-2xl text-base"
            }`}
            style={{ color: "#46a748", overflowWrap: "break-word", wordBreak: "break-word" }}
          >
            {product.currency} {formatPrice(normalPrice)}
          </span>
          {promotionalNormalPrice && (
            <span
              className="font-bold border-round md:text-2xl text-base"
              style={{ color: "#46a748", overflowWrap: "break-word", wordBreak: "break-word" }}
            >
              {product.currency} {formatPrice(promotionalNormalPrice)}
            </span>
          )}

          <ul className="m-0 p-0 list-none">
            <li className="flex flex-row flex-wrap justify-content-start align-items-center">
              <p className="text-700 lg:text-base md:text-base text-xs mb-0">Cashback&nbsp;</p>
              <p
                className="text-700 lg:text-base md:text-base text-xs mb-0 p-0"
                style={{ overflowWrap: "break-word", wordBreak: "break-word" }}
              >
                {product.currency} {formatPrice(cashbackValue)}
              </p>
            </li>
          </ul>

          {product.sendBy === "SELLER" ? (
            <>
              {!product?.downloadable && !product?.service && (
                <p
                  className={`lg:text-sm text-xs flex align-items-start mt-3 gap-1 ${freightTextColor}`}
                  style={{ minHeight: "25px" }}
                >
                  <i className={`pi pi-truck ${freightTextColor}`} /> {`${freightText}`}
                </p>
              )}
            </>
          ) : (
            <>
              {!product?.downloadable && !product?.service && (
                <p
                  className={`lg:text-sm text-xs flex align-items-start mt-3 gap-1 ${freightTextColor}`}
                  style={{ minHeight: "25px" }}
                >
                  <i className={`pi pi-truck ${freightTextColor}`} /> {`${freightText}`}
                </p>
              )}
            </>
          )}

          {product?.downloadable && (
            <p className="text-xs text-blue-500 mt-3" style={{ minHeight: "25px" }}>
              {localeText("downloadable")}
            </p>
          )}

          {product?.service && (
            <p className="text-xs text-blue-500 capitalize mt-3" style={{ minHeight: "25px" }}>
              {localeText("service")}
            </p>
          )}
        </div>
        {!loadingProductInfo && (
          <div className="flex align-items-center justify-content-around mt-2 gap-1">
            <MiniAddToCart
              productInfo={productInfo}
              currentVariation={currentVariation}
              currentAttribute1Value={currentAttribute1Value}
              currentAttribute2Value={currentAttribute2Value}
              productQty={productQty}
            />
            <Button
              className="see-product"
              label={localeText("seeProduct")}
              onClick={() => confirmSearch()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
