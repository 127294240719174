const withdrawIntentDictPT = {
  paypalEmail: "Insira o e-mail do PayPal abaixo",
  amountWithdrawn: "Valor sacado da carteira",
  amountDeposited: "Valor depositado no PayPal",
  withdrawFee: "taxa de saque",
  paypalFee: "PayPal aplicará uma taxa de",
  onTop: "sobre o valor transferido",
  requestWithdraw: "Solicitar saque",
  amountWithdrawnSuccessfully: "Valor sacado com sucesso!",
  invalidemail: "e-mail inválido",
  withdrawRequests: "Solicitações de saque",
  requestDate: "Data da solicitação",
  processDate: "Data do processamento",
  processing: "Processando",
  amountToDeposit: "Valor para depositar",
  amountRequested: "Valor solicitado",
  processRequest: "Processar solicitação",
  process: "Processar",
  withdrawProcessedSuccessfully: "Solicitação de saque processada com sucesso!",
  askFinancialPassword: "Digite sua senha financeira",
  financialPasswordIsWrong: "Senha financeira incorreta",
  withdrawWith: "Sacar com",
  bank: "Banco",
  bankName: "Nome do banco",
  bankAccountNumber: "Número da conta bancária",
  accountOwner: "Nome do titular da conta",
  allFieldsRequired: "Todos os campos são obrigatórios",
};

export default withdrawIntentDictPT;
