import React from 'react'
import AppHorizontalLogo from '../../../images/logo-white-horizontal.png';
import localeText from '../../../../locale';

const PrimaryBanner = () => {
  return (
    <div className="flex-initial flex align-items-center sm:flex-row flex-column justify-content-center bg-green-500 font-bold mb-5 text-white lg:p-8 p-4 border-round sm:gap-8 gap-2">
      <img src={AppHorizontalLogo} alt='app-logo' width={400} className='img-primaryBanner' />
      <p className='xl:text-4xl text-xl lg:w-25rem w-15rem text-center text-primary'>{localeText('firstBannerText')}</p>
    </div>
  )
}

export default PrimaryBanner
