import { useEffect, useState } from "react";
import ProductService from "../service/ProductService";

const useProductTechnicalInfo = ({ productInfo, onError }) => {
  const [loading, setLoading] = useState(true);
  const [technicalInfo, setTechnicalInfo] = useState([]);
  const productService = new ProductService();

  const loadTechnicalInfo = async () => {
    setLoading(true);
    const resTechInfo = await productService.getTechnicalInfo(productInfo.data.product.id);

    if (resTechInfo.messageCode !== 200001) {
      onError(resTechInfo.messageCode);
      if (resTechInfo.messageCode === 500000) console.log(resTechInfo);
    } else setTechnicalInfo(resTechInfo.result);

    setLoading(false);
  };

  useEffect(() => {
    if (productInfo) loadTechnicalInfo(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  return { loading, technicalInfo };
};

export default useProductTechnicalInfo;
