import axios from 'axios'
import apiURI from './Api';

export default class LoginService {
  async postLogin(username, password) {
    const login = {
      username: username,
      password: password
    }

    const response = await axios.post(`${apiURI}/login`, login);
    return response.data;
  }

  async getForgotPassword(email, username) {
    const response = await axios.get(`${apiURI}/user/forgetpassword/${username}/email/${email}`);
    return response.data;
  }

  async postRegisterLocation(token, latitude, longitude, username, precision) {
    const locationJson = {
      user: { username },
      longitude: longitude,
      latitude: latitude,
      precision: precision ? precision : 0,
    }

    const response = await axios({
      method: 'post', url: `${apiURI}/geolocation/registerlocation`, data: locationJson,
      headers: { 'Authorization': 'Bearer ' + token }
    })

    return response.data;
  }

  async postRegisterDevice(token, deviceId, typeDevice, username) {
    const deviceJson = {
      user:  { username } ,
      typeDevice: JSON.parse(typeDevice).toUpperCase(),
      deviceid: JSON.parse(deviceId),
    }
    
    const response = await axios({
      method: 'post', url: `${apiURI}/device/registerdevice`, data: deviceJson,
      headers: { 'Authorization': 'Bearer ' + token }
    })

    return response.data;
  }
}
