import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";
import localeText from "../../../../locale";
import GetUserInfoService from "../../../../service/GetUserInfoService";
import { formatPrice } from "../../../../utilities/formatPrice";
import { CartItem } from "./CartItem";
import SectionProducts from "../../../Shop/components/SectionProducts/SectionProducts";
import useShopSections from "../../../Shop/hooks/useShopSections";
import { useHistory } from "react-router-dom";
import IntegrationService from "../../../../service/IntegrationService";

const CartItems = ({
  walletBalance,
  order,
  setOrder,
  isLoadingOrder,
  currencyProduct,
  currencyCodeUser,
  currencyUser,
  setCashback,
  setPage,
  setPrice,
  orderItems,
  setOrderItems,
  walletExtracoinBalance,
  userPercentExtracoin,
  walletExtracoinBalanceForex,
  setOrderIsEmpty,
  orderIsEmpty,
  cashback,
  isLoadingWalletExtracoinBalanceForex,
}) => {
  const { showErrorToast } = useToastContext();
  const [loading, setLoading] = useState(false);
  const [isLoadingToContinue, setIsLoadingToContinue] = useState(false);

  const getUserInfoService = new GetUserInfoService();
  const userInfoService = new GetUserInfoService();
  const integrationService = new IntegrationService();

  const toast = useRef(null);

  const { shopSections, isLoading } = useShopSections({
    onError: (code) => showErrorToast(code, toast?.current),
  });

  const history = useHistory();

  async function sendInitiateCheckout() {
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "InitiateCheckout",
    });
    const user = await getUserInfoService.getUserInfo(
      localStorage.getItem("__extraconomy_token"),
      localStorage.getItem("__extraconomy_username")
    );
    let ip = await integrationService.getUserIp();
    let date = new Date();
    let payload = {
      eventData: {
        currency: "",
        value: 0,
      },
      eventName: "InitiateCheckout",
      eventTime: Math.floor(date.getTime() / 1000),
      userData: {
        email: user.result.user?.email ? user.result.user.email : "",
        ipAddress: ip.data.ip,
        phone: user.result.user?.phoneNumber ? user.result.user.phoneNumber : "",
        userAgent: window.navigator.userAgent,
      },
    };
    let res = await integrationService.sendEvent(payload);
    return res;
  }

  useEffect(() => {
    if (orderItems) {
      setLoading(true);
      let cashbackValue = 0;

      orderItems.forEach((item) => {
        let price;

        if (item?.product.promotionalPriceUnique && !item?.product.productVariation) {
          price = item?.product.promotionalPriceUnique;
        } else {
          price = item?.priceUnitary;
        }

        const cashbackPercent = item?.product?.percentExtraCoin / 100;
        cashbackValue = cashbackValue + price * cashbackPercent * item.quantity;
      });

      setCashback(cashbackValue);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItems]);

  const isLoadingAll = !isLoadingWalletExtracoinBalanceForex && !!userPercentExtracoin;

  return (
    <>
      {!isLoadingAll && !orderIsEmpty ? (
        <div>
          <div className="flex md:align-items-center align-items-start md:flex-row w-full justify-content-start flex-column gap-2">
            <Link to="/shop">
              <button className="cursor-pointer py-2 px-4 flex w-full gap-2 align-items-center border-none border-round bg-green-500 hover:bg-green-600 text-white">
                <i className="pi pi-arrow-left"></i>
                <p className="font-semibold">{localeText("shop")}</p>
              </button>
            </Link>
            <h2 className="m-0">{localeText("shoppingCart")}</h2>
          </div>
          <hr />
          <Loading classNameIcon={"text-4xl"} />
        </div>
      ) : (
        <>
          <div className="grid">
            <div className="col-12 lg:col-9">
              <div className="flex md:align-items-center align-items-start md:flex-row w-full justify-content-start flex-column gap-2">
                <Link to="/shop">
                  <button className="cursor-pointer py-2 px-4 flex w-full gap-2 align-items-center border-none border-round bg-green-500 hover:bg-green-600 text-white">
                    <i className="pi pi-arrow-left"></i>
                    <p className="font-semibold">{localeText("shop")}</p>
                  </button>
                </Link>
                <h2 className="m-0">{localeText("shoppingCart")}</h2>
              </div>
              <hr />
              {orderItems?.map((product, idx) => (
                <CartItem
                  product={product}
                  setLoading={setLoading}
                  setOrder={setOrder}
                  setOrderItems={setOrderItems}
                  setOrderIsEmpty={setOrderIsEmpty}
                  key={idx}
                />
              ))}
            </div>
            {order && (
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <div>
                  <h2>{localeText("balance")}</h2>
                  <hr />
                </div>
                <div className="flex flex-column gap-2">
                  <div className="flex justify-content-between">
                    <span>{localeText("balanceAvailable")}</span>
                    <span className="font-bold text-right">
                      {currencyUser}{" "}
                      {formatPrice(
                        parseFloat(walletExtracoinBalance * (userPercentExtracoin / 100))
                      )}
                      {currencyCodeUser !== order.itens[0].product.currency && (
                        <>
                          {" "}
                          (≈ {currencyProduct}{" "}
                          {formatPrice(
                            parseFloat(walletExtracoinBalanceForex * (userPercentExtracoin / 100))
                          )}
                          )
                        </>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-content-between">
                    <span>{localeText("walletBalance")}</span>
                    <span>
                      <b>
                        {currencyUser} {formatPrice(walletBalance)}
                      </b>
                    </span>
                  </div>
                </div>
                <hr />
                <div className="flex justify-content-between align-items-baseline">
                  <h2 className="my-0">{localeText("orderResume")}</h2>
                  <span className="text-gray-500">
                    {orderItems.length} {orderItems.length > 1 ? "items" : "item"}
                  </span>
                </div>
                <hr />
                <div className="flex flex-column gap-2">
                  <div className="flex justify-content-between">
                    <span>{localeText("subtotal")}</span>
                    <span>
                      <b>
                        {currencyProduct} {formatPrice(order.amountOrder)}
                      </b>
                    </span>
                  </div>
                  <div className="flex justify-content-between">
                    <div className="">
                      <span>{localeText("platformFee")}</span>
                      <Tooltip target=".extracoin-tooltip" position="bottom" />
                      <i
                        className="pi pi-info-circle ml-1 extracoin-tooltip cursor-pointer text-600 align-self-start gap-0"
                        data-pr-tooltip={localeText("plataformFeeExplanation")}
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center-2"
                        tooltipoptions={{ event: "focus" }}
                      />
                    </div>
                    <span>
                      <b>
                        {currencyProduct} {formatPrice(order.amountToPlatform)}
                      </b>
                    </span>
                  </div>
                  <div className="flex justify-content-between mt-2">
                    <span>{localeText("total")}</span>
                    <span>
                      <b>
                        {currencyProduct} {formatPrice(order.amountTotal)}
                      </b>
                    </span>
                  </div>
                </div>
                <hr />

                <div className="flex justify-content-between text-green-500 mb-2">
                  <span>Cashback extracredit</span>
                  <span>
                    <b>
                      {currencyProduct} {formatPrice(cashback)}
                    </b>
                  </span>
                </div>
                <Button
                  label={localeText("next")}
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={async () => {
                    setLoading(true);
                    setIsLoadingToContinue(true);

                    const userInfo = await getUserInfoService.getUserInfo(
                      localStorage.getItem("__extraconomy_token"),
                      localStorage.getItem("__extraconomy_username")
                    );

                    if (userInfo.messageCode === 200001) {
                      const user = userInfo.result.user;
                      if (!user?.phoneNumber || !user?.internationalCode) {
                        history.push("/editprofile");
                        showErrorToast({ detail: "errorDontHavePhoneNumber" });
                        setLoading(false);
                        setIsLoadingToContinue(false);
                        return;
                      }
                    } else {
                      showErrorToast({ code: userInfo.messageCode });
                      setLoading(false);
                      setIsLoadingToContinue(false);
                      return;
                    }

                    // If user doesn't have enough valid Extracredit credits (user percentage applied) and it's not their first 3 purchases as a Customer or Promoter plan
                    const resOrderCount = await userInfoService.getUserOrderCount(
                      localStorage.getItem("__extraconomy_token"),
                      localStorage.getItem("__extraconomy_username")
                    );

                    if (resOrderCount.messageCode !== 200001) {
                      showErrorToast({ code: 500000 });
                      setLoading(false);
                      setIsLoadingToContinue(false);
                      return;
                    }

                    if (
                      order.itens.find(
                        (product) =>
                          product.product.amountInStock === 0 && !product.product.downloadable
                      )
                    ) {
                      showErrorToast({ code: 500074 });
                      setLoading(false);
                      setIsLoadingToContinue(false);
                      return false;
                    }
                    sendInitiateCheckout();
                    setPage(2);
                    setPrice(order.amountTotal);
                  }}
                  loading={isLoadingToContinue || loading}
                  disabled={
                    !localStorage.getItem("__extraconomy_order") || isLoadingToContinue || loading
                  }
                />
              </div>
            )}
          </div>
          {!order && (
            <div className="flex flex-column gap-4">
              <h4 className="m-0">{localeText("emptyCartText")}</h4>
              <span>{localeText("emptyCartSuggestion")}</span>
              <Link to="/shop">
                <button className="cursor-pointer py-2 px-4 border-none border-round bg-green-500 hover:bg-green-600 text-white">
                  {localeText("checkCatalog")}
                </button>
              </Link>
            </div>
          )}

          {isLoading ? (
            <div className="text-center my-8">
              <i className="pi pi-spin pi-spinner text-8xl text-400"></i>
            </div>
          ) : (
            <SectionProducts section={shopSections[0]} />
          )}
        </>
      )}
    </>
  );
};

export default CartItems;
