const editProfileInfoDictES = {
  choose: "Elegir",
  cancel: "Cancelar",
  dragImgFile: "Arrastre el archivo de imagen aquí",
  zipCode: "Código Postal",
  country: "País",
  state: "Estado",
  city: "Ciudad",
  publicPlace: "Calle",
  district: "Barrio",
  number: "Número",
  countryPhoneCode: "Cód. de Tel. del País",
  regionalPhoneCode: "Cód. de Tel. Regional",
  phoneNumber: "Número de Tel.",
  changeProfilePic: "Cambiar Foto de Perfil",
  confirmProfileChanges: "Confirmar cambios en el Perfil",
  // toast
  pleaseChoosePfp: "Por favor elija una foto de perfil primero.",
  fileSizeTooBig: "El tamaño del archivo es muy grande (excede 2 MB).",
  unexpectedErrorUploadImg: "Error inesperado al cargar la imagen.",
  usernameCantBeEmpty:
    "El nombre de usuario debe contener únicamente letras minúsculas y números. No puede estar vacío ni contener espacios.",
  usernameNotAvailable: "Nombre de usuario no disponible.",
  unexpectedErrorChangingPfp: "Error inesperado al cambiar el perfil.",
  sucessfullyEditedProfile: "Perfil editado con éxito.",
  unexpectedSystemBehaviour: "Comportamiento inesperado del sistema.",
  canRetriveOnSaturdays: "¿El servicio de envío puede recoger el pedido los sábados?",
  quantityOfDaysToRetrive:
    "Cantidad de días para que el servicio de envío recoja su pedido y lo lleve a su destino",
};

export default editProfileInfoDictES;
