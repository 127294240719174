import React, { useContext, useEffect } from "react";
import { useReducer } from "react";
import { createContext } from "react";
import { ACTIONS_TYPES } from "./actionsTypes";
import useGetFavorites from "../../hooks/useGetFavorites";
import showErrorToast from "../../utilities/Toast/showErrorToast";
import { setFavoritesCount } from "./actions";

export const FavoritesContext = createContext(null);
export const FavoritesDispatchContext = createContext(null);

const initialState = {
  favoritesCount: null,
};

const favoritesReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_FAVORITES_COUNT: {
      return {
        ...state,
        favoritesCount: action.payload,
      };
    }
    case ACTIONS_TYPES.ADD_FAVORITE: {
      return {
        ...state,
        favoritesCount: (state.favoritesCount ?? 0) + 1,
      };
    }
    case ACTIONS_TYPES.REMOVE_FAVORITE: {
      return {
        ...state,
        favoritesCount: (state.favoritesCount ?? 0) - 1,
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

const FavoritesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(favoritesReducer, initialState);
  const { favorites } = useGetFavorites({ onError: showErrorToast });

  useEffect(() => {
    if (favorites) dispatch(setFavoritesCount(favorites.length));
  }, [favorites]);

  return (
    <FavoritesContext.Provider value={state}>
      <FavoritesDispatchContext.Provider value={dispatch}>
        {children}
      </FavoritesDispatchContext.Provider>
    </FavoritesContext.Provider>
  );
};

export const useFavoritesContext = () => {
  return useContext(FavoritesContext);
};

export const useFavoritesDispatch = () => {
  return useContext(FavoritesDispatchContext);
};

export default FavoritesProvider;
