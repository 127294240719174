import { Button } from "primereact/button";
import React from "react";
import Loading from "../../../../components/Loading/Loading";
import ReviewsModal from "../../../../components/ReviewsModal/ReviewsModal";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";
import useGetCommentsOfProduct from "../../../../hooks/useGetCommentsOfProduct";
import { useToggle } from "../../../../hooks/useToggle";
import localeText from "../../../../locale";
import Comment from "../Comment/Comment";

const ProductRatingComments = ({ productInfoID }) => {
  const { showErrorToast } = useToastContext();
  const { value, setAsFalse, setAsTrue } = useToggle();
  const { commentsOfProduct, isLoading } = useGetCommentsOfProduct({
    productId: productInfoID,
    onError: (code) => showErrorToast({ code }),
  });

  if (isLoading) return <Loading />;
  const firstFiveComments = commentsOfProduct.slice(0, 5);
  return (
    <>
      <ReviewsModal
        headerText={`${localeText("productReviews")} (${commentsOfProduct.length})`}
        setAsFalse={setAsFalse}
        visible={value}
        comments={commentsOfProduct}
      />
      <div
        id="productReviews"
        className={`border-solid border-round lg:p-6 p-4 border-1 border-gray-300 overflow-hidden bg-white`}
      >
        <h1 className="font-bold mb-0 block lg:text-3xl md:text-xl text-lg text-primary">
          {localeText("productReviews")} ({commentsOfProduct.length})
        </h1>
        {firstFiveComments && firstFiveComments.length !== 0 && (
          <>
            <hr />
            <div className="flex flex-column gap-5">
              {firstFiveComments.map((comment, i) => (
                <Comment
                  key={i}
                  comments={comment.comments}
                  dateorder={comment.dateorder}
                  rate={comment.rateproduct}
                  username={comment.username}
                />
              ))}
            </div>
            {commentsOfProduct.length > 5 && (
              <Button className="lg:mt-5 mt-3" onClick={setAsTrue}>
                {localeText("showMore")}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ProductRatingComments;
