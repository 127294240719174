import React from "react";
import "./ProductViews.css";

const ProductViews = ({ views, className }) => {
  const baseClass =
    "flex flex-row gap-1 align-items-center justify-content-center z-5 text-white bg-gray-600 text-bold productViews-bg px-2 py-1 border-round-3xl";
  return (
    <span
      className={
        className
          ? `${className} ${baseClass}`
          : `${baseClass} productViews md:text-lg text-xs absolute`
      }
    >
      {views ? views : 0}
      <i className="pi pi-eye"></i>
    </span>
  );
};

export default ProductViews;
