const payPalCheckoutButtonDictPT = {
  unexpectedErrorTransaction: "Erro inesperado ao registrar.",
  invalidUserPlan: "Plano de usuário inválido.",
  emailConfirmationTitle: "Registrado com sucesso!",
  emailConfirmationTransfer: "Sua transferência foi registrada com sucesso!",
  emailConfirmationTransferMessage:
    "Seu pagamento será validado em breve. Você receberá um email de confirmação assim que o pagamento for validado.",
  emailConfirmationMessage: "Cheque seu email para ativar sua conta.",
  upgradedSuccessfully: "Plano da conta atualizado com sucesso!",
  upgradedSuccessfullyText:
    "Você precisará inserir suas credenciais de login novamente. Você será redirecionado para a tela de login em 20 segundos, ou pode pressionar o botão abaixo.",
  youWillBeRedirectedIn: "Você será redirecionado em",
  seconds: "segundos.",
  noStoresFound: "Nenhuma loja encontrada.",
};

export default payPalCheckoutButtonDictPT;
