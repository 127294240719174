import constructionImg from '../images/underconstruction/underconstruction.jpg';
import '../RegisterUser/RegisterUser.css'
import logoWhite from '../images/logo-white-horizontal.png';
import localeText from '../../locale';
import { useHistory, Link } from 'react-router-dom';
import { Button } from 'primereact/button';
const UnderConstruction = () => {
  
  const history = useHistory();

  return (
    <div className='bg-cover bg-center h-screen fixed w-screen' style={{ background: `url(${constructionImg})` }}>
      <div className='h-screen w-full flex align-items-center'>
        <div style={{ width: '92%', margin: 'auto' }}>
          <div className='w-full lg:w-6 md:w-8 h-30rem bg-black-alpha-50 mx-auto text-0 flex align-items-center border-round'>
            <ul className='mx-auto list-none text-center p-0'>
              <li>
                <h1 className='lg:text-7xl text-6xl mb-0 font-light underline'>{localeText('comingSoon')}</h1>
              </li>
              <li>
                <Link to='/'>
                  <img className='h-4rem mt-6' src={logoWhite} alt="Logo extraconomy" />
                </Link>
              </li>
              <li>
                <Button className='button-blank-nohover p-button-rounded p-button-warning mt-4'
                  onClick={() => history.goBack()} icon='pi pi-arrow-left'>
                </Button> 
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='credits-specialcustomer'>
        {localeText('photoBy')} <a href="https://unsplash.com/@ralphkayden?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ralph (Ravi) Kayden</a> on <a href="https://unsplash.com/s/photos/under-construction?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </div>
    </div>
  )
}

export default UnderConstruction