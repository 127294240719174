const ESDict = {
  "pt": {
    "startsWith": "Comienza con",
    "contains": "Hay",
    "notContains": "No hay",
    "endsWith": "Termina con",
    "equals": "Igual",
    "notEquals": "Diferente",
    "noFilter": "Sin filtro",
    "lt": "Menor que",
    "lte": "Menor o igual a",
    "gt": "Mayor que",
    "gte": "Mayor o igual a",
    "dateIs": "La fecha es",
    "dateIsNot": "La fecha no es",
    "dateBefore": "La fecha es anterior",
    "dateAfter": "La fecha es posterior",
    "custom": "Personalizado",
    "clear": "Limpiar",
    "apply": "Aplicar",
    "matchAll": "Match All",
    "matchAny": "Match Any",
    "addRule": "Agregar Regla",
    "removeRule": "Eliminar Regla",
    "accept": "Si",
    "reject": "No",
    "choose": "Escolha",
    "upload": "Upload",
    "cancel": "Cancelar",
    "dayNames": ["domingo", "lunes", "martes", "miercules", "jueves", "viernes", "sábado"],
    "dayNamesShort": ["dom", "Lun", "Mar", "Mie", "Jue", "Vie", "sáb"],
    "dayNamesMin": ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    "monthNamesShort": ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    "today": "Hoy",
    "weekHeader": "Sin",
    "firstDayOfWeek": 0,
    "dateFormat": "dd/mm/yy",
    "weak": "Débil",
    "medium": "Mediana",
    "strong": "Fuerte",
    "passwordPrompt": "Introduzca una contraseña",
    "emptyFilterMessage": "Sin opciones disponíbles",
    "emptyMessage": "Sin resultados",
    "aria": {
      "trueLabel": "True",
      "falseLabel": "False",
      "nullLabel": "No seleccionado",
      "pageLabel": "Página",
      "firstPageLabel": "Primera Página",
      "lastPageLabel": "Ultima Página",
      "nextPageLabel": "Próxima",
      "previousPageLabel": "Anterior"
    }
  }
}

export default ESDict