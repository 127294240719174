import axios from "axios";
import apiURI from "./Api";

class VoucherService {
  async getTypeOfVoucher(value) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/voucher/get/${value}`,
    });

    return response.data;
  }
}

const voucherService = new VoucherService();

export default voucherService;
