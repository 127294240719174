const couponsDictEN = {
  specialCustomerRegisterCoupons: "Credit Coupons",
  generateCoupon: "Generate a new coupon",
  extracoinAmount: "Amount of Extracredit credit",
  unexpectedErrorWhileGeneratingCoupon: "Unexpected error while generating credit coupon.",
  currency: "Currency",
  used: "Used",
  notUsed: "Not used",
  couponGenerated: "You generated a new credit coupon successfully!",
  couponCanceled: "You canceled the operation to generate a new credit coupon",
  areYouSureCoupon:
    "Are you sure you want to generate a new credit coupon? The amount will be debited from your Extracredit balance.",
  confirmYourAction: "Confirm your action",
  confirm: "Confirm",
  usedBy: "Used by",
  generatedCoupon: "Generated coupon",
};

export default couponsDictEN;
