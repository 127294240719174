import localeText from "../../../../locale";
import { PLATFORM_PRODUCT_FEE } from "../../../../utilities/constants";
import { formatPrice } from "../../../../utilities/formatPrice";

const PromotionalPrice = ({ productInfo, currency }) => {
  const { product, productVariations } = productInfo?.data;
  const { promotionalPriceUnique } = product;

  const hasNotPromotionalPrice = !promotionalPriceUnique && promotionalPriceUnique <= 0;
  const hasNotVariation = productVariations.length !== 0;

  if (hasNotPromotionalPrice || hasNotVariation) return null;

  const promotionalPrice = promotionalPriceUnique * PLATFORM_PRODUCT_FEE + promotionalPriceUnique;

  return (
    <h4 className="flex align-items-center font-semibold my-0 lg:text-3xl text-xl text-primary">
      {localeText("promocionalPrice")}{" "}
      <span className="flex align-items-center font-semibold pl-1">
        {" "}
        {currency} {formatPrice(promotionalPrice)}
      </span>
    </h4>
  );
};

export default PromotionalPrice;
