import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import localeText from "../../../../locale";
import { useToggle } from "../../../../hooks/useToggle";

const ComparePriceButton = ({ title, priceUnique, promotionalPriceUnique }) => {
  const { value: visible, setAsTrue, setAsFalse } = useToggle();

  let searchPrice = promotionalPriceUnique ? promotionalPriceUnique : priceUnique;

  return (
    <>
      <Button
        label={localeText("comparePrices")}
        severity="help"
        className="uppercase font-bold md:text-sm text-xs py-3 md:px-6 px-4 w-max my-3 flex justify-content-center"
        icon="pi pi-search"
        outlined
        onClick={setAsTrue}
      />
      <Dialog
        className="dialog-compare-prices m-2 border-rounded"
        header={localeText("comparePrices")}
        visible={visible}
        onHide={setAsFalse}
      >
        <iframe
          id="inlineFrameExample"
          title="google iframe"
          width="100%"
          height="100%"
          src={`https://www.google.com/search?igu=1&hl=en&tbm=shop&psb=1&q=${title}&tbs=mr:1,price:1,ppr_min:${
            searchPrice / 2
          },ppr_max:${
            searchPrice * 2
          }&sa=X&ved=0ahUKEwjryIvr5oT_AhWgqpUCHSAZCjYQvSsIrAkoAQ&biw=1339&bih=965&dpr=1client=mobile-sh-serp`}
        ></iframe>
      </Dialog>
    </>
  );
};

export default ComparePriceButton;
