const attributesDictIT = {
  edit: 'Modificare',
  attributeSearch: 'Attributo di ricerca',
  addNewAttribute: 'Aggiungi un nuovo attributo',
  attributeName: 'Nome attributo',
  add: 'Aggiungere',
  attributeCreated: 'Attributo creato con successo!',
  pleaseFillOutField: 'Si prega di compilare il campo.',
  goBackToAttributeList: "Torna all'elenco degli attributi",
  attributeValues: 'Valori di attributo',
  attribute: 'Attributo',
  value: 'Valore',
  listOfValues: 'Valori di',
  addNewValues: 'Aggiungere nuovi valori',
  valuesCreated: 'Valori creati e aggiunti per attribuire con successo!',
  // Assign Attributes
  assign: 'Assegnare', 
  assignAttributesToProduct: 'Associa attributi al prodotto',
  pressARowToSelect: 'Premere una riga per selezionare un attributo.',
  selectAtLeastOneAttribute: 'Seleziona almeno un attributo.',
  canOnlyHaveUpTo2Attributes: 'Puoi selezionare solo fino a due attributi.',
  attributeAssignedSuccessfully: 'Attributo(i) assegnato(i) con successo!',
  confirmAssignAttributes: "Stai per assegnare attributi a questo prodotto e questo lo trasformerà in un prodotto variabile. Vuoi procedere con questa azione?",
  attributeEnterExplanation: 'Premi i tasti Enter o "," per aggiungere un valore (puoi aggiungere più valori)',
}

export default attributesDictIT;