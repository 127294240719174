const productsListDictPT = {
  productImage: "Imagem Principal",
  title: "Título",
  priceUnique: "Preço",
  productState: "Estado",
  productStatus: "Situação",
  published: "Publicado",
  unpublished: "Não publicado",
  planNotAllowed: "Sua assinatura atual não tem acesso a este recurso.",
  upgradeToBusinessPartner: "Atualize para Partner",
  upgradeToAreaManager: "Atualize para Manager",
  assignAttributes: "Associar Atributos",
  goBackProductList: "Voltar para a lista de produtos",
  noVariations:
    "Este produto não tem variações. Deseja gerar variações automaticamente para este produto?",
  generateVariations: "Gerar Variações",
  manageVariations: "Gerenciar variações do produto",
  manageVariationsShort: "Gerenciar Variações",
  image: "Imagem",
  stock: "Estoque",
  delete: "Remover",
  pleaseChooseImage: "Por favor escolha uma imagem.",
  theValuesOfTheseAttributes: "Os valores desses atributos serão usados para gerar variações:",
  generateVariationsWithThese: "Gerar variações com esses atributos?",
  goBackToVariationsList: "Voltar para a lista de variações",
  confirmChanges: "Confirmar Mudanças",
  pressThePencilIconTo: "Pressione o botão do lápis para editar a imagem da variação",
  variationUpdatedSuccessfully: "Variação do produto atualizada com sucesso!",
  variationMustHaveAValidPrice: "A variação deve ter um preço válido.",
  variationDeletedSuccessfully: "Variação removida com sucesso.",
  deleteThisVariation: "Tem certeza de que deseja excluir esta variação?",
  preview: "Prévia",
  selectTwoAttributes: "Selecione",
  and: "e",
  selectOneAttribute: "Selecione",
  unavailable: "Indisponível",
  youMustFirstGenerateVariations:
    "Este é um produto variável. Você deve primeiro gerar variações para publicá-lo.",
  clickHereToGenerateVariations: "Clique aqui para gerar variações para este produto",
  publish: "Publicar",
  productPublishedSuccessfully: "Produto publicado com sucesso!",
  productUnpublishedSuccessfully: "Produto despublicado da sua loja com sucesso.",
  seller: "Vendedor",
  itemAlreadyInCart: "Item já está no carrinho.",
  unpublish: "Despublicar",
  visibility: "Visibilidade",
  downloadableProductExplanation:
    "Este é um produto para download. Você pode verificar uma versão de amostra antes de comprar para ter acesso ao link de download real.",
  searchByTitle: "Buscar por título",
  searchBySeller: "Buscar por vendedor",
  searchByBuyer: "Buscar por comprador",
  imported: "Importado",
  importOrigin: "Origem",
  archived: "Arquivar",
  archivedProduct: "Arquivado",
};

export default productsListDictPT;
