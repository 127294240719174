const extracoinTransferDictIT = {
  extracoinTransfer: 'Trasferimento di Extracredit',
  amount: 'Quantità',
  usernameNotFound: 'Utente non trovato.',
  receivingUsername: 'Utente destinatario',
  amountCantBeZero: "L'importo non può essere zero.",
  attention: 'Attenzione',
  confirmTransactionExplanation: 'Confermando questa transazione, accetti di pagare una commissione di transazione di',
  doYouAgree: "Sei d'accordo?",
  transactionConfirmed: 'Transazione completata con successo! Attendi la conferma del destinatario.',
  transactionReceived: 'Transazione ricevuta con successo!',
  sender: 'Mittente',
  accepted: 'Accettata',
  toAgreeAndReceiveInsertCode: 'Per accettare e ricevere la transazione, inserisci il codice che ti è stato inviato al tuo indirizzo email qui sotto.',
  seeStatement: 'Ver estratto',
  showingReceivedRequests: 'Richieste ricevute',
  showingSentRequests: 'Richieste inviate',
  acceptTransaction: 'Accettare',
  addressee: 'Destinatario',
  financialPasswordCantBeEmpty: 'La password finanziaria non può essere vuota.',
  codeCantBeEmpty: 'Il codice non può essere vuoto.',
}

export default extracoinTransferDictIT;
