import { useEffect, useState } from "react";
import ProductService from "../service/ProductService";

const useRatingProduct = ({ productId, onError }) => {
  const [ratingProduct, setRatingProduct] = useState(null);
  const [loadingRatingProduct, setLoadingRatingProduct] = useState(true);

  const productService = new ProductService();

  const loadRatingProduct = async () => {
    try {
      if (productId) {
        setLoadingRatingProduct(true);
        const resRating = await productService.getProductRating(productId);

        if (resRating.messageCode !== 200001) {
          onError(resRating.messageCode);
          if (resRating.messageCode === 500000) console.log(resRating);
        } else {
          setRatingProduct(resRating.result);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingRatingProduct(false);
    }
  };

  useEffect(() => {
    if (productId) loadRatingProduct(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return {
    ratingProduct,
    loadingRatingProduct,
  };
};

export default useRatingProduct;
