import { Rating } from "primereact/rating";
import React from "react";
import { formatDate } from "../../../../utilities/formatDate";

const Comment = ({ comments, dateorder, rate, username }) => {
  return (
    <div>
      <p className="mb-0">
        {username} - {formatDate(dateorder)}
      </p>
      <div className="flex mb-1 align-items-center justify-content-start">
        <Rating value={Math.ceil(rate)} readOnly stars={5} cancel={false} />
        {rate > 0 && <label className="ml-1 text-600">{parseFloat(rate.toFixed(2))}</label>}
      </div>
      {comments && <p>{comments}</p>}
    </div>
  );
};

export default Comment;
