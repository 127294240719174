import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import localeText from "../../../../locale";
import { formatPrice } from "../../../../utilities/formatPrice";
import Shipping from "../Shipping/Shipping";
import { formatAddress } from "../ThankYouPage/ThankYouPage";
import useCheckout from "./hooks/useCheckout";
import "./Checkout.css";
import { useEffect, useRef, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { FileUpload } from "primereact/fileupload";
import OrderService from "../../../../service/OrderService";
import { Toast } from "primereact/toast";

const Checkout = ({
  order,
  orderItems,
  setOrder,
  currencyProduct,
  currencyCodeUser,
  currencyUser,
  setPage,
  setAmountTransfer,
  currencyExchangeValue,
  setPrice,
  amountWallet,
  walletBalance,
  walletBalanceForex,
  price,
  amountBankTransfer,
  setAmountBankTransfer,
  bankTransferReceipt,
  setBankTransferReceipt,
  amountToPayWithExtracredit,
  setAmountToPayWithExtracredit,
  currencyCode,
  setAmountWallet,
  walletExtracoinBalance,
  userInfo,
  userPercentExtracoin,
  setAddressInfo,
  setPriceForex,
  setDisplayPaymentModal,
  sendMethod,
  setSendMethod,
  walletExtracoinBalanceForex,
  setAmountShipmentForexProduct,
  amountShipmentForexProduct,
  setLoading,
}) => {
  const {
    validateCheckout,
    loading,
    amountShipmentForexUser,
    postalCode,
    shippingQuotes,
    isFreeFreight,
    setNumber,
    number,
    setAddressValues,
    setDistrict,
    resetOrderWhenChangeAddress,
    setAddress,
    showMessageSellerResponsibility,
    country,
    checkZipCode,
    state,
    city,
    district,
    address,
    isLoadingFreight,
    setCity,
    setPostalCode,
    setState,
    toggleIsPickup,
    isPickup,
  } = useCheckout({
    order,
    setOrder,
    currencyUser,
    setPrice,
    amountWallet,
    setAmountWallet,
    amountBankTransfer,
    setAmountBankTransfer,
    bankTransferReceipt,
    setBankTransferReceipt,
    amountToPayWithExtracredit,
    setAmountToPayWithExtracredit,
    walletBalance,
    price,
    currencyCode,
    userInfo,
    setAddressInfo,
    setPriceForex,
    setDisplayPaymentModal,
    sendMethod,
    setSendMethod,
    setAmountShipmentForexProduct,
    setPage,
    setAmountTransfer,
    currencyProduct,
  });
  const orderService = new OrderService();

  const [currentSection, setCurrentSection] = useState("address");
  const [autoCurrentSection, setAutoCurrentSection] = useState(false);

  const toast = useRef(null);
  const fileUploadRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCompleteForm = () => {
    if (
      address &&
      city &&
      country &&
      district &&
      number &&
      postalCode &&
      state &&
      !autoCurrentSection
    ) {
      setCurrentSection("shipping");
      setAutoCurrentSection(true);
    }
  };

  const uploadFile = async (event) => {
    if (event.files[0].size / 1024 / 1024 > 5) {
      fileUploadRef.current.clear();
      toast.current.show({
        severity: "error",
        summary: localeText("error"),
        detail: localeText("fileSizeTooBig"),
        life: 6000,
      });
      /* setLoading(false); */
      return false;
    }
    try {
      const res = await orderService.setBankTransferReceipt(event.files[0]);
      setBankTransferReceipt(res.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    autoCurrentSection && setAddressValues(); // eslint-disable-next-line
  }, [autoCurrentSection]);

  const maxInputAmountWallet =
    (sendMethod?.currency !== currencyProduct ? amountShipmentForexProduct : order.amountShipment) +
    order.amountTotal -
    amountToPayWithExtracredit;

  const walletBalanceValue =
    currencyCodeUser !== order.itens[0].product.currency ? walletBalanceForex : walletBalance;

  const maxInputAmountExtracredit =
    (sendMethod?.currency !== currencyProduct ? amountShipmentForexProduct : order.amountShipment) +
    order.amountTotal -
    amountWallet;

  const maxInputAmountBankTransfer =
    (sendMethod?.currency !== currencyProduct ? amountShipmentForexProduct : order.amountShipment) +
    order.amountTotal -
    amountWallet -
    amountToPayWithExtracredit;
  let extracoinInWallet;

  if (walletExtracoinBalanceForex) {
    extracoinInWallet = walletExtracoinBalanceForex * (userPercentExtracoin / 100);
  } else {
    extracoinInWallet = walletExtracoinBalance * (userPercentExtracoin / 100);
  }

  const anyProductIsPickup = order?.itens?.some(
    (product) => product?.product?.shippingOption === "PICKUP"
  );
  const totarPrice =
    currencyProduct +
    " " +
    formatPrice(
      Math.abs(
        (sendMethod?.currency !== currencyProduct
          ? amountShipmentForexProduct
          : order.amountShipment) +
          order.amountTotal -
          amountWallet -
          amountToPayWithExtracredit
      )
    );
  return (
    <>
      <Toast ref={toast} />
      <h3 className="my-4">{localeText("checkout")}</h3>
      <hr />
      <div className={`grid`}>
        {!order.itens[0].product.downloadable && !order.itens[0].product.service && (
          <div className="lg:col-9 col-12">
            <div className="dropdown-container">
              <div
                className="dropdown-header"
                onClick={() => {
                  setCurrentSection(currentSection === "address" ? "" : "address");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                >
                  <path
                    d="M21.3125 11.2678L11 3.26344L0.6875 11.2678V8.00625L11 0L21.3125 8.00438V11.2678ZM18.7344 10.9772V18.7125H13.5781V13.5562H8.42188V18.7125H3.26562V10.9781L11 5.17781L18.7344 10.9772Z"
                    fill="black"
                  />
                </svg>
                <span>{localeText("deliveryAddress")}</span>
              </div>
              <div className={`dropdown-content ${currentSection === "address" ? "is-open" : ""}`}>
                {!order.itens[0].product.downloadable && !order.itens[0].product.service && (
                  <div className="container">
                    {anyProductIsPickup && (
                      <div>
                        <h5>{localeText("pickUpAddress")}:</h5>
                        <p className="text-sm">{formatAddress(order.seller)}</p>

                        <div className="flex align-items-center">
                          <Checkbox
                            inputId="ingredient1"
                            name="pizza"
                            value="Cheese"
                            onChange={toggleIsPickup}
                            checked={isPickup}
                          />
                          <label htmlFor="ingredient1" className="ml-2">
                            {localeText("pickUpInStore")}
                          </label>
                        </div>
                      </div>
                    )}
                    {!isPickup && (
                      <>
                        {anyProductIsPickup && (
                          <div className="my-2">
                            <hr />
                            <p className="w-full text-center">{localeText("or")}</p>
                            <hr />
                          </div>
                        )}
                        <div className="row cep">
                          <div className="form-field">
                            {loading && <i className="pi pi-spin pi-spinner" />}
                            <label htmlFor="postalCode">{localeText("zipCode")}</label>
                            <input
                              type="text"
                              value={postalCode}
                              onChange={(e) => {
                                resetOrderWhenChangeAddress();
                                setPostalCode(e.target.value);
                              }}
                              onBlur={handleCompleteForm}
                              disabled={loading}
                            />
                          </div>
                          <button className="auto-fill" onClick={() => checkZipCode(postalCode)}>
                            {localeText("searchAddress")}
                          </button>
                        </div>
                        <div className="row">
                          <div className="form-field">
                            <label htmlFor="country">{localeText("country")}</label>
                            <input type="text" value={country} disabled={true} />
                          </div>
                          <div className="form-field">
                            <label htmlFor="state">{localeText("state")}</label>
                            <input
                              type="text"
                              value={state}
                              onChange={(e) => {
                                resetOrderWhenChangeAddress();
                                setState(e.target.value);
                              }}
                              disabled={loading}
                              onBlur={handleCompleteForm}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-field">
                            <label htmlFor="address">{localeText("city")}</label>
                            <input
                              type="text"
                              value={city}
                              onChange={(e) => {
                                resetOrderWhenChangeAddress();
                                setCity(e.target.value);
                              }}
                              onBlur={handleCompleteForm}
                              disabled={loading}
                            />
                          </div>
                          <div className="form-field">
                            <label htmlFor="address">{localeText("publicPlace")}</label>
                            <input
                              type="text"
                              value={address}
                              onChange={(e) => {
                                resetOrderWhenChangeAddress();
                                setAddress(e.target.value);
                              }}
                              onBlur={handleCompleteForm}
                              disabled={loading}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-field">
                            <label htmlFor="district">{localeText("district")}</label>
                            <input
                              type="text"
                              value={district}
                              onChange={(e) => {
                                resetOrderWhenChangeAddress();
                                setDistrict(e.target.value);
                              }}
                              onBlur={handleCompleteForm}
                              disabled={loading}
                            />
                          </div>
                          <div className="form-field">
                            <label htmlFor="number">{localeText("number")}</label>
                            <input
                              type="text"
                              value={number}
                              onChange={(e) => {
                                resetOrderWhenChangeAddress();
                                setNumber(e.target.value.replace(/[^0-9]/g, ""));
                              }}
                              onBlur={handleCompleteForm}
                              disabled={loading}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="dropdown-container">
              <div
                className={`dropdown-header ${isPickup ? "disabled" : ""}`}
                onClick={() => {
                  setCurrentSection(currentSection === "shipping" ? "" : "shipping");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M15.7733 21.7631C15.2846 21.2648 14.7144 20.8818 14.0916 20.6431L12.7861 15.172L28.3343 10.4911L30 17.4767L15.7733 21.7631ZM10.8235 6.94754L21.1894 3.82732L22.8561 10.8152L12.4913 13.9354L10.8235 6.94754ZM11.2887 20.5381C10.9993 20.6257 10.7303 20.7503 10.4688 20.8876L5.89839 1.72218H4.17914C4.00657 2.12706 3.64536 2.41197 3.21876 2.41197H1.07292C0.480189 2.41197 0 1.87098 0 1.20541C0 0.539838 0.480189 0 1.07292 0H3.21876C3.57891 0 3.88117 0.212244 4.07624 0.515615H7.37754V0.574443L7.42685 0.558294L12.1537 20.3789C11.8654 20.4077 11.577 20.4527 11.2887 20.5381ZM11.5663 21.7031C13.5696 21.0998 15.6286 22.4356 16.1656 24.6884C16.7026 26.9389 15.514 29.2528 13.5096 29.8561C11.5063 30.457 9.44728 29.1213 8.91028 26.8708C8.37329 24.6203 9.56304 22.3064 11.5663 21.7031ZM11.0015 26.3206C11.2308 27.2849 12.114 27.857 12.9726 27.5975C13.8311 27.338 14.3403 26.3471 14.1109 25.3828C13.8815 24.4185 12.9983 23.8463 12.1398 24.1036C11.2801 24.3631 10.771 25.3551 11.0015 26.3206ZM29.866 21.5762L17.3425 25.3459C17.32 25.024 17.2782 24.6988 17.201 24.3746C17.1249 24.0505 17.0145 23.746 16.8913 23.453L29.3494 19.7041L29.866 21.5762Z"
                    fill="black"
                  />
                </svg>
                <span>{localeText("shippingOptions")}</span>
              </div>
              <div className={`dropdown-content ${currentSection === "shipping" ? "is-open" : ""}`}>
                <div className="text-center">
                  <button
                    className="shipping-options"
                    onClick={() => setAddressValues()}
                    disabled={isLoadingFreight}
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_15_152)">
                        <rect width="24" height="24" fill="transparent" />
                        <circle cx="10.5" cy="10.5" r="6.5" stroke="#fff" strokeLinejoin="round" />
                        <path
                          d="M19.6464 20.3536C19.8417 20.5488 20.1583 20.5488 20.3536 20.3536C20.5488 20.1583 20.5488 19.8417 20.3536 19.6464L19.6464 20.3536ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    {isLoadingFreight
                      ? localeText("lookingTheBestShippingMethod")
                      : localeText("searchForShipping")}
                  </button>
                </div>
                {isFreeFreight && (
                  <h3 className="mb-0">{`${localeText("freight")}: ${localeText("free")}`}</h3>
                )}
                {showMessageSellerResponsibility && (
                  <h5 className="">*{localeText("shippingResponsibilitySeller")}</h5>
                )}
                {/* Shipping component */}
                {!order.itens[0].product.service &&
                  !order.itens[0].product.downloadable &&
                  shippingQuotes &&
                  !isPickup && (
                    <Shipping
                      shippingQuotes={shippingQuotes}
                      postalCode={postalCode}
                      sendMethod={sendMethod}
                      setSendMethod={setSendMethod}
                    />
                  )}
              </div>
            </div>
            <button className="return" onClick={() => setPage(1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
              >
                <path
                  d="M8.04754 12.1293L7.35387 12.7901C7.06015 13.07 6.5852 13.07 6.29461 12.7901L0.220287 7.00607C-0.0734301 6.72624 -0.0734301 6.27376 0.220287 5.99691L6.29461 0.209869C6.58833 -0.0699568 7.06327 -0.0699568 7.35387 0.209869L8.04754 0.870734C8.34438 1.15354 8.33813 1.61495 8.03504 1.8918L4.26984 5.30925L13.2501 5.30925C13.6657 5.30925 14 5.62778 14 6.0237V6.9763C14 7.37222 13.6657 7.69075 13.2501 7.69075L4.26984 7.69075L8.03504 11.1082C8.34126 11.385 8.34751 11.8465 8.04754 12.1293Z"
                  fill="white"
                />
              </svg>
              {localeText("previous")}
            </button>
          </div>
        )}

        <div
          className={
            !order.itens[0].product.downloadable && !order.itens[0].product.service
              ? `col-12 lg:col-3 flex flex-column gap-2`
              : "col-12"
          }
        >
          <div>
            <h4>{localeText("balance")}</h4>
            <hr />
          </div>
          <div className="flex flex-column gap-2">
            <div className="flex justify-content-between align-items-center">
              <span>{localeText("balanceAvailable")}</span>
              <span className="font-bold text-right">
                {currencyUser}{" "}
                {formatPrice(parseFloat(walletExtracoinBalance * (userPercentExtracoin / 100)))}
                {currencyCodeUser !== order.itens[0].product.currency && (
                  <>
                    {" "}
                    (≈ {currencyProduct}{" "}
                    {formatPrice(
                      parseFloat(walletExtracoinBalanceForex * (userPercentExtracoin / 100))
                    )}
                    )
                  </>
                )}
              </span>
            </div>
            <div className="flex justify-content-between align-items-center">
              <span>{localeText("walletBalance")}</span>
              <span className="text-right white-space-nowrap">
                <b>
                  {currencyUser} {formatPrice(walletBalance)}
                </b>
              </span>
            </div>
          </div>
          <hr />
          <div className="flex justify-content-between align-items-baseline">
            <h4 className="my-0">{localeText("orderResume")}</h4>
            <span className="text-gray-500">
              {orderItems.length} {orderItems.length > 1 ? "items" : "item"}
            </span>
          </div>
          <hr />
          <div className="flex flex-column gap-2">
            {!order.itens[0].product.service &&
              !order.itens[0].product.downloadable &&
              sendMethod &&
              shippingQuotes && (
                <div className="flex justify-content-between align-items-center gap-3">
                  <span>
                    {localeText("shippingCost")}
                    {showMessageSellerResponsibility && (
                      <p className="text-sm font-bold mb-1">
                        *{localeText("shippingResponsibilitySeller")}
                      </p>
                    )}
                  </span>
                  <span className="text-right">
                    <b>
                      {currencyProduct} {formatPrice(order.amountShipment)}
                      {amountShipmentForexUser > 0 && (
                        <span className="text-600 text-right">
                          {" "}
                          (≈ {currencyUser}{" "}
                          {formatPrice(order.amountShipment / currencyExchangeValue)})
                        </span>
                      )}
                    </b>
                  </span>
                </div>
              )}
            <div className="flex justify-content-between">
              <span>{localeText("orderTotal")}</span>
              <span className="text-right">
                <b>
                  {currencyProduct} {formatPrice(order.amountTotal)}
                </b>
              </span>
            </div>
          </div>
          <br />
          <div className="text-justify">
            <i className="sm:text-base text-sm text-green-500">
              {localeText("extraconomyExplain")}
            </i>
          </div>
          <br />
          <div className="flex flex-column gap-4">
            <div className="flex flex-column justify-content-between gap-2 w-full">
              <label className="flex align-items-center" htmlFor="wallet">
                {localeText("amountToPayWithWalletBalance")}
              </label>
              <div className={"flex align-items-center xl:flex-row gap-2 w-full"}>
                <span className="text-600">{currencyProduct}</span>
                <InputNumber
                  className={"input-amount"}
                  style={{ width: "100%" }}
                  value={amountWallet}
                  onChange={(e) => {
                    setLoading(true);
                    const maxValueInWallet =
                      maxInputAmountWallet <= walletBalanceValue
                        ? maxInputAmountWallet
                        : walletBalanceValue;

                    const shipmentePrice =
                      sendMethod?.currency !== currencyProduct
                        ? amountShipmentForexProduct
                        : order.amountShipment;

                    const priceWithShip =
                      shipmentePrice > 0 ? order.amountTotal + shipmentePrice : order.amountTotal;
                    const priceToPay = parseFloat(priceWithShip.toFixed(2));
                    const inputValue =
                      e.value && e.value <= maxValueInWallet
                        ? parseFloat(e.value)
                        : e.value
                        ? maxValueInWallet
                        : 0;
                    setOrder((prev) => ({ ...prev, amountWallet: inputValue }));
                    setAmountWallet(inputValue);
                    setPrice(
                      parseFloat(
                        Math.abs(priceToPay - inputValue - amountToPayWithExtracredit).toFixed(2)
                      )
                    );
                    if (!inputValue) {
                      setAmountWallet(0);
                    } else {
                      const amountWallet_ = parseFloat(parseFloat(inputValue).toFixed(2));
                      setAmountWallet(amountWallet_);
                    }
                    setLoading(false);
                  }}
                  max={
                    maxInputAmountWallet <= walletBalanceValue
                      ? maxInputAmountWallet
                      : walletBalanceValue
                  }
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>
            </div>
            <div className="flex flex-column justify-content-between gap-2 w-full">
              <label className="flex align-items-center" htmlFor="extracredit">
                {localeText("amountToPayWithExtracredit")}
              </label>
              <div className={"flex align-items-center xl:flex-row gap-2 w-full"}>
                <span className="text-600">{currencyProduct}</span>
                <InputNumber
                  className={"input-amount"}
                  style={{ width: "100%" }}
                  value={amountToPayWithExtracredit}
                  onChange={(e) => {
                    setLoading(true);
                    const maxValue =
                      maxInputAmountExtracredit < extracoinInWallet
                        ? maxInputAmountExtracredit
                        : extracoinInWallet;
                    const shipmentePrice =
                      sendMethod?.currency !== currencyProduct
                        ? amountShipmentForexProduct
                        : order.amountShipment;

                    const priceWithShip =
                      shipmentePrice > 0 ? order.amountTotal + shipmentePrice : order.amountTotal;
                    const priceToPay = parseFloat(priceWithShip.toFixed(2));
                    const inputValue =
                      e.value && e.value < maxValue ? parseFloat(e.value) : e.value ? maxValue : 0;
                    setOrder((prev) => ({ ...prev, amountWalletExtracoin: inputValue }));
                    setPrice(
                      parseFloat(Math.abs(priceToPay - inputValue - amountWallet).toFixed(2))
                    );
                    if (!inputValue) {
                      setAmountToPayWithExtracredit(0);
                    } else {
                      const amountExtracredit = parseFloat(parseFloat(inputValue).toFixed(2));
                      setAmountToPayWithExtracredit(amountExtracredit);
                    }
                    setLoading(false);
                  }}
                  max={
                    maxInputAmountExtracredit < extracoinInWallet
                      ? maxInputAmountExtracredit
                      : extracoinInWallet
                  }
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>
            </div>
            <div className="flex flex-column justify-content-between gap-2 w-full">
              <label className="flex align-items-center" htmlFor="extracredit">
                {localeText("payWithBankTransfer")}
              </label>
              <div className={"flex align-items-center xl:flex-row gap-2 w-full"}>
                <span className="text-600">{currencyProduct}</span>
                <InputNumber
                  className={"input-amount"}
                  style={{ width: "100%" }}
                  value={amountBankTransfer}
                  onChange={(e) => {
                    setLoading(true);
                    const maxValue = maxInputAmountBankTransfer;
                    const shipmentePrice =
                      sendMethod?.currency !== currencyProduct
                        ? amountShipmentForexProduct
                        : order.amountShipment;

                    const priceWithShip =
                      shipmentePrice > 0 ? order.amountTotal + shipmentePrice : order.amountTotal;
                    const priceToPay = parseFloat(priceWithShip.toFixed(2));
                    const inputValue =
                      e.value && e.value < maxValue ? parseFloat(e.value) : e.value ? maxValue : 0;
                    setOrder((prev) => ({ ...prev, amountBankTransfer: inputValue }));
                    setPrice(
                      parseFloat(
                        Math.abs(
                          priceToPay - inputValue - amountWallet - amountToPayWithExtracredit
                        ).toFixed(2)
                      )
                    );
                    if (!inputValue) {
                      setAmountBankTransfer(0);
                    } else {
                      const amountTransfer = parseFloat(inputValue).toFixed(2);
                      setAmountBankTransfer(amountTransfer);
                    }
                    setLoading(false);
                  }}
                  max={maxInputAmountBankTransfer}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>

              {amountBankTransfer ? (
                <div>
                  <div className="my-3">
                    <h5>
                      {localeText("youWillNeedToTransfer")} <b>{totarPrice}</b>{" "}
                      {localeText("toTheBankAccount")}{" "}
                      <strong>IBAN: IT23H0200805118000106629437</strong>{" "}
                      {localeText("andUploadTheReceipt")}
                    </h5>
                  </div>
                  <div>
                    <FileUpload
                      name="bankTransferReceipt"
                      auto
                      customUpload
                      uploadHandler={uploadFile}
                      ref={fileUploadRef}
                      accept="image/*"
                      maxFileSize={1000000}
                      emptyTemplate={
                        <p className="m-0 text-xl flex items-center gap-2">
                          <i className="pi pi-file-check" style={{ fontSize: "1.5rem" }}></i>{" "}
                          {localeText("sendYourTransferReceipt")}.
                        </p>
                      }
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <br />
          <div className="flex justify-content-between">
            <span className="text-2xl"> {localeText("total")}</span>
            <span className="text-2xl text-right">
              <b>
                {currencyProduct}{" "}
                {formatPrice(
                  Math.abs(
                    (sendMethod?.currency !== currencyProduct
                      ? amountShipmentForexProduct
                      : order.amountShipment) +
                      order.amountTotal -
                      amountWallet -
                      amountToPayWithExtracredit
                  )
                )}
              </b>
            </span>
          </div>
          <hr />
          {!order.itens[0].product.downloadable && !order.itens[0].product.service && (
            <div className="flex">
              {price > 0 ? (
                <Button
                  className="w-full"
                  label={localeText("proceedToPayment")}
                  onClick={() => validateCheckout(false)}
                  loading={loading}
                  disabled={amountBankTransfer && !bankTransferReceipt}
                />
              ) : (
                <Button
                  className="w-full"
                  label={localeText("finishOrder")}
                  onClick={() => validateCheckout(true)}
                  loading={loading}
                  disabled={amountBankTransfer && !bankTransferReceipt}
                />
              )}
            </div>
          )}
          {(order.itens[0].product.downloadable || order.itens[0].product.service) && (
            <div className="flex align-items-center gap-3">
              <Button className="sm:p-3" icon="pi pi-arrow-left" onClick={() => setPage(1)} />

              {price > 0 ? (
                <Button
                  className="w-full proceed-to-payment"
                  label={localeText("proceedToPayment")}
                  onClick={() => validateCheckout(false)}
                  loading={loading}
                  disabled={amountBankTransfer && !bankTransferReceipt}
                />
              ) : (
                <Button
                  className="w-full proceed-to-payment"
                  label={localeText("finishOrder")}
                  onClick={() => validateCheckout(true)}
                  loading={loading}
                  disabled={amountBankTransfer && !bankTransferReceipt}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Checkout;
