import React from "react";
import { regulamentos } from "../../../../components/PopupExtracoin/regulamentos";
import localeText from "../../../../locale";
import { locale } from "../../../../utilities/locale/locale";
import { Button } from "primereact/button";

const LandingLaunchPad = () => {
  const regulamento = regulamentos[locale];

  const handleClick = () => {
    window.location.href =
      "https://cryptosmart.it/pagina/launchpad-del-token-extracoin-su-cryptosmart";
  };
  
  return (
    <>
      <h1
        className="text-2xl lg:text-3xl  font-semibold mt-5 mx-auto mb-4"
        style={{ maxWidth: "20ch" }}
      >
        {localeText("joinTheLaunchpadButton")}
      </h1>
      <div className="flex flex-column align-items-center gap-3">
        <a
          href={regulamento}
          className="p-button p-button-danger font-bold text-xs text-center text-white"
          download
        >
          {localeText("SeeTheRegulationsHere")}
        </a>
        <Button
          className="p-button-warning p-button text-base m-0"
          style={{ height: "40px" }}
          label={
            <div className="flex flex-column justify-content-center">
              <b className="sm:text-base text-sm text-white">{localeText("clickHere")}</b>
            </div>
          }
          icon={
            <img
              alt="Extracredit"
              src="/assets/layout/images/icone_extracoin_site.webp"
              style={{ width: "30px", marginRight: "10px" }}
            />
          }
          onClick={handleClick}
        />
      </div>
    </>
  );
};

export default LandingLaunchPad;
