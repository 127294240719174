import axios from "axios";
import apiURI from "./Api";

const apiBDCKey = process.env.REACT_APP_API_BDC_KEY;
const apiZipCode = process.env.REACT_APP_ZIPCODEBASE_KEY;

export default class GetInfoService {
  async getConfiguration() {
    const response = await axios.get(`${apiURI}/marketplace/configuration/getconfiguration`);
    return response.data;
  }

  async getDynamicProducts(endpoint) {
    const response = await axios.get(`${apiURI}${endpoint}`);
    return response.data;
  }

  async getPlanList() {
    const response = await axios.get(`${apiURI}/plan/list`);
    return response.data;
  }

  async getPlanListByCurrency(currency) {
    const response = await axios.get(`${apiURI}/plan/list/currency/${currency}`);
    return response.data;
  }

  async getFindReferral(username) {
    const response = await axios.get(`${apiURI}/user/findreferall/` + username);
    return response.data;
  }

  async getMyReferral(username) {
    const response = await axios.get(`${apiURI}/network/myreferall/${username}`);
    return response.data;
  }

  async getFindUsername(username) {
    const response = await axios.get(`${apiURI}/user/findusername/` + username);
    return response.data;
  }

  async getFindEmail(email) {
    const response = await axios.get(`${apiURI}/user/findemail/` + email);
    return response.data;
  }

  async getFindDocumentNumber(documentNumber) {
    const response = await axios.get(`${apiURI}/user/finddocumentnumber/` + documentNumber);
    return response.data;
  }

  // THIRD-PARTY API: Big Data Cloud
  async getUserCurrency() {
    const response = await axios.get(
      `https://api.bigdatacloud.net/data/ip-geolocation?key=${apiBDCKey}`
    );
    return response.data.country.currency.code;
    // return 'GBP';
  }

  async getUserCountry() {
    const response = await axios.get(
      `https://api.bigdatacloud.net/data/ip-geolocation?key=${apiBDCKey}`
    );
    return response.data.country.isoAlpha2;
    // return 'GB';
  }

  async getUserLocation() {
    const response = await axios.get(
      `https://api.bigdatacloud.net/data/ip-geolocation?key=${apiBDCKey}`
    );
    return response.data.location;
  }

  async getForexRate(from, to) {
    const response = await axios.get(`${apiURI}/exchange/quote/${from}/to/${to}`);
    return response.data;
  }

  async getZipCodeInfo(zip, country) {
    const response = await axios.get(
      `https://app.zipcodebase.com/api/v1/search?apikey=${apiZipCode}&codes=${zip}&country=${country}`
    );
    return response.data;
  }
}
