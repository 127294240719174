import { Dialog } from "primereact/dialog";
import React from "react";
import localeText from "../../../../locale";
import AllSearchInputs from "../../../../pages/Shop/components/FiltersInSearch/components/AllSearchInputs/AllSearchInputs";
import Loading from "../../../Loading/Loading";
import { Button } from "primereact/button";

const AdvancedFilterModal = ({
  visible,
  setNotVisible,
  isLoadingStoreNames,
  isLoading,
  searchParams,
  setSearchParams,
  storeNames,
  filter,
  isAdvancedFilter,
}) => {
  return (
    <Dialog
      header={localeText("filters")}
      visible={visible}
      className="w-30rem m-2"
      onHide={setNotVisible}
      draggable={false}
    >
      <div className="m-0">
        {isLoadingStoreNames ? (
          <Loading classNameIcon="text-4xl" />
        ) : (
          <div className="px-4">
            <AllSearchInputs
              isLoading={isLoading}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              isAdvancedFilter={isAdvancedFilter ? true : false}
              storeNames={storeNames}
            />
          </div>
        )}
        <div className="flex justify-content-end">
          <Button
            onClick={() => filter()}
            disabled={isLoading}
            className="flex gap-2 justify-content-center align-items-center"
          >
            {isLoading && <Loading classNameIcon="text-sm" disableMargin />}
            {localeText("filter")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AdvancedFilterModal;
