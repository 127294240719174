import { PayPalButtons } from "@paypal/react-paypal-js";
import localeText from "../../locale";
import OrderService from "../../service/OrderService";
import { removelocalStorageToken } from "../../utilities/sessionStorage/token";
import { removelocalStorageUsername } from "../../utilities/sessionStorage/username";
import { useHistory } from "react-router-dom";
import IntegrationService from "../../service/IntegrationService";
import GetInfoService from "../../service/GetInfoService";

const PayPalCheckoutCart = (props) => {
  const { address, city, country, district, number, postalCode, state } = props.addressInfo;
  const {
    setPage,
    setDisplayPaymentModal,
    setIdTransaction,
    price,
    priceOriginal,
    toast,
    amountWallet,
    userCurrency,
  } = props;

  const Navigate = useHistory();

  const order = JSON.parse(localStorage.getItem("__extraconomy_order"));

  const orderService = new OrderService();
  const integrationService = new IntegrationService();
  const getUserInfoService = new GetInfoService();

  const firstDigit = (num) => {
    const matches = String(num).match(/\d/);
    const digit = Number(matches[0]);
    return num < 0 ? -digit : digit;
  };

  async function sendPurchase() {
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "Purchase",
    });
    const user = await getUserInfoService.getUserInfo(
      localStorage.getItem("__extraconomy_token"),
      localStorage.getItem("__extraconomy_username")
    );
    let ip = await integrationService.getUserIp();
    let date = new Date();
    let payload = {
      eventData: {
        currency: userCurrency,
        value: price,
      },
      eventName: "Purchase",
      eventTime: Math.floor(date.getTime() / 1000),
      userData: {
        email: user.result.user?.email ? user.result.user.email : "",
        ipAddress: ip.data.ip,
        phone: user.result.user?.phoneNumber ? user.result.user.phoneNumber : "",
        userAgent: window.navigator.userAgent,
      },
    };
    let res = await integrationService.sendEvent(payload);
    return res;
  }

  async function validateAndFinish(transactionID) {
    let amountBankTransfer = parseFloat(
      (props.amountBankTransfer ? props.amountBankTransfer : 0).toFixed(2)
    );
    try {
      props.setLoading(true);

      const res = await orderService.validateBeforeFinish(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username"),
        address,
        priceOriginal,
        amountWallet ?? 0,
        parseFloat(
          (props?.amountToPayWithExtracredit ? props?.amountToPayWithExtracredit : 0).toFixed(2)
        ),
        amountBankTransfer,
        props?.bankTransferReceipt,
        city,
        country,
        district,
        order.id,
        number,
        postalCode,
        state,
        transactionID
      );

      console.log(res);

      if (firstDigit(res.messageCode) !== 2) {
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
          life: 6000,
        });
        if (firstDigit(res.messageCode) === 5) {
          console.log(res);
        }
        props.setLoading(false);
        return false;
      } else {
        localStorage.removeItem("__extraconomy_order");
        finishOrder(transactionID);
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        removelocalStorageToken();
        removelocalStorageUsername();
        Navigate.push("/login");
      }
      props.setLoading(false);
      console.log(e);
      return false;
    }
  }

  async function finishOrder(transactionID) {
    let amountBankTransfer = parseFloat(
      (props.amountBankTransfer ? props.amountBankTransfer : 0).toFixed(2)
    );
    try {
      props.setLoading(true);

      const res = await orderService.postFinishOrder(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username"),
        address,
        priceOriginal,
        amountWallet ?? 0,
        parseFloat(
          (props?.amountToPayWithExtracredit ? props?.amountToPayWithExtracredit : 0).toFixed(2)
        ),
        amountBankTransfer,
        props?.bankTransferReceipt,
        city,
        country,
        district,
        order.id,
        number,
        postalCode,
        state,
        transactionID
      );

      if (res.messageCode !== 200000) {
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
          life: 6000,
        });
        setPage(4);
        if (res.messageCode === 500000) {
          console.log(res);
          setPage(4);
        }
        props.setLoading(false);
        return false;
      } else {
        localStorage.removeItem("__extraconomy_order");
        sendPurchase();
        setDisplayPaymentModal(false);
        setPage(3);
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        removelocalStorageToken();
        removelocalStorageUsername();
        Navigate.push("/login");
      }
      props.setLoading(false);
      console.log(e);
      return false;
    }
  }

  return (
    <>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: price, //transaction value (can be variable)
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            setIdTransaction(details.id);
            validateAndFinish(details.id);
          });
        }}
        onError={(err) => {
          console.log(err);
          toast.current.show({
            severity: "error",
            summary: localeText("error"),
            detail: localeText("unexpectedErrorTransaction"),
            life: 6000,
          });
        }}
      />
    </>
  );
};

export default PayPalCheckoutCart;
