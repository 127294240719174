import { DataView } from "primereact/dataview";
import { Dialog } from "primereact/dialog";
import React from "react";
import Loading from "../../../../../components/Loading/Loading";
import Question from "./Question";

const itemTemplate = ({ question, onwerProduct, productOwnerFirstName }) => {
  return (
    <div className="py-2 block w-full">
      <Question
        firstName={question.user.firstname}
        question={question.question}
        dateQuestion={question.dateQuestion}
        onwerProduct={onwerProduct}
        questionId={question.id}
        answer={question?.answer}
        dateAnswer={question?.dateAnswer}
        productOwnerFirstName={productOwnerFirstName}
      />
    </div>
  );
};

const QuestionsModal = ({
  visible,
  setAsFalse,
  headerText,
  isLoading,
  questions,
  onwerProduct,
  productOwnerFirstName,
}) => {
  return (
    <Dialog
      header={headerText}
      visible={visible}
      className="modal-review-rating"
      onHide={setAsFalse}
      draggable={false}
    >
      {isLoading ? (
        <Loading classNameIcon={"text-4xl"} />
      ) : (
        <div className="flex flex-column gap-3">
          <DataView
            className="flex flex-column"
            value={questions}
            itemTemplate={(question) =>
              itemTemplate({
                question,
                onwerProduct,
                productOwnerFirstName,
              })
            }
            paginator
            rows={5}
          />
        </div>
      )}
    </Dialog>
  );
};

export default QuestionsModal;
