import localeText from '../../../../locale';

import BenefitsCheck from "../../../images/landing/benefitsCheck.png";

const LandingBenefits = ({benefits, locale}) => {
    return (
        <div className="flex justify-content-center gap-4 lg:gap-8 flex-wrap align-content-start">
            {benefits.map((benefit) => (
                <div key={benefit} className="flex wrap gap-4 justify-content-center align-items-center text-center">
                    <img src={BenefitsCheck} alt="checkedBenefit" className="w-4rem h-4rem" />
                    <p className="font-semibold text-primary text-lg text-left" style={{ width: "15ch" }}>
                        {localeText(benefit)}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default LandingBenefits;
