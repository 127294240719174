const PTDict = {
  "pt": {
    "startsWith": "Começa com",
    "contains": "Contém",
    "notContains": "Não contém",
    "endsWith": "Termina com",
    "equals": "Igual",
    "notEquals": "Diferente",
    "noFilter": "Sem filtro",
    "lt": "Menor que",
    "lte": "Menor que ou igual a",
    "gt": "Maior que",
    "gte": "Maior que ou igual a",
    "dateIs": "Data é",
    "dateIsNot": "Data não é",
    "dateBefore": "Date é anterior",
    "dateAfter": "Data é posterior",
    "custom": "Customizado",
    "clear": "Limpar",
    "apply": "Aplicar",
    "matchAll": "Match All",
    "matchAny": "Match Any",
    "addRule": "Adicionar Regra",
    "removeRule": "Remover Regra",
    "accept": "Sim",
    "reject": "Não",
    "choose": "Escolha",
    "upload": "Upload",
    "cancel": "Cancelar",
    "dayNames": ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
    "dayNamesShort": ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    "dayNamesMin": ["Do", "Se", "Te", "Qa", "Qi", "Sx", "Sa"],
    "monthNamesShort": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    "today": "Hoje",
    "weekHeader": "Sem",
    "firstDayOfWeek": 0,
    "dateFormat": "dd/mm/yy",
    "weak": "Fraco",
    "medium": "Médio",
    "strong": "Forte",
    "passwordPrompt": "Digite uma senha",
    "emptyFilterMessage": "Sem opções disponíveis",
    "emptyMessage": "Sem resultados",
    "aria": {
      "trueLabel": "True",
      "falseLabel": "False",
      "nullLabel": "Não selecionado",
      "pageLabel": "Página",
      "firstPageLabel": "Primeira Página",
      "lastPageLabel": "Última Página",
      "nextPageLabel": "Próxima",
      "previousPageLabel": "Anterior"
    }
  }
}

export default PTDict