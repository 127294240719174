import { useState } from "react";
import useApiStatus from "../../../../../hooks/useApiStatus";
import ProductService from "../../../../../service/ProductService";
import { useEffect } from "react";

const useGetQuestions = ({ productId, onError }) => {
  const { isLoading, setIsLoading, hasError, setHasError } = useApiStatus();
  const [questions, setQuestions] = useState([]);

  const productService = new ProductService();

  const loadComments = async (productId) => {
    try {
      setIsLoading(true);
      const res = await productService.getQuestionsOfProduct(productId);
      if (res.messageCode !== 200001) {
        setHasError(true);
        onError(res.messageCode);
      } else {
        setHasError(false);
        setQuestions(res.result);
      }
    } catch (e) {
      setHasError(true);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (productId) loadComments(productId); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return {
    isLoading,
    questions,
    hasError,
    setQuestions,
  };
};

export default useGetQuestions;
