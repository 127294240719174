const networkTableDictPT = {
  showingFirstToLast: 'Mostrando {first} a {last} de {totalRecords}',
  profilePic: 'Foto de Perfil',
  name: 'Nome',
  level: 'Nível',
  searchByUsername: 'Buscar por nome de usuário',
  searchByName: 'Buscar por nome',
  searchByCountry: 'Buscar por país',
  searchByEmail: 'Buscar por email',
  searchByPlan: 'Buscar por plano',
}

export default networkTableDictPT