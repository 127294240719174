import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import localeText from "../../../../locale";

const SearchInput = ({ setExpression, expression, onSearch, isLoading }) => {
  return (
    <div className="pr-3 md:w-30rem relative mx-auto">
      <InputText
        value={expression}
        onChange={(e) => setExpression(e.target.value)}
        placeholder={localeText("searchProducts")}
        className="w-full font-light p-3 border-1 border-300 text-lg"
        style={{ borderRadius: "1rem" }}
        onKeyDown={(e) => {
          if (e.key === "Enter") onSearch(expression);
        }}
      />
      <Button
        className="absolute"
        icon={<i className="pi pi-search text-xl"></i>}
        style={{ color: "#46a748", top: 13, right: 30 }}
        rounded
        text
        onClick={() => onSearch(expression)}
        loading={isLoading}
      />
    </div>
  );
};

export default SearchInput;
