const appTopbarDictEN = {
  welcome: 'Welcome',
  editProfile: 'Edit Profile',
  logout: 'Logout',
  cancelAccount: 'Cancel Account',
  writeCancelToConfirm: 'Write "CANCEL" to confirm',
  confirmYourPassword: 'Confirm your password',
  accountCanceledSuccessfully: 'Account canceled successfully! You will be logged out shortly.',
  changePassword: 'Change Password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  passwordChangedSuccessfully: 'Password updated successfully!',
  upgradeAccount: 'Upgrade Account',
  upgradeTo: 'Upgrade to',
  learnMoreAboutThePlan: 'Learn more about the plan',
  upgradeWithVoucher: 'Upgrade account with voucher',
  including: 'including',
}

export default appTopbarDictEN