import { ACTIONS_TYPES } from "./actionsTypes";

export const addFavorite = () => ({
  type: ACTIONS_TYPES.ADD_FAVORITE,
});

export const removeFavorite = () => ({
  type: ACTIONS_TYPES.REMOVE_FAVORITE,
});

export const setFavoritesCount = (favoritesCount) => ({
  type: ACTIONS_TYPES.SET_FAVORITES_COUNT,
  payload: favoritesCount,
})
