import { useEffect, useState } from "react";
import GetInfoService from "../../../service/GetInfoService";

const useShopSections = ({ onError }) => {
  const service = new GetInfoService();

  const [isLoading, setLoading] = useState(true);
  const [shopSections, setShopSections] = useState([]);

  const loadShopSections = async () => {
    const response = await service.getConfiguration();

    if (response.messageCode !== 200001) {
      onError(response.messageCode);
      return;
    }

    setShopSections(response.result.sections);
    setLoading(false);
  };

  useEffect(() => {
    loadShopSections();
    return () => {
      setLoading(false);
      setShopSections([]);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    shopSections,
  };
};

export default useShopSections;
