const couponsDictIT = {
  specialCustomerRegisterCoupons: "Buoni di Credito",
  generateCoupon: "Generare un nuovo coupon",
  extracoinAmount: "Valore del credito Extracredit",
  unexpectedErrorWhileGeneratingCoupon:
    "Errore imprevisto durante la generazione del coupon di credito.",
  currency: "Moneta",
  used: "Usato",
  notUsed: "Non usato",
  couponGenerated: "Hai generato con successo un nuovo coupon di credito!",
  canceledCoupon: "Hai annullato l'operazione per generare un nuovo coupon di credito",
  areYouSureCoupon:
    "Sei sicuro di voler generare un nuovo coupon di credito? Il valore verrà addebitato sul tuo saldo Extracredit.",
  confirmYourAction: "Conferma la tua azione",
  confirm: "Confermare",
  usedBy: "Usato da",
  generatedCoupon: "Coupon generato",
};

export default couponsDictIT;
