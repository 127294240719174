import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import localeText from "../../../../../locale";
import { formatDate } from "../../../../../utilities/formatDate";
import { useLocation, useHistory } from "react-router-dom";
import Loading from "../../../../../components/Loading/Loading";

const breakWordStyle = {
  overflowWrap: "break-word",
  wordBreak: "break-word",
};

const AnswerModal = ({
  visible,
  setAsFalse,
  headerText,
  setAnswer,
  answer,
  isLoading,
  postAnswer,
  currentQuestion,
  isLoadingAnwserModal,
}) => {
  const history = useHistory();
  const url = useLocation();
  const params = new URLSearchParams(url?.search);

  const handleClose = () => {
    if (isLoading) return;
    params.delete("questionId");
    setAsFalse();
    setAnswer("");
    history.push(`${url.pathname}?${params?.toString()}`);
  };

  return (
    <Dialog
      header={headerText}
      visible={visible}
      className="modal-review-rating"
      onHide={handleClose}
      draggable={false}
    >
      {isLoadingAnwserModal ? (
        <Loading />
      ) : (
        <div>
          <p className="mb-0">
            <strong>{currentQuestion?.firstName}</strong> -{" "}
            {formatDate(currentQuestion?.dateQuestion)}
          </p>
          <p>{currentQuestion?.question}</p>
          {currentQuestion?.answer ? (
            <div className="flex gap-2 ml-1 align-items-start">
              <span>L</span>
              <div>
                <p className="mb-0">
                  <strong>{currentQuestion.productOwnerFirstName}</strong> -{" "}
                  {formatDate(currentQuestion.dateAnswer)}
                </p>
                <span style={breakWordStyle}>{currentQuestion.answer}</span>
              </div>
            </div>
          ) : (
            <>
              <InputTextarea
                value={answer}
                className="w-full"
                style={{ resize: "none" }}
                onChange={(e) => setAnswer(e.target.value)}
                rows={5}
                cols={30}
              />
              <div className="flex justify-content-end mt-2">
                <Button onClick={postAnswer} loading={isLoading}>
                  <span className="ml-2">{localeText("reply")}</span>
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </Dialog>
  );
};

export default AnswerModal;
