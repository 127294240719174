import localeText from "../../../../locale";
import { locale } from "../../../../utilities/locale/locale";

const LandingLinkHowItWorks = ({ link }) => {
  const isDownload = locale === "it" ? true : false;
  return (
    <a
      href={link}
      className="inline-flex mx-auto p-button py-3"
      target="_blank"
      rel="noopener noreferrer"
      download={isDownload}
    >
      {localeText("learnMore")}
    </a>
  );
};

export default LandingLinkHowItWorks;
