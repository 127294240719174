import localeText from "../../locale";

const showErrorToast = (toast, detail) => (code) => {
  toast.show({
    severity: "error",
    summary: localeText("error"),
    detail: detail
      ? localeText(detail)
      : localeText("data").find((msg) => msg.code === code).message,
    life: 6000,
  });
};

export default showErrorToast;
