const confirmDocumentDictEN = {
  confirmID: 'Confirm identity document',
  confirmDocumentPic: "Please consider uploading a photo of your identity document so we can confirm your account.",
  confirmDocumentPicNow: "Please upload a photo of your identity document to unlock the site and confirm your account.",
  documentUploadedSuccessfully: 'Document photo uploaded successfully!',
  youCanCloseThisWindow: 'You can close this window now.',
  photo: 'Photo',
  required: 'required',
}

export default confirmDocumentDictEN;