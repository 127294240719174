const shoppingCartDictES = {
  productType: "Tipo de producto:",
  soldBy: "Vendido por:",
  pickUpInStore: "Recoger en la tienda", // Recoger en tienda
  orderResume: "Resumen del Pedido",
  shippingOptions: "Opciones de envío",
  checkCatalog: "Consulte nuestro catálogo",
  emptyCartText: "Tu carrito de compras está vacío.",
  emptyCartSuggestion:
    "Agrega artículos a tu carrito y disfruta del mercado que siempre te recompensa.",
  percentualCreditInfo: "información de porcentaje de crédito",
  shop: "Tienda",
  searchProducts: "Buscar productos...",
  searchByTitleInThisSubcategory: "Buscar en esa subcategoria...",
  byCap: "Por",
  product: "Producto",
  noResultsFound: "No se encontraron resultados.",
  shoppingCart: "Carrito de compras",
  cart: "Carrito",
  subtotal: "Subtotal",
  onlyOneSeller: "Solo puedes comprar productos de un vendedor.",
  items: "Artículos",
  platformFee: "Tarifa de la plataforma",
  total: "Total",
  walletBalance: "Saldo actual de su billetera",
  amountToPayWithWalletBalance: "Pague con el saldo de su billetera",
  amountToPayWithExtracredit: "Pague con Extracredit",
  sendYourTransferReceipt: "Envíe aquí el justificante de la transferencia",
  youWillNeedToTransfer: "Debe transferir",
  toTheBankAccount: "a la cuenta bancaria",
  andUploadTheReceipt: "y cargar el recibo",
  payWithBankTransfer: "Pagar con transferencia bancaria",
  finishOrder: "Cerrar solicitud",
  searching: "Buscando",
  inSubcategory: "en la subcategoria",
  inAllProducts: "en todos los productos",
  productMaximumExtracoinPercent: "Porcentaje máximo de Extracredit (Producto)",
  userMaximumExtracoinPercent: "Porcentaje máximo de Extracredit (Usuario)",
  checkout: "Checkout",
  amountToPayAtCheckout: "Valor a pagar en el checkout",
  orderData: "Datos del pedido",
  howWillPay: "Cómo va a pagar",
  otherMethods: "Otras formas",
  creditRequested: "Crédito solicitado",
  moreDetails: "más detalles",
  productNotFound: "Producto no encontrado. Intente buscar en nuestro catálogo.",
  downloadableAndNonDownloadable:
    "Los productos descargables y no descargables no pueden estar en el mismo carrito.",
  balanceAvailable: "Saldo Extracredit disponible",
  freeExtracoinExplanation1: "Como",
  freeExtracoinExplanation2:
    "recién registrado, se le otorgará un pago Extracredit completo de forma gratuita en sus primeros tres pedidos, lo que significa que pagará el monto mínimo de compra sin gastar sus créditos Extracredit.",
  itemFavorited: "Ítem añadido a favoritos.",
  itemRemovedFromFavorites: "Ítem eliminado de favoritos.",
  info: "Info",
  searchForShipping: "Buscar opciones de envío para esta dirección postal",
  shippingQuotesFor: "Cotizaciones de envío para",
  pleasePickASendMethod:
    'Haga clic en el botón "Opciones de envío" y luego seleccione un método de envío',
  shippingCost: "Costo de envío",
  searchAddress: "Autocompletar con Código Postal",
  shippingResponsibilitySeller: "El envío es responsabilidad del vendedor",
  plataformFeeExplanation: "La tarifa de la plataforma es del 5% del valor total de los productos.",
  cashbackExplanation:
    "Cualquier cantidad que no se pague en extracredit tendrá el 50% convertido en cashback, regresando a su billetera en extracredit.",
  thankYouForYourPurchase: "Gracias por su compra",
  pleaseFillAllFieldsAddressOrSearchShippingMethod:
    "Complete todos los campos de dirección o haga clic en Opciones de envío.",
  deliveryAddress: "Dirección de entrega",
  pickUpAddress: "Recoge tu pedido en",
  orderId: "ID de pedido",
  orderDate: "Fecha de pedido",
  orderTotal: "Total de pedido",
  extraconomyExplain:
    "Tienes la flexibilidad de utilizar tu saldo Extracredit y/o saldo de la cartera para pagar tu pedido. Elige a continuación cuánto deseas usar de cada uno para completar tu compra.",
  orderInformation: "Información de pedido",
  goToMyShoppings: "Ir a mis compras",
  errorDontHavePhoneNumber:
    "No tienes un número de teléfono registrado. Complete su número de teléfono y código telefónico de país para continuar.",
  insufficientFunds: "fondos insuficientes",
  errorCheckout:
    "Se produjo un error al completar su pedido. Comuníquese con el soporte a través del correo electrónico a continuación. Y no olvide proporcionarle la información a continuación.",
  contactEmail: "Entrar en contacto",
  yourOrderWillBeProcessed: "Su pedido se liberará una vez confirmada la transferencia.",
  weWillNotifyYou: "Se lo notificaremos por correo electrónico tras la confirmación."
};

export default shoppingCartDictES;
