const userStoreDictES = {
  setUpStore: "Configura tu tienda",
  userStoreExplanation:
    "Tus productos publicados se mostrarán en la página de tu tienda. Para configurarla, proporcione un título, una descripción y un logotipo:",
  storeSetUpSuccessfully: "¡La tienda se configuró correctamente!",
  editYourStore: "Edita tu tienda",
  storeEditedSuccessfully: "Tienda editada con éxito!",
  thisUserHasntSetUpStore: "Este usuario no ha creado una tienda todavía.",
  ratings: "evaluaciones",
  rating: "evaluación",
  noRatings: "No hay evaluaciones de clientes", //'Evaluaciones de los usuarios',
  logoWrongAspectRatio:
    "El logotipo de la tienda debe tener una relación de aspecto de 4:1 (por ejemplo, 1200 x 300).",
  cropImage: "Recortar imagen",
  addCategory: "Agregar categoría",
  slug: "Enlace personalizado de la tienda",
  linkStore: "Enlace de la tienda",
};

export default userStoreDictES;
