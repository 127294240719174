const ITDict = {
  "it": {
    "accept": "Si",
    "reject": "No",
    "choose": "Scegli",
    "upload": "Carica",
    "cancel": "Annulla",
    "dayNames": ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    "dayNamesShort": ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    "dayNamesMin": ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    "monthNamesShort": ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    "today": "Oggi",
    "clear": "Chiaro",
    "weekHeader": "Sett.",
    "firstDayOfWeek": 1,
    "dateFormat": "dd/mm/yy",
    "weak": "Debole",
    "medium": "Medio",
    "strong": "Forte",
    "passwordPrompt": "Inserisci la password"
  }
}

export default ITDict