import localeText from "../../../../locale";
import { Link } from "react-router-dom";
import LandingLinkHowItWorks from "../LandingLinkHowItWorks/LandingLinkHowItWorks";

const LadingSwitchButtonHowItWorks = ({ links, locale }) => {
  const buttonLinks = {
    it: links.it,
    es: links.es,
    pt: links.pt,
  };
  const currentButtonLink = buttonLinks[locale];
  if (!currentButtonLink)
    return (
      <Link to="comingsoon" className="inline-flex mx-auto">
        <button type="button" className="p-button">
          <span className="p-button-text">{localeText("learnMore")}</span>
        </button>
      </Link>
    );

  return <LandingLinkHowItWorks link={currentButtonLink} />;
};

export default LadingSwitchButtonHowItWorks;
