const couponsDictES = {
  specialCustomerRegisterCoupons: "Cupones de Crédito",
  generateCoupon: "Generar un nuevo cupón",
  extracoinAmount: "Cantidad de crédito Extracredit",
  unexpectedErrorWhileGeneratingCoupon: "Error inesperado al generar cupón de crédito.",
  currency: "Moneda",
  used: "Usado",
  notUsed: "No usado",
  couponGenerated: "Has generado un nuevo cupón de crédito con éxito!",
  couponCanceled: "Ha cancelado la operación para generar un nuevo cupón de crédito.",
  areYouSureCoupon:
    "Está seguro de que desea generar un nuevo cupón de crédito? El importe se debitará de su saldo Extracredit.",
  confirmYourAction: "Confirma tu acción",
  confirm: "Confirmar",
  usedBy: "Usado por",
  generatedCoupon: "Cupón generado",
};

export default couponsDictES;
