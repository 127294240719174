const networkTableDictEN = {
  showingFirstToLast: 'Showing {first} to {last} of {totalRecords}',
  profilePic: 'Profile Picture',
  name: 'Name',
  level: 'Level',
  searchByUsername: 'Search by username',
  searchByName: 'Search by name',
  searchByCountry: 'Search by country',
  searchByEmail: 'Search by email',
  searchByPlan: 'Search by plan'
}

export default networkTableDictEN