import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToastContext } from "../../../../../contexts/ToastContext/ToastContext";
import GetInfoService from "../../../../../service/GetInfoService";
import OrderService from "../../../../../service/OrderService";
import { removelocalStorageToken } from "../../../../../utilities/sessionStorage/token";
import { removelocalStorageUsername } from "../../../../../utilities/sessionStorage/username";
import { useToggle } from "../../../../../hooks/useToggle";
import IospediscoService from "../../../../../service/iospedisco";

const useCheckout = ({
  order,
  setOrder,
  currencyProduct,
  currencyUser,
  setPrice,
  amountWallet,
  amountBankTransfer,
  setAmountBankTransfer,
  bankTransferReceipt,
  setBankTransferReceipt,
  amountToPayWithExtracredit,
  setAmountToPayWithExtracredit,
  price,
  currencyCode,
  userInfo,
  setAmountWallet,
  setAddressInfo,
  setPriceForex,
  setDisplayPaymentModal,
  sendMethod,
  setSendMethod,
  setAmountShipmentForexProduct,
  setPage,
  setAmountTransfer,
}) => {
  const [loading, setLoading] = useState(false);
  const { showErrorToast } = useToastContext();
  const { value: isPickup, toggle: toggleIsPickup } = useToggle();
  // Component-specific
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [district, setDistrict] = useState("");
  const [number, setNumber] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [amountShipmentForexUser, setAmountShipmentForexUser] = useState(0);
  const [isFreeFreight, setIsFreeFreight] = useState(false);
  const [isLoadingFreight, setIsLoadingFreight] = useState(false);

  const [showMessageSellerResponsibility, setShowMessageSellerResponsibility] = useState(false);
  // Shipping
  const [shippingQuotes, setShippingQuotes] = useState(null);

  const getInfoService = new GetInfoService();
  const orderService = new OrderService();
  const iospediscoService = new IospediscoService();

  const history = useHistory();

  async function checkZipCode(zip) {
    if (zip.length > 0) {
      try {
        const zip_ = zip.replace(/\D+/g, "");

        setLoading(true);

        const userCountry = await getInfoService.getUserCountry();
        const res = await getInfoService.getZipCodeInfo(zip_, userCountry);

        if (Array.isArray(res.results) && res.results.length === 0) {
          setDistrict("");
          setState("");
          setCity("");
          setCountry("");
          setLoading(false);
        } else if (res.results[`${zip_}`][0]) {
          setDistrict(res.results[`${zip_}`][0].city);
          setCity(res.results[`${zip_}`][0].province);
          setState(res.results[`${zip_}`][0].state_en);
          setCountry(res.results[`${zip_}`][0].country_code);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
  }

  async function validateCheckout(finish) {
    setLoading(true);

    if (
      !order?.itens[0].product?.subCategory?.service &&
      !order?.itens[0].product?.downloadable &&
      order?.seller?.addressStatus === "ENABLED" &&
      !showMessageSellerResponsibility &&
      !shippingQuotes &&
      !isPickup
    ) {
      showErrorToast({ detail: "pleasePickASendMethod" });
      setLoading(false);
      return false;
    }

    if (
      !order?.itens[0].product?.downloadable &&
      order?.itens[0]?.product?.shippingOption === "PAID" &&
      !sendMethod
    ) {
      showErrorToast({ detail: "pleasePickASendMethod" });
      setLoading(false);
      return;
    }

    if (!order?.itens[0].product?.downloadable && shippingQuotes && !sendMethod && !isPickup) {
      showErrorToast({ detail: "pleasePickASendMethod" });
      setLoading(false);
      return false;
    }

    if (
      (!order?.address ||
        !order?.city ||
        !order?.country ||
        !order?.district ||
        !order?.number ||
        !order?.postalCode ||
        !order?.state) &&
      !isPickup &&
      !order?.itens[0].product?.downloadable &&
      !order?.itens[0].product?.subCategory?.service &&
      !order?.itens[0].product?.service
    ) {
      showErrorToast({ detail: "pleaseFillAllFieldsAddressOrSearchShippingMethod" });
      setLoading(false);
      return;
    }

    if (isPickup) {
      const resShipping = await orderService.postAddPickup(
        address,
        city,
        country,
        district,
        0,
        postalCode,
        state,
        localStorage.getItem("__extraconomy_username"),
        order.id,
        null,
        localStorage.getItem("__extraconomy_token")
      );
      if (resShipping.messageCode === 200000) {
        setOrder(resShipping.result);
      }
      if (resShipping.messageCode !== 200000) {
        showErrorToast({ code: resShipping.messageCode });
        setLoading(false);
        return;
      }
    }

    finish ? finishOrder() : paymentCheckout();
  }

  async function paymentCheckout() {
    try {
      if (
        !order.itens[0].product.downloadable &&
        !order.itens[0].product.service &&
        (address.length === 0 ||
          city.length === 0 ||
          country.length === 0 ||
          district.length === 0 ||
          number.length === 0 ||
          postalCode.length === 0 ||
          state.length === 0) &&
        !isPickup
      ) {
        showErrorToast({ detail: "pleaseFillOutAll" });
        setLoading(false);
        return;
      }

      setAddressInfo({
        address: address,
        city: city,
        country: country,
        district: district,
        number: number,
        postalCode: postalCode,
        state: state,
      });

      if (currencyCode !== process.env.REACT_APP_BASE_PAYPAL_CURRENCY) {
        const resForex = await getInfoService.getForexRate(
          currencyCode,
          process.env.REACT_APP_BASE_PAYPAL_CURRENCY
        );
        setPriceForex(parseFloat((price * resForex.result).toFixed(2)));
      } else setPriceForex(parseFloat(price.toFixed(2)));

      setDisplayPaymentModal(true);
      setLoading(false);
    } catch (e) {
      if (e.status === 401) {
        removelocalStorageToken();
        removelocalStorageUsername();
        history.push("/login");
      }
      setLoading(false);
      console.log(e);
    }
  }

  async function finishOrder() {
    try {
      if (
        !order.itens[0].product.downloadable &&
        !order.itens[0].product.service &&
        (address.length === 0 ||
          city.length === 0 ||
          country.length === 0 ||
          district.length === 0 ||
          number.length === 0 ||
          postalCode.length === 0 ||
          state.length === 0) &&
        !isPickup
      ) {
        showErrorToast({ detail: "pleaseFillOutAll" });
        setLoading(false);
        return;
      }
      console.log(amountBankTransfer);
      const res = await orderService.postFinishOrder(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username"),
        address,
        0,
        amountWallet ? parseFloat(amountWallet.toFixed(2)) : 0,
        amountToPayWithExtracredit ? parseFloat(amountToPayWithExtracredit.toFixed(2)) : 0,
        amountBankTransfer ? parseFloat(amountBankTransfer).toFixed(2) : 0,
        bankTransferReceipt,
        city,
        country,
        district,
        order.id,
        number,
        postalCode,
        state,
        null
      );

      if (res.messageCode !== 200000) {
        showErrorToast({ code: res.messageCode });
        setLoading(false);
        setPage(4);
      } else {
        localStorage.removeItem("__extraconomy_order");
        setAmountTransfer(res.result?.amountTransferBank);
        setPage(3);
      }
    } catch (e) {
      if (e.status === 401) {
        removelocalStorageToken();
        removelocalStorageUsername();
        history.push("/login");
      }
      setLoading(false);
      console.log(e);
    }
  }

  const shippingQuote = async () => {
    try {
      setLoading(true);
      setIsLoadingFreight(true);
      if (country === "IT") {
        if (!!order?.itens?.find((item) => item.product.shippingOption === "FREE")) {
          const resShipping = await orderService.postAddShipping(
            address,
            city,
            country,
            district,
            number,
            postalCode,
            state,
            localStorage.getItem("__extraconomy_username"),
            order.id,
            null,
            localStorage.getItem("__extraconomy_token"),
            0
          );
          if (resShipping.messageCode !== 200000) {
            showErrorToast({ code: resShipping.messageCode });
            setLoading(false);
            setIsLoadingFreight(false);
            return;
          }
          setIsFreeFreight(true);
          setShowMessageSellerResponsibility(true);
          setLoading(false);
          setIsLoadingFreight(false);
          return;
        } else if (
          !!order?.itens?.find((item) => item.product.shippingOption === "FREESENDCLOUD")
        ) {
          const res = await iospediscoService.getLocatiobId({
            token: localStorage.getItem("__extraconomy_token"),
            postal_code: postalCode,
            country: "IT",
          });
          const locationResult = res?.locationResponse?.location_results;

          if (locationResult && locationResult[0]?.locations_list[0]?.id_location) {
            const locationId = locationResult[0]?.locations_list[0]?.id_location;
            const compare = await iospediscoService.compareCart({
              token: localStorage.getItem("__extraconomy_token"),
              id_location: locationId,
              pickup_date: order?.seller?.amountDaysToPickUp,
              boxes: order?.itens.map((item, i) => ({
                index: i + 1,
                depth: item.product?.length,
                height: item.product?.height,
                weight: item.product?.weight,
                width: item.product?.width,
              })),
            });

            const freights = compare?.comparisonResponse?.comparison_result;
            if (freights) {
              const freightObj = freights[0]?.comparison_items[0];

              const freteMaisBarato = {
                currency: "EUR",
                carrierMethod: freightObj?.courier,
                price: "free",
              };
              setSendMethod(freteMaisBarato);
              setShippingQuotes({ prices: [freteMaisBarato] });
              setLoading(false);
              setIsLoadingFreight(false);
              return;
            } else {
              showErrorToast({ code: 500000 });
              setIsLoadingFreight(false);
              setLoading(false);
              return;
            }
          }

          // const res = await shippingService.getQuoteShipping(
          //   order.id,
          //   localStorage.getItem("__extraconomy_token")
          // );
          // if (res.messageCode !== 200001) {
          //   showErrorToast({ code: res.messageCode });
          //   setLoading(false);
          //   setIsLoadingFreight(false);
          //   return;
          // }
          // let menorCusto = Infinity;
          // let freteMaisBarato = null;

          // for (const frete of res.result.prices) {
          //   if (frete.price < menorCusto) {
          //     menorCusto = frete.price;
          //     freteMaisBarato = frete;
          //   }
          // }
          // freteMaisBarato.price = "free";

          // setShippingQuotes({ prices: [freteMaisBarato] });
          // setLoading(false);
          // setIsLoadingFreight(false);
          // return;
        } else if (
          !!order?.itens?.find((item) => item.product.shippingOption === "PAID") ||
          !!order?.itens?.find((item) => item.product.shippingOption === "PICKUP" && !isPickup)
        ) {
          const res = await iospediscoService.getLocatiobId({
            token: localStorage.getItem("__extraconomy_token"),
            postal_code: postalCode,
            country: "IT",
          });
          const locationResult = res?.locationResponse?.location_results;

          if (locationResult && locationResult[0]?.locations_list[0]?.id_location) {
            const locationId = locationResult[0]?.locations_list[0]?.id_location;
            const compare = await iospediscoService.compareCart({
              token: localStorage.getItem("__extraconomy_token"),
              id_location: locationId,
              pickup_date: order?.seller?.amountDaysToPickUp,
              boxes: order?.itens.map((item, i) => ({
                index: i + 1,
                depth: item.product?.length,
                height: item.product?.height,
                weight: item.product?.weight,
                width: item.product?.width,
              })),
            });

            const freights = compare?.comparisonResponse?.comparison_result;
            if (freights) {
              const freightObj = freights[0]?.comparison_items[0];

              const freteMaisBarato = {
                currency: "EUR",
                carrierMethod: freightObj?.courier,
                price: freightObj?.total_amount_tax_excluded,
              };
              setSendMethod(freteMaisBarato);
              setShippingQuotes({ prices: [freteMaisBarato] });
              setLoading(false);
              setIsLoadingFreight(false);
              return;
            } else {
              showErrorToast({ code: 500000 });
              setIsLoadingFreight(false);
              setLoading(false);
              return;
            }
          }
        }

        setShowMessageSellerResponsibility(true);
        setIsLoadingFreight(false);
        setLoading(false);
      } else {
        if (!!order?.itens?.find((item) => item.product.shippingOption === "FREE")) {
          setIsFreeFreight(true);
          setShowMessageSellerResponsibility(true);
          setLoading(false);
          setIsLoadingFreight(false);
          return;
        }

        setShowMessageSellerResponsibility(true);
        setIsLoadingFreight(false);
        setLoading(false);
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        removelocalStorageToken();
        removelocalStorageUsername();
        history.push("/login");
      }
      console.log(e);
    }
  };

  const setAddressValues = () => {
    if (
      !order.itens[0].product.downloadable &&
      !order.itens[0].product.service &&
      (address.length === 0 ||
        city.length === 0 ||
        country.length === 0 ||
        district.length === 0 ||
        number.length === 0 ||
        postalCode.length === 0 ||
        state.length === 0)
    ) {
      showErrorToast({ detail: "pleaseFillOutAll" });
      setLoading(false);
      return false;
    }
    setLoading(true);
    setIsLoadingFreight(true);

    const orderObj = order;

    orderObj.postalCode = postalCode;
    orderObj.address = address;
    orderObj.city = city;
    orderObj.country = country;
    orderObj.district = district;
    orderObj.number = number;
    orderObj.state = state;

    localStorage.setItem("__extraconomy_order", JSON.stringify(orderObj));
    setOrder(orderObj);
    shippingQuote();
  };

  useEffect(() => {
    let cancel = false;

    async function addShipping() {
      try {
        if (country === "IT") {
          setLoading(true);
          if (sendMethod.price === "free") {
            const resShipping = await orderService.postAddShipping(
              address,
              city,
              country,
              district,
              Number(number),
              postalCode,
              state,
              localStorage.getItem("__extraconomy_username"),
              order.id,
              sendMethod.idsendcloudmethod,
              localStorage.getItem("__extraconomy_token"),
              0
            );
            if (resShipping.messageCode !== 200000) {
              showErrorToast({ code: resShipping.messageCode });
              setSendMethod(null);
              setLoading(false);
              return;
            }

            setIsFreeFreight(true);
            setShowMessageSellerResponsibility(true);
            setLoading(false);
            return;
          }
          let sendMethodPrice = sendMethod.price;
          if (sendMethod?.currency !== currencyProduct) {
            const resRate = await getInfoService.getForexRate(sendMethod.currency, currencyProduct);
            sendMethodPrice = sendMethod.price * resRate.result;
          }

          const resShipping = await orderService.postAddShipping(
            address,
            city,
            country,
            district,
            number,
            postalCode,
            state,
            localStorage.getItem("__extraconomy_username"),
            order.id,
            sendMethod.idsendcloudmethod,
            localStorage.getItem("__extraconomy_token"),
            sendMethodPrice
          );
          if (cancel) return;

          if (resShipping.messageCode !== 200000) {
            showErrorToast({ detail: resShipping.messageCode });
            if (resShipping.messageCode === 500000) console.log(resShipping);
            setLoading(false);
            return false;
          }
          const actualAmountTotal =
            resShipping.result.amountTotal - resShipping.result.amountShipment;

          const orderObj = {
            ...resShipping.result,
            amountTotal: actualAmountTotal,
          };

          let amountShipmentForexProduct = resShipping.result.amountShipment;

          setAmountShipmentForexUser(amountShipmentForexProduct);
          setAmountShipmentForexProduct(amountShipmentForexProduct);

          localStorage.setItem("__extraconomy_order", JSON.stringify(orderObj));
          setOrder(orderObj);

          const priceToPay = parseFloat(
            (
              actualAmountTotal +
              amountShipmentForexProduct -
              (amountWallet ?? 0) -
              (amountToPayWithExtracredit ?? 0) -
              (amountBankTransfer ?? 0)
            ).toFixed(2)
          );
          setPrice(parseFloat(Math.abs(priceToPay).toFixed(2)));

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        if (e.status === 401) {
          removelocalStorageToken();
          removelocalStorageUsername();
          history.push("/login");
        }
        setLoading(false);
        console.log(e);
      }
    }

    if (sendMethod) addShipping();

    return () => {
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMethod]);

  // Set user address info
  useEffect(() => {
    setPostalCode(userInfo?.postalCode ? userInfo.postalCode : "");
    setDistrict(userInfo?.district ? userInfo.district : "");
    setState(userInfo?.state ? userInfo.state : "");
    setCity(userInfo?.city ? userInfo.city : "");
    setCountry(userInfo?.country ? userInfo.country : "");
    setAddress(userInfo?.address ? userInfo.address : "");
    setNumber(userInfo?.number ? userInfo.number?.replace(/[^0-9]/g, "") : "");

    return () => {
      setShippingQuotes(null);
      setSendMethod(null);
      setIsFreeFreight(false);
      setShowMessageSellerResponsibility(false);
      setAmountShipmentForexProduct(0);
      setAmountWallet(0);
      setOrder((prev) => {
        return {
          ...prev,
          amountTotal: prev.amountTotal,
          amountShipment: 0,
        };
      });
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset order shipping when order is pickup
  useEffect(() => {
    if (shippingQuotes && isPickup) {
      setSendMethod(null);
      setShippingQuotes(null);
    }
  }, [isPickup, setSendMethod, shippingQuotes]);

  const resetOrderWhenChangeAddress = () => {
    setPrice((prev) => prev - order.amountShipment);
    setShippingQuotes(null);
    setSendMethod(null);
    setIsFreeFreight(false);
    setShowMessageSellerResponsibility(false);
    // const order = JSON.parse(localStorage.getItem("__extraconomy_order"));
    // const newOrder = {
    //   ...order,
    //   amountShipment: 0,
    //   amountTotal: order.amountTotal - order.amountShipment,
    // };
    // setOrder(newOrder);
    // localStorage.setItem("__extraconomy_order", JSON.stringify(newOrder));
  };

  useEffect(() => {
    return () => {
      setShippingQuotes(null);
      setSendMethod(null);
      setIsFreeFreight(false);
      setShowMessageSellerResponsibility(false);
      setAmountShipmentForexProduct(0);
      setAmountWallet(0);
      // const order = JSON.parse(localStorage.getItem("__extraconomy_order"));
      // const newOrder = {
      //   ...order,
      //   amountShipment: 0,
      // };
      // setOrder(newOrder);
      // localStorage.setItem("__extraconomy_order", JSON.stringify(newOrder));
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    validateCheckout,
    loading,
    amountShipmentForexUser,
    postalCode,
    shippingQuotes,
    isFreeFreight,
    setNumber,
    number,
    setAddressValues,
    setDistrict,
    resetOrderWhenChangeAddress,
    setAddress,
    showMessageSellerResponsibility,
    country,
    checkZipCode,
    state,
    city,
    district,
    toggleIsPickup,
    isPickup,
    address,
    isLoadingFreight,
    setCity,
    setPostalCode,
    setState,
    amountToPayWithExtracredit,
    setAmountToPayWithExtracredit,
    amountBankTransfer,
    setAmountBankTransfer,
    bankTransferReceipt,
    setBankTransferReceipt,
  };
};

export default useCheckout;
