const networkTableDictIT = {
  showingFirstToLast: 'Visualizzazione da {first} a {last} di {totalRecords}',
  profilePic: 'Immagine del Profilo',
  name: 'Nome',
  level: 'Livello',
  searchByUsername: 'Cerca per nome utente',
  searchByName: 'Ricerca per nome',
  searchByCountry: 'Ricerca per paese',
  searchByEmail: 'Ricerca per email',
  searchByPlan: 'Ricerca per piano',
}

export default networkTableDictIT