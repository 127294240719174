import { Dropdown } from 'primereact/dropdown';
import { locale } from '../../utilities/locale/locale';
import { useEffect, useState } from 'react';

const LanguageSelectionButton = () => {
  

  const [language, setLanguage] = useState(null);

  const languages = [
    { label: 'English', iso639: 'en' },
    { label: 'Português', iso639: 'pt' },
    { label: 'Italiano', iso639: 'it' },
    { label: 'Español', iso639: 'es' },
  ]

  useEffect(() => {
    switch (locale) {
      case 'pt':
        setLanguage({ label: 'Português', iso639: 'pt' });
        break;
      case 'it':
        setLanguage({ label: 'Italiano', iso639: 'it' });
        break;
      case 'es':
        setLanguage({ label: 'Español', iso639: 'es' });
        break;
      default:
        setLanguage({ label: 'English', iso639: 'en' });
        break;
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='flex align-items-center lg:ml-0 md:ml-0 ml-6'>
      <i className="pi pi-globe mr-2 text-2xl text-700"></i>
      <Dropdown className='text-center' value={language} options={languages} onChange={(e) => {
        setLanguage(e.value)

        switch (e.value.iso639) {
          case 'pt':
            localStorage.setItem('__extraconomy_locale', 'pt');
            window.location.reload();
            break;
          case 'it':
            localStorage.setItem('__extraconomy_locale', 'it');
            window.location.reload();
            break;
          case 'es':
            localStorage.setItem('__extraconomy_locale', 'es');
            window.location.reload();
            break;
          default:
            localStorage.setItem('__extraconomy_locale', 'en');
            window.location.reload();
            break;
        }
      }} style={{ minWidth: '108px' }} />
    </div>
  )
}

export default LanguageSelectionButton