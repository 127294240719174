import { CartLogo } from "../svg/cart";
import useButtonAddToCart from "../../../hooks/useButtonAddToCart";
import { useToastContext } from "../../../../../contexts/ToastContext/ToastContext";
import IntegrationService from "../../../../../service/IntegrationService";
import GetUserInfoService from "../../../../../service/GetUserInfoService";

const integrationService = new IntegrationService();
const getUserInfoService = new GetUserInfoService();

export const MiniAddToCart = ({
  productInfo,
  currentVariation,
  currentAttribute1Value,
  currentAttribute2Value,
  productQty,
}) => {
  const { showErrorToast } = useToastContext();
  const { addToCart, loadingOrder } = useButtonAddToCart({
    currentVariation: currentVariation,
    currentAttribute1Value,
    currentAttribute2Value,
    productQty,
    productInfo,
    onErrorAddToCart: (code) => showErrorToast({ code }),
    onErrorDownloadableAndNonDownloadable: () =>
      showErrorToast({
        detail: "downloadableAndNonDownloadable",
      }),
    onErrorData: (code) => showErrorToast({ code }),
    onErrorItemAlreadyInCart: () => showErrorToast({ detail: "itemAlreadyInCart" }),
    onErrorOneSeller: () => showErrorToast({ detail: "onlyOneSeller" }),
  });

  const { product } = productInfo.data;

  const isDisabled = loadingOrder
    ? "opacity-80 pointer-events-none cursor-pointer flex align-items-center justify-content-center sm:p-2 p-1 border-green-400 sm:border-3 border-2 border-solid border-round-md"
    : "cursor-pointer flex align-items-center justify-content-center sm:p-2 p-1 border-green-400 border-2 border-solid border-round-md hover:bg-gray-100";
  const buttonClass = `${isDisabled}`;

  async function getUserInfo() {
    let info = await getUserInfoService.getUserInfo(
      localStorage.getItem("__extraconomy_token"),
      localStorage.getItem("__extraconomy_username")
    );
    senAddCart(info.result.user);
  }
  async function senAddCart(user) {
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "AddCart",
    });
    let ip = await integrationService.getUserIp();
    let date = new Date();
    let payload = {
      eventData: {
        currency: "",
        value: 0,
      },
      eventName: "AddCart",
      eventTime: Math.floor(date.getTime() / 1000),
      userData: {
        email: user ? user.email : "",
        ipAddress: ip.data.ip,
        phone: "",
        userAgent: window.navigator.userAgent,
      },
    };
    let res = await integrationService.sendEvent(payload);
    return res;
  }
  const addCart = async () => {
    localStorage.getItem("__extraconomy_token") ? getUserInfo() : senAddCart(null);
    addToCart()
  }

  return (
    <div
      className={buttonClass}
      onClick={() => {
        if (product?.user?.username === localStorage.getItem("__extraconomy_username"))
          return showErrorToast({ detail: "errorThisProductIsYours" });
        addCart();
      }}
    >
      <CartLogo />
    </div>
  );
};
