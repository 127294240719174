import { Rating } from "primereact/rating";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import useRatingProduct from "../../hooks/useRatingProduct";
import localeText from "../../locale";

const ProductRating = ({ productId }) => {
  const { showErrorToast } = useToastContext();
  const { ratingProduct } = useRatingProduct({
    productId,
    onError: (code) => showErrorToast({ code }),
  });

  return (
    <>
      {ratingProduct && (
        <>
          <div className="flex align-items-center justify-content-start">
            <Rating value={Math.ceil(ratingProduct.rate)} readOnly stars={5} cancel={false} />
            {ratingProduct.rate > 0 && (
              <label className="ml-2 text-600">{parseFloat(ratingProduct.rate.toFixed(2))}</label>
            )}
          </div>

          {ratingProduct.rate > 0 ? (
            <a
              className="inline-block text-left text-700 underline text-gray-800 hover:text-green-600 mb-2"
              href="#productReviews"
            >
              {ratingProduct.count}{" "}
              {ratingProduct.count > 1 ? localeText("ratings") : localeText("rating")}
            </a>
          ) : (
            <p className="text-left  text-700">{localeText("noRatings")}</p>
          )}
        </>
      )}
    </>
  );
};

export default ProductRating;
