const withdrawIntentDictIT = {
  paypalEmail: "Inserisci l'e-mail di PayPal qui sotto",
  amountWithdrawn: "Importo prelevato dal portafoglio",
  amountDeposited: "Importo depositato su PayPal",
  withdrawFee: "tassa di prelievo",
  paypalFee: "PayPal applicherà una tassa del",
  onTop: "sul valore trasferito",
  requestWithdraw: "Richiedi il ritiro",
  amountWithdrawnSuccessfully: "Importo prelevato con successo!",
  invalidemail: "e-mail non valido",
  withdrawRequests: "Richieste di prelievo",
  requestDate: "Data richiesta",
  processDate: "Data di elaborazione",
  processing: "In lavorazione",
  amountToDeposit: "Ammontare da depositare",
  amountRequested: "Importo richiesto",
  processRequest: "Processi la richiesta",
  process: "Processi",
  withdrawProcessedSuccessfully: "Richiesta di prelievo elaborata con successo!",
  askFinancialPassword: "Inserisci la tua password finanziaria",
  financialPasswordIsWrong: "Password finanziaria errata",
  withdrawWith: "Prelievo con",
  bank: "Banca",
  bankName: "Nome della banca",
  bankAccountNumber: "IBAN",
  accountOwner: "Nome del proprietario del conto bancario",
  allFieldsRequired: "Tutti i campi sono obbligatori",
};

export default withdrawIntentDictIT;
