const planListDictEN = {
  specialCustomer: "Customer",
  physicalPerson: "Natural Person",
  legalPerson: "Legal Entities",
  for: "For",
  free: "Free",
  joinPlan: "Join!",
  instantlyEarnPlanList: "Receive ",
  forYourPurchase: " Extrabonus",
  fisrtSpecialCustomerExplanation: "You can buy goods and services on our platform.",
  secondSpecialCustomerExplanation:
    "If you download our official app to sign up, you will earn free Extracredit.",
  businessPartner: "Partner",
  taxes: "tax",
  businessPartnerExplanation: "You can register all profiles",
  businessPartnerExplanationCont: "Can sell with showcase",
  businessPartnerExplanationCont2: "Annual renewal of ",
  areaManager: "Manager",
  areaManagerExplanation:
    "The most complete and beneficial plan to automatically increase your customers and your economic power through our cutting-edge technology.",
  areaManagerExplanationCont: "Win your ad campaign by signing up now!",
  taxExplanation: "* sales tax in accordance with your Country.",
  sellerExplanation:
    "Open your electronic store of products and/or services in a simple way and start doing your business quickly.",
  sellerExplanation2:
    "Sign up now for our exclusive and innovative platform with no monthly payments.",
  sellerExplanationRegister:
    "Open your electronic store of products and/or services in a simple way and start doing your business quickly. Ideal for those who need to offer products and services quickly, practically and simply through a powerful platform. For a registration fee of USD 3500, you enjoy the following benefits:",
  sellerExplanationRegister2:
    "Immediately receive a credit worth USD 3,500.00 for your future purchases (+ USD 1,500.00 if you are one of the first 1000 members in your country to register your company as a Seller).",
  sellerExplanationRegister3:
    "Purchase your e-commerce space to publicize and sell your products and services.",
  knowmore: "know more",
  sellPlans: "Sales plan",
  silverPro1: "Receive ",
  canSellWithEcommerce: "Can sell with e-commerce",
  geolocalizedStore: "Geolocated store",
  allInclusive: "All inclusive",
  noMonthlyPayments: "No monthly payments",
  unlimitedAds: "Unlimited ads",
  getsPersonalCommerce: "Get your personal e-commerce",
  openYourOnlineShop: "Open your online shop",
  earnCommission: "Earn commission",
  onlineShop: "Online shop",
  activeGeolocation: "Active geolocation",
  personalEcommerce: "Personal e-commerce",
  goToDropShipping: "Go to Drop-shipping",
  onlineShowcase: "Online showcase",
  activeCommission: "Active commission",
};

export default planListDictEN;
