const shopDictEN = {
  seeProduct: "See product",
  firstBannerText: "The marketplace that always rewards you",
  secondaryBannerText: "Join the Extraconomy",
  secondaryBannerButtonText: "Discover our plans",
  secondaryBannerText2: "Increase your economic power",
  searchQueryText: "You searched for: ",
  subCategoryFilter: "Subcategories",
  noProductsInCategory: "Has no products here",
  noProductsInSearch: "No products found",
  noSubcategories: "Has no subcategories",
  filters: "Filters",
  downloadable: "Downloadable",
  brazil: "Brazil",
  mexico: "Mexico",
  italy: "Italy",
  brl: "BRL",
  mxn: "MXN",
  eur: "EUR",
  usd: "USD",
  gbp: "GBP",
  try: "TRY",
  results: "Results",
  result: "Result",
  selectStore: "Select a store",
  store: "Store",
  filter: "Filter",
  registerAsCustomerFree: "Register as a Customer for free",
  register2: "Register",
};

export default shopDictEN;
