import { useEffect, useState } from "react";
import GetInfoService from "../../../service/GetInfoService";
import { parseProduct } from "../../../utilities/parsers/product";

const useSectionProducts = ({ endpoint, onError }) => {
  const service = new GetInfoService();

  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const loadShopSections = async () => {
    const response = await service.getDynamicProducts(endpoint);

    if (response.messageCode !== 200001) {
      onError(response.messageCode);
      return;
    }

    setProducts(response.result.map(parseProduct));
    setLoading(false);
  };

  useEffect(() => {
    loadShopSections();
    return () => {
      setLoading(false);
      setProducts([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    products,
  };
};

export default useSectionProducts;
