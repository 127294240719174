import localeText from "../../../../locale";
import { Tooltip } from "primereact/tooltip";

const ProductStateTag = ({ productInfo }) => {
  const { stateofproduct, downloadable, service } = productInfo.data.product;

  const text = stateofproduct === "NEW" ? "new" : "used";

  return (
    <>
      {!downloadable && !service ? (
        <>
          {stateofproduct && (
            <span
              className={`text-white lg:ml-3 ml-2 p-2 lg:text-base md:text-sm text-xs text-center inline-block border-round ${
                stateofproduct === "NEW" ? "bg-green-500" : "bg-primary"
              }`}
            >
              {localeText(text).toUpperCase()}
            </span>
          )}
        </>
      ) : (
        <>
          {!service && (
            <span className="md:mt-1 -mt-1">
              <span className="flex">
                <i
                  className="pi pi-download text-0 p-2 md:text-xl sm:text-sm border-circle ml-2 inline-block"
                  style={{ backgroundColor: "#0288D1" }}
                ></i>
                <Tooltip target=".downloadable-tooltip" />
                <i
                  className="pi pi-info-circle ml-1 downloadable-tooltip cursor-pointer text-600 inline-block "
                  data-pr-tooltip={`${localeText("downloadableProductExplanation")}`}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                  tooltipoptions={{ event: "focus" }}
                ></i>
              </span>
            </span>
          )}
        </>
      )}
    </>
  );
};

/*
{product?.service ? (
              <span
                style={{ marginTop: "-21px", opacity: 0 }}
                className="text-0 p-1 text-xl border-round mr-3 text-right border-circle"
              >
                <i className="pi pi-download text-0 p-2" style={{ opacity: 0 }}></i>
              </span>
            ) : (
              <>
                {!product?.stateofproduct ? (
                  <>
                    {product?.downloadable ? (
                      <span
                        style={{ marginTop: "-21px", backgroundColor: "#0288D1" }}
                        className="text-0 p-1 text-xl border-round mr-3 text-right border-circle"
                      >
                        <i className="pi pi-download text-0 p-2"></i>
                      </span>
                    ) : (
                      <span
                        style={{ marginTop: "-21px", opacity: 0 }}
                        className="text-0 p-1 text-xl border-round mr-3 text-right border-circle"
                      >
                        <i className="pi pi-download text-0 p-2" style={{ opacity: 0 }}></i>
                      </span>
                    )}
                  </>
                ) : (
                  <span
                    style={{
                      marginTop: "-16px",
                      backgroundColor: product?.stateofproduct === "NEW" ? "#0288D1" : "#f7b005",
                    }}
                    className={`text-0 py-2 px-3 border-round text-right font-bold text-xs ${
                      product?.stateofproduct === "NEW" ? "bg-green-500" : "bg-primary"
                    }`}
                  >
                    {product?.stateofproduct === "NEW" && localeText("new").toUpperCase()}
                    {product?.stateofproduct === "USED" && localeText("used").toUpperCase()}
                  </span>
                )}
              </>
            )}
*/

export default ProductStateTag;
