

const Wrapper = ({ classnames, style, children }) => {
  return (
    <div className={`mx-auto ${classnames}`} style={{ maxWidth: "1850px", style }}>
      {children}
    </div>
  );
};

export default Wrapper;
