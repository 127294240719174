const productsListDictEN = {
  productImage: "Main Image",
  title: "Title",
  priceUnique: "Price",
  productState: "State",
  productStatus: "Status",
  published: "Published",
  unpublished: "Not published",
  planNotAllowed: "Your current plan doesn't have access to this resource.",
  upgradeToBusinessPartner: "Upgrade to Partner",
  upgradeToAreaManager: "Upgrade to Manager",
  assignAttributes: "Assign Attributes",
  goBackProductList: "Go back to products list",
  noVariations:
    "This product doesn't have any variations. Would you like to automatically generate variations for this product?",
  generateVariations: "Generate Variations",
  manageVariations: "Manage product variations of",
  manageVariationsShort: "Manage Variations",
  image: "Image",
  stock: "Stock",
  delete: "Delete",
  pleaseChooseImage: "Please choose a image.",
  theValuesOfTheseAttributes: "The values of these attributes will be used to generate variations:",
  generateVariationsWithThese: "Generate variations with these attributes?",
  goBackToVariationsList: "Go back to variations list",
  confirmChanges: "Confirm Changes",
  pressThePencilIconTo: "Press the pencil button to edit variation image",
  variationUpdatedSuccessfully: "Product variation updated successfully!",
  variationMustHaveAValidPrice: "Variation must have a valid price.",
  variationDeletedSuccessfully: "Variation deleted successfully.",
  deleteThisVariation: "Are you sure you want to delete this variation?",
  preview: "Preview",
  selectTwoAttributes: "Select",
  and: "and",
  selectOneAttribute: "Select",
  unavailable: "Unavailable",
  youMustFirstGenerateVariations:
    "This is a variable product. You must first generate variations to publish it.",
  clickHereToGenerateVariations: "Click here to generate variations for this product",
  publish: "Publish",
  productPublishedSuccessfully: "Product published successfully!",
  productUnpublishedSuccessfully: "Product unpublished from your store successfully.",
  seller: "Seller",
  itemAlreadyInCart: "Item is already in cart.",
  unpublish: "Unpublish",
  visibility: "Visibility",
  downloadableProductExplanation:
    "This is a downloadable product. You can check a sample version before buying to have access to the actual download link.",
  searchByTitle: "Search by title",
  searchBySeller: "Search by seller",
  searchByBuyer: "Search by buyer",
  imported: "Imported",
  importOrigin: "Origin",
  archived: "To file",
  archivedProduct: "Archived",
};

export default productsListDictEN;
