const validateUserInfoDictES = {
  pleaseEnterValidEmail: "Por favor introduzca un correo electrónico válido.",
  thereIsAnExisting: "Ya hay una cuenta asociada a ese correo electrónico .",
  usernameWhitespace: "El nombre del usuario no puede tener caracteres de espacio en blanco.",
  thereIsAnDocNumber: "Ya hay una cuenta asociada a este número de documento.",
  invalidCPF: 'Número de documento fiscal inválido',                  
  invalidCNPJ: 'Número de registro de persona jurídica inválido',                        
  invalidCodiceFiscale: 'Codice Fiscale inválido',
  invalidPartitaIVA: 'Partita IVA inválido',
  invalidDocumentNumber: 'Número de documento fiscal no válido',
  unexpectedErrorRegisteringSC: 'Error inesperado al registrar Customer',
  invalidVoucher: 'Voucher inválido.',
  passwordFieldsMismatched: 'Los campos de contraseña no son compatibles.',
  pleaseFillOutAll: 'Por favor rellene todos los campos.',
  pleaseAgreeTerms: 'Por favor acepta los términos y condiciones.',
  pleaseTypeLongerPassword: 'Introduzca una contraseña con más de 6 caracteres.',
  pleaseTypeShorterPassword: 'Introduzca una contraseña con menos de 19 caracteres.',
  invalidPassword: 'Contraseña inválida. Debe tener sólo caracteres alfanuméricos y símbolos - _',
  noSpecialCharacters: 'El nombre del usuario sólo puede tener letras y/o números.',
  invalidRFC: 'RFC no válido.',
}

export default validateUserInfoDictES