const message_PT = {
    "data": [
        {
            "code": 500000,
            "message": "Erro Não Esperado do Sistema"
        },
        {
            "code": 500001,
            "message": "Patrocinador não encontrado"
        },
        {
            "code": 500002,
            "message": "Erro de pagamento"
        },
        {
            "code": 500003,
            "message": "Erro no cálculo de câmbios"
        },
        {
            "code": 500004,
            "message": "Método de pagamento não encontrado"

        },
        {
            "code": 500005,
            "message": "Gateway de pagamento não encontrado"
        },
        {
            "code": 500006,
            "message": "Plano não encontrado"
        },
        {
            "code": 500007,
            "message": "Quantidade é nula ou zero"
        },
        {
            "code": 500008,
            "message": "Quantidade é nula ou não há saldo suficiente na carteira"
        },
        {
            "code": 500009,
            "message": "Este tipo de usuário não pode ser patrocinador"
        },
        {
            "code": 500010,
            "message": "Id do Plano deve corresponder a um Partner"
        },
        {
            "code": 500011,
            "message": "Usuário patrocinador não encontrado"
        },
        {
            "code": 500012,
            "message": "Usuário não encontrado"
        },
        {
            "code": 500013,
            "message": "Falha autenticação do usuário"
        },
        {
            "code":  500014,
            "message": "Erro ao decodificar token de segurança"
        },
        {
            "code": 500015,
            "message": "Este token de segurança não pertence ao usuário da requisição"
        },
        {
            "code": 500016,
            "message": "Nome de usuário não está disponível"
        },
        {
            "code": 500017,
            "message": "Já existe uma conta associada a este email"
        },
        {
            "code": 500018,
            "message": "Campo currency está nulo"
        },
        {
            "code": 500019,
            "message": "Error no upload do arquivo"
        },
        {
            "code": 500020,
            "message": "O pagamento da transferência não pode ser confirmado"
        },
        {
            "code": 500021,
            "message": "Customer não pode transferir Extracredit"
        },
        {
            "code": 500022,
            "message": "Saldo insuficiente na carteira Extracredit"
        },
        {
            "code": 500023,
            "message": "Saldo insuficiente na carteira"
        },
        {
            "code": 500024,
            "message": "Já existe uma conta associada a este número de CPF"
        },
        {
            "code": 500025,
            "message": "Este e-mail não corresponde a este usuário"
        },
        {
            "code": 500026,
            "message": "Plano do usuário não encontrado"
        },
        {
            "code": 500027,
            "message": "Voucher inválido"
        },
        {
            "code": 500028,
            "message": "Pagamento com voucher só é permitido para cadastros de Manager"
        },
        {
            "code": 500029,
            "message": "ID da transação não encontrado"
        },
        {
            "code": 500030,
            "message": "Esta transação não pertence a este usuário"
        },
        {
            "code": 500031,
            "message": "Esta transação não está pendente de confirmação"
        },
        {
            "code": 500032,
            "message": "Categoria não localizada com esse ID"
        },
        {
            "code": 500033,
            "message": "Atributo não localizado com esse ID"
        },
        {
            "code": 500034,
            "message":"O ID do atributo não corresponde ao usuário da requisição"
        },
        {
            "code": 500035,
            "message": "Customer não pode criar produtos"
        },
        {
            "code": 500036,
            "message": "Sub-categoria não encontrada"
        },
        {
            "code": 500037,
            "message": "Produto não encontrado"
        },
        {
            "code": 500038,
            "message": "O ID do produto não corresponde ao usuário da requisição"
        },
        {
            "code": 500039,
            "message": "Este produto já possui esse atributo"
        },
        {
            "code": 500040,
            "message": "O valor mínimo para criar cupons é de 50"
        },
        {
            "code": 500042,
            "message": "Campo idioma está nulo"
        },
        {
            "code": 500041,
            "message": "Cupom é inválido"
        },
        {
            "code": 500043,
            "message": "Este cupom não pertence ao patrocinador fornecido"
        },
        {
            "code": 500044,
            "message": "Customer não pode criar cupons"
        },
        {
            "code": 500045,
            "message": "O produto não está associado a este atributo"
        },
        {
            "code": 500046,
            "message": "O produto já possui variações cadastradas"
        },
        {
            "code": 500047,
            "message": "Você não pode criar variações com o mesmo atributo"
        },
        {
            "code": 500048,
            "message": "Todos os atributos devem ter pelo menos um valor associado"
        },
        {
            "code": 500049,
            "message": "Marca não está associada a este subcategoria"
        },
        {
            "code": 500050,
            "message": "Campo subcategory não encontrado"
        },
        {
            "code": 500051,
            "message": "Marca não encontrada"
        },
        {
            "code": 500052,
            "message": "Tipo não está associado à subcategoria"
        },
        {
            "code": 500053,
            "message": "Tipo não encontrado"
        },
        {
            "code": 500054,
            "message": "Gênero do Produto não está associado a esta subcategoria"
        },
        {
            "code": 500055,
            "message": "Gênero do Produto não encontrado"
        },
        {
            "code": 500056,
            "message": "Variação do produto não encontrada"
        },
        {
            "code": 500057,
            "message": "Produto pode ter apenas 2 atributos"
        },
        {
            "code": 500058,
            "message": "Produto não tem nenhum atributos cadastrado"
        },
        {
            "code": 500059,
            "message": "Este produto possui variações. Você não pode modificar o preço total"
        },
        {
            "code": 500060,
            "message": "Esta variação não corresponde a este produto"
        },
        {
            "code": 500061,
            "message": "O campo linguagem está vazio ou nulo"
        },
        {
            "code": 500062,
            "message": "O pedido deve ter pelo menos um item"
        },
        {
            "code": 500063,
            "message": "Status permitido: PUBLICADO ou NÃO PUBLICADO"
        },
        {
            "code": 500064,
            "message": "Comprador e Vendedor devem ser diferentes"
        },
        {
            "code": 500065,
            "message": "Pedido não encontrado"
        },
        {
            "code": 500066,
            "message": "Este pedido não pertence a este usuário"
        },
        {
            "code": 500067,
            "message": "O produto no item não pertence ao vendedor do pedido"
        },
        {
            "code": 500068,
            "message": "Item não encontrado no pedido"
        },
        {
            "code": 500069,
            "message": "Este produto tem variações, por favor especifique uma idvariation"
        },
        {
            "code": 500070,
            "message": "A porcentagem do cashback deve estar entre 1% e 15% para Parceiros de Negócios."
        },
        {
            "code": 500071,
            "message": "A porcentagem do cashback deve estar entre 1% e 35% para Gerentes de Área."
        },
        {
            "code": 500072,
            "message": "A porcentagem de Extracredit está acima do que é solicitado pelo vendedor"
        },
        {
            "code": 500073,
            "message": "Você deve informar como deseja pagar (carteira e extracrédito)."
        },
        {
            "code": 500074,
            "message": "Produto esgotado"
        },
        {
            "code": 500075,
            "message": "Não é possível modificar/excluir um item de um pedido que não está aberto"
        },
        {
            "code": 500076,
            "message": "O valor máximo de extracredit possível foi excedido. Por favor, revise seu pedido!"
        },
        {
            "code": 500077,
            "message": "Este usuário não é o vendedor deste pedido"
        },
        {
            "code": 500078,
            "message": "Este pedido não pode ser enviado"
        },
        {
            "code": 500079,
            "message": "Este usuário não é o comprador deste pedido"
        },
        {
            "code": 500080,
            "message": "Este pedido não pode ser recebido"
        },
        {
            "code": 500081,
            "message": "Carreira não encontrada"
        },
        {
            "code": 500082,
            "message": "A carteira de origem tem saldo insuficiente na carteira para pagar a taxa"
        },
        {
            "code": 500083,
            "message": "A Carteira de destino não tem saldo suficiente para pagar a taxa"
        },
        {
            "code": 500084,
            "message": "A Carteira de origem não tem saldo suficiente para tranferir"
        },
        {
            "code": 500085,
            "message": "A Carteira de destino não tem saldo suficiente para tranferir"
        },
        {
            "code": 500086,
            "message": "Código de transferência não encontrado"
        },
        {
            "code": 500087,
            "message": "Este usuário não tem relação com esta Transferência"
        },
        {
            "code": 500088,
            "message": "A origem e o destino do usuário são os mesmos"
        },
        {
            "code": 500089,
            "message": "O código de transferência de confirmação não está correto"
        },
        {
            "code": 500090,
            "message": "O usuário de origem ainda não confirmou a transação"
        },
        {
            "code": 500091,
            "message": "Você só pode classificar os pedidos que marcou como recebidos"
        },
        {
            "code": 500092,
            "message": "Você já avaliou este pedido"
        },
        {
            "code": 500093,
            "message": "As taxas devem estar entre 1 e 5"
        },
        {
            "code": 500094,
            "message": "A senha atual está incorreta"
        },
        {
            "code": 500095,
            "message": "A nova senha deve ser diferente da senha atual"
        },
        {
            "code": 500096,
            "message": "Token de confirmação de telefone não encontrado"
        },
        {
            "code": 500097,
            "message": "Este número de telefone já está confirmado"
        },
        {
            "code": 500098,
            "message": "Código inválido - número de telefone de confirmação"
        },
        {
            "code": 500099,
            "message": "Apenas Customers podem atualizar para Manager"
        },
        {
            "code": 500100,
            "message": "Já existe um pagamento associado a este ID de transação"
        },
        {
            "code": 500101,
            "message": "A transação de pagamento não pôde ser confirmada"
        },
        {
            "code": 500102,
            "message": "Apenas Customers podem atualizar para Partner"
        },
        {
            "code": 500103,
            "message": "O usuário não está ativo. Você não pode solicitar saques."
        },
        {
            "code": 500104,
            "message": "O usuário remetente não tem relação com este pedido."
        },
        {
            "code": 500105,
            "message": "O usuário receptor não tem relação com este pedido."
        },
        {
            "code": 500106,
            "message": "Mensagem de bate-papo não encontrada."
        },
        {
            "code": 500107,
            "message": "O usuário não é o destinatário desta mensagem de bate-papo."
        },
        {
            "code": 500108,
            "message": "Este usuário pode não ver as mensagens deste pedido."
        },
        {
            "code": 500109,
            "message": "Apenas Managers podem indicar um Promoter."
        },
        {
            "code": 500110,
            "message": "O ID do Plano deve ser Promoter."
        },
        {
            "code": 500111,
            "message": "Promoter não pode criar produtos."
        },
        {
            "code": 500112,
            "message": "O administrador não pode criar produtos.."
        },
        {
            "code": 500113,
            "message": "Esta função requer permissão de administrador."
        },
        {
            "code": 500114,
            "message": "A latitude/longitude do campo é obrigatória."
        },
        {
            "code": 500115,
            "message": "O campo origem do cadastro é obrigatório."
        },
        {
            "code": 500116,
            "message": "Se o produto for para download, você deve fornecer os campos tipo de download , url de download e url download de exemplo."
        },
        {
            "code": 500117,
            "message": "Se pelo menos um item for um produto para download, todos os itens devem ser baixáveis."
        },
        {
            "code": 500118,
            "message": "Este pedido não possui itens para download."
        },
        {
            "code": 500119,
            "message": "Este dispositivo já está registrado."
        },
        {
            "code": 500120,
            "message": "Este dispositivo não está registrado."
        },
        {
            "code": 500121,
            "message": "Este dispositivo não pertence a este usuário."
        },
        {
            "code": 500122,
            "message": "Produto para download não pode criar variações."
        },
        {
            "code": 500123,
            "message": "Produto para download não pode receber atributos."
        },
        {
            "code": 500124,
            "message": "Saque mínimo permitido violado."
        },
        {
            "code": 500125,
            "message": "Somente Gerentes de Área podem criar produtos com informações técnicas."
        },
        {
            "code": 500126,
            "message": "ID de informação técnica não encontrado."
        },
        {
            "code": 500127,
            "message": "Campos peso, altura, largura ou comprimento não encontrados."
        },
        {
            "code": 500128,
            "message": "Este produto já foi adicionado como favorito por este usuário."
        },
        {
            "code": 500129,
            "message": "Este produto não foi adicionado aos favoritos deste usuário."
        },
        {
            "code": 500130,
            "message": "Saque não encontrado."
        },
        {
            "code": 500131,
            "message": "Esta retirada já foi processada."
        },
        {
            "code": 500132,
            "message": "Este usuário já está bloqueado."
        },
        {
            "code": 500133,
            "message": "Este usuário já está desbloqueado."
        },
        {
            "code": 500134,
            "message": "Pesquisa não encontrada."
        },
        {
            "code": 500135,
            "message": "Esta pesquisa já foi confirmada."
        },
        {
            "code": 500136,
            "message": "Já existe uma Marca com esta descrição."
          },
          {
              "code": 500137,
              "message": "Este tipo já está associado a esta subcategoria."
          },
          {
              "code": 500138,
              "message": "Esta marca já está associada a esta subcategoria."
          },
          {
              "code": 500139,
              "message": "Configuração do Marketplace não encontrada."
          },
          {
              "code": 500140,
              "message": "Este produto não pode ser enviado. Por favor, entre em contato com o vendedor."
          },
          {
              "code": 500141,
              "message": "Ainda não podemos enviar deste país."
          },
          {
              "code": 500142,
              "message": "Não foi possível encontrar uma transportadora para o seu pedido."
          },
          {
              "code": 500143,
              "message": "Este vendedor ainda não pode enviar."
          },
          {
              "code": 500144,
              "message": "Este vendedor ainda não pode enviar."
          },
          {
              "code": 500146,
              "message": "Produtos para download não podem ser enviados."
          },
          {
              "code": 500147,
              "message": "Os serviços não podem ser enviados."
          },
          {
              "code": 500148,
              "message": "Esta encomenda ainda não tem parcela atribuída. Por favor, aguarde alguns minutos e tente novamente."
          },
          {
              "code": 500149,
              "message": "Já existe uma coleta reservada para este lote."
          },
          {
              "code": 500150,
              "message": "Somente o vendedor pode reservar uma coleta."
          },
          {
              "code": 500151,
              "message": "Conversão não encontrada."
          },
          {
              "code": 500152,
              "message": "A conversão expirou."
          },
          {
              "code": 500153,
              "message": "Esta conversão não pertence ao usuário na solicitação."
          },
          {
              "code": 500154,
              "message": "Esta conversão foi usada."
          },
          {
              "code": 500155,
              "message": "Este usuário já cadastrou o endereço. Para modificar, entre em contato com o suporte."
          },
          {
              "code": 500156,
              "message": "Este pedido contém um serviço. Todos os itens devem ser serviços."
          },
          {
              "code": 500157,
              "message": "Esta encomenda não está associada a uma encomenda."
          },
          {
              "code": 500158,
              "message": "Os parceiros não podem criar mais de 50 produtos publicados!."
          },
          {
              "code": 500159,
              "message": "Os parceiros não podem criar mais de 50 produtos inéditos!"
          },
          {
            "code": 500162,
            "message": "Você só pode renovar sua assinatura a partir de 10 dias antes da data de vencimento!"
          },
          {
              "code": 500163,
              "message": "A renovação não pode ser paga por este método de pagamento!"
          },
          {
              "code": 500164,
              "message": "Este método só pode pagar renovação!"
          },
          {
              "code": 500165,
              "message": "Este voucher não pode ser usado para pagar este plano!"
          },
          {
              "code": 500166,
              "message": "A senha financeira não corresponde."
          },
          {
              "code": 500167,
              "message": "O ID do Plano deve ser Vendedor."
          },
          {
              "code": 500168,
              "message": "A porcentagem do cashback deve estar entre 1% e 25% para vendedores."
          },
          {
              "code": 500169,
              "message": "Um produto não pode ser serviço e ser baixável ao mesmo tempo."
          },
          {
            "code": 500170,
            "message": "Você está tentando adicionar um pedido de serviço que contém itens que não são de serviço."
          },
          {
            "code": 500171,
            "message": "Pergunta do produto não encontrada."
          },
          {
            "code": 500172,
            "message": "This user can not answer this question."
          },
          {
            "code": 500173,
            "message": "Você deve informar como deseja pagar (carteira e extracrédito)."
          },
          {
            "code": 500174,
            "message": "Você não pode perguntar sobre seu próprio produto."
          },
          {
            "code": 500175,
            "message": "Notificação não encontrada."
          },
          {
            "code": 500176,
            "message": "Esta notificação não pertence ao usuário solicitado."
          },
          {
            "code": 500177,
            "message": "Suas notificações não puderam ser enviadas porque seu saldo extracredit é insuficiente, mas seu produto está registrado corretamente."
          },
          {
            "code" : 500178,
            "message": "Se o seu pedido tiver pelo menos um item com frete grátis, todos os itens deverão ter frete grátis também."
          },
        {
            "code" : 500179,
            "message": "Você não pode criar produtos."
        },
        {
            "code" : 500180,
            "message": "Você não pode atualizar para SilverPro."
        },
        {
            "code" : 500181,
            "message": "Você não pode atualizar para GeoGold."
        },
        {
            "code" : 500182,
            "message": "Você não pode atualizar para o StartSeller."
        },
        {
            "code" : 500183,
            "message": "Compartilhar conteúdo não encontrado."
        },
        {
            "code" : 500184,
            "message": "Este produto já foi compartilhado por este usuário."
        },
        {
            "code" : 500185,
            "message": "Extrabonus da carteira não encontrado."
        },
        {
            "code" : 500186,
            "message": "Este pedido não pode ser retirado no endereço do vendedor."
        },
        {
            "code" : 500187,
            "message": "Chave pública não encontrada."
        },
        {
            "code" : 500188,
            "message": "Transação idCrypto não encontrada."
        },
        {
            "code" : 500189,
            "message": "Erro de pagamento. Não foi possível confirmar a transação da sua carteira criptografada."
        },
        {
          "code" : 500190,
          "message": "Caso o produto seja importado, deverá fornecer o campo OriginProductImported."
      },
        {
          "code" : 500191,
          "message": "Caso o produto seja importado da Banggood deverá fornecer o campo ProductIdBanggood."
        },
        {
          "code" : 500192,
          "message": "Este produto não está atribuído a este atributo."
        },
        {
          "code" : 500193,
          "message": "Este atributo é atribuído em pelo menos uma variação! Exclua as variações primeiro."
        },
        {
          "code" : 500194,
          "message": "Edite seu produto e defina a porcentagem de cashback antes de publicar."
        },
        {
          "code" : 500195,
          "message": "A porcentagem do cashback deve estar entre 1% e 5% para clientes."
        },
        {
          "code": 500198,
          "message": "Esse slug não está disponível."
        }
    ]
}

export default message_PT;
