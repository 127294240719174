import axios from "axios";
import apiURI from "./Api";

export default class IntegrationService {
  async getPixel() {
    const response = await axios({
      method: "get",
      url: `${apiURI}/meta/getpixel`

    });

    return response.data;
  }

  async configPixel(facebook_access_token, facebook_pixel_id, token) {
    const bodyJson = {
      facebook_access_token,
      facebook_pixel_id,
    };
    const response = await axios({
      method: "post",
      data: bodyJson,
      url: `${apiURI}/meta/configure-pixel`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async sendEvent(payload) {
    const response = await axios({
      method: "post",
      data: payload,
      url: `${apiURI}/meta/send-event`
    });

    return response.data;
  }

  async getUserIp() {
    const response = await axios({ method: "get", url: "https://api.ipify.org?format=json" });
    return response
  }

  async getGtm() {
    const response = await axios({
      method: 'get',
      url: `${apiURI}/gtm/getgtm`
    });

    return response.data;
  }

  async configGtm(gtm_code, token) {
    const bodyJson = {
      gtm_code
    }

    const response = await axios({
      method: 'post', data: bodyJson,
      url: `${apiURI}/gtm/configure-gtm`,
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return response.data;
  }
}
