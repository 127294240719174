import { Link } from "react-router-dom";
import localeText from "../../../../locale";
import { useUserContext } from "../../../../contexts/UserContext/UserContext";

const CustomerBanner = () => {
  const { userInfo } = useUserContext();

  if (userInfo) return null;

  return (
    <div className="flex-initial flex align-items-center sm:flex-row flex-column justify-content-center bg-green-500 font-bold text-white lg:p-5 p-4 border-round sm:gap-8 gap-2 lg:my-5 my-4">
      <p className="md:text-xl text-base text-center mb-0">
        {localeText("registerAsCustomerFree")}
      </p>
      <Link
        to="/specialcustomer"
        className="block bg-primary px-4 py-2 border-round hover:bg-primary-reverse transition-all transition-delay-100 md:text-base text-sm"
      >
        {localeText("register2")}
      </Link>
    </div>
  );
};

export default CustomerBanner;
