import { Button } from "primereact/button";

import localeText from "../../../../locale";
import AppNoTextLogo from "../../../images/logo-color-notext.png";
import { isMobile } from "../../../../utilities/isMobile";
import { Link } from "react-router-dom";

const SecondaryBanner = () => {
  return (
    <div className="flex justify-content-between gap-4 lg:flex-row flex-column">
      {isMobile === false && (
        <div className="flex flex-1 flex-column align-items-center justify-content-center bg-primary font-bold text-white lg:p-4 p-2 py-6 border-round gap-2">
          <p className="xl:text-4xl text-2xl text-center text-white">
            {localeText("secondaryBannerText")}
          </p>
          <Link to="/#plans" className="block">
            <Button className="xl:text-3xl lg:text-xl md:text-lg text-base">
              {localeText("secondaryBannerButtonText")}
            </Button>
          </Link>
        </div>
      )}
      <div className="banner-gradient-background flex flex-1 align-items-center justify-content-center font-bold text-white lg:p-4 p-2 py-6 border-round gap-4">
        <p className="xl:text-4xl lg:text-2xl text-xl xl:w-25rem w-15rem text-primary">
          {localeText("secondaryBannerText2")}
        </p>
        <img src={AppNoTextLogo} alt="app-logo" width={100} />
      </div>
    </div>
  );
};

export default SecondaryBanner;
