import React from "react";
import { inputs } from "../../inputs";
import { RadioButton } from "primereact/radiobutton";
import localeText from "../../../../../../locale";

const SearchInputsMap = ({ isDisabled, searchParams, setSearchParams, isAdvancedFilter }) => {
  return (
    <>
      {inputs.map((filter, index) => (
        <div key={index} className="mb-3">
          <h4 className="text-lg mb-0">{localeText(filter.title)}</h4>
          {filter.inputs.map((input, i) => (
            <div className={`flex align-items-center ${isDisabled}`} key={i}>
              <RadioButton
                className={`my-2 ${isDisabled}`}
                inputId={`${input.id}${input.type}${isAdvancedFilter}`}
                value={input.value}
                checked={searchParams[input.type] === input.value}
                onChange={() => setSearchParams((prev) => ({ ...prev, [input.type]: input.value }))}
              />
              <label
                htmlFor={`${input.id}${input.type}${isAdvancedFilter}`}
                className={`ml-2 cursor-pointer ${isDisabled}`}
              >
                {localeText(input.description)}
              </label>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default SearchInputsMap;
