function handleCurrency(currency) {
  let currencyDisplay = 'USD';

  switch (currency) {
    case 'BRL':
      currencyDisplay = 'BRL';
      break;
    case 'EUR':
      currencyDisplay = 'EUR';
      break;
    case 'MXN':
      currencyDisplay = 'MXN';
      break;
    case 'TRY':
      currencyDisplay = 'TRY';
      break;
    case 'GBP':
      currencyDisplay = 'GBP';
      break;
    default:
      break;
  }

  return currencyDisplay;
}

export default handleCurrency;