export const extrapayDictEN = {
  extrapayArea: "Extrapay Area",
  convertionExpiration: "Expiration date",
  conversionRate: "Conversion rate",
  extrapayRegulation: "*Click on the card to see the regulation",
  noConversions: "No conversions available",
  convert: "Convert",
  convertExtracoinToWallet: "Convert Extracredit to wallet",
  errorConversionIsZero: "Error converting, conversion value is zero",
  successConversion: "Conversion successful",
  errorExpiredConversion: "Error converting, conversion expired",
};
