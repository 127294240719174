const editProfileInfoDictPT = {
  choose: "Escolher",
  cancel: "Cancelar",
  dragImgFile: "Arraste o arquivo de imagem aqui",
  zipCode: "Código Postal",
  country: "País",
  state: "Estado",
  city: "Cidade",
  publicPlace: "Logradouro",
  district: "Bairro",
  number: "Número",
  countryPhoneCode: "Cód. de Tel. do País",
  regionalPhoneCode: "Cód. de Tel. Regional",
  phoneNumber: "Número de Tel.",
  changeProfilePic: "Trocar Foto de Perfil",
  confirmProfileChanges: "Confirmar Mudanças no Perfil",
  // toast
  pleaseChoosePfp: "Por favor escolha uma foto de perfil primeiro.",
  fileSizeTooBig: "O tamanho do arquivo é muito grande.",
  unexpectedErrorUploadImg: "Erro inesperado ao carregar a imagem.",
  usernameCantBeEmpty:
    "O nome do usuário deve conter apenas letras minúsculas e números. Não pode estar vázio ou conter espaços.",
  usernameNotAvailable: "Nome de usuário não disponível.",
  unexpectedErrorChangingPfp: "Erro inesperado ao alterar o perfil.",
  sucessfullyEditedProfile: "Perfil editado com sucesso.",
  unexpectedSystemBehaviour: "Comportamento inesperado do sistema.",
  canRetriveOnSaturdays: "O serviço de frete pode buscar o pedido nos dias de sábado?",
  quantityOfDaysToRetrive:
    "Quantidade de dias para o serviço de frete ir buscar seu pedido para levar ao destino",
};

export default editProfileInfoDictPT;
