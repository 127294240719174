const editProfileInfoDictIT = {
  choose: "Scegliere",
  cancel: "Annulla",
  dragImgFile: "Trascina qui il file immagine",
  zipCode: "Codice Postale",
  country: "Paese",
  state: "Stato",
  city: "Città",
  publicPlace: "Indirizzo",
  district: "Quartiere",
  number: "Numero civico",
  countryPhoneCode: "Prefisso Tel. del Paese",
  regionalPhoneCode: "Prefisso Tel. Regionale",
  phoneNumber: "Numero di Tel.",
  changeProfilePic: "Cambia Immagine del Profilo",
  confirmProfileChanges: "Conferma le Modifiche al Profilo",
  // toast
  pleaseChoosePfp: "Scegli prima un'immagine del profilo.",
  fileSizeTooBig: "La dimensione del file è troppo grande.",
  unexpectedErrorUploadImg: "Errore imprevisto durante il caricamento dell'immagine.",
  usernameCantBeEmpty:
    "Il nome utente deve contenere solo lettere minuscole e numeri. Non può essere vuoto o contenere spazi.",
  usernameNotAvailable: "Nome utente non disponibile.",
  unexpectedErrorChangingPfp: "Errore imprevisto durante la modifica del profilo.",
  sucessfullyEditedProfile: "Problema di sistema imprevisto.",
  unexpectedSystemBehaviour: "Comportamento imprevisto del sistema.",
  canRetriveOnSaturdays: "Il servizio di spedizione può ritirare l'ordine di sabato?",
  quantityOfDaysToRetrive:
    "Quantità di giorni per il servizio di spedizione per ritirare il tuo ordine e portarlo a destinazione",
};

export default editProfileInfoDictIT;
