const couponsDictPT = {
  specialCustomerRegisterCoupons: "Cupons de Crédito",
  generateCoupon: "Gerar um novo cupom",
  extracoinAmount: "Quantia de crédito Extracredit",
  unexpectedErrorWhileGeneratingCoupon: "Erro inesperado ao gerar cupom de crédito.",
  currency: "Moeda",
  used: "Usado",
  notUsed: "Não usado",
  couponGenerated: "Você gerou um novo cupom de crédito com sucesso!",
  couponCanceled: "Você cancelou a operação para gerar um novo cupom de crédito.",
  areYouSureCoupon:
    "Tem certeza de que deseja gerar um novo cupom de crédito? O valor será debitado do seu saldo Extracredit.",
  confirmYourAction: "Confirme sua ação",
  confirm: "Confirmar",
  usedBy: "Usado por",
  generatedCoupon: "Cupom gerado",
};

export default couponsDictPT;
