export const PLATFORM_PRODUCT_FEE = 0.05;

export const CUSTOMER_MIN_PERCENT_CASHBACK = 1;
export const CUSTOMER_MAX_PERCENT_CASHBACK = 5;

export const PARTNER_MIN_PERCENT_CASHBACK = 1;
export const PARTNER_MAX_PERCENT_CASHBACK = 15;

export const MANAGER_MIN_PERCENT_CASHBACK = 1;
export const MANAGER_MAX_PERCENT_CASHBACK = 35;

export const SELLER_MIN_PERCENT_CASHBACK = 1;
export const SELLER_MAX_PERCENT_CASHBACK = 25;
