const extracoinTransferDictPT = {
  extracoinTransfer: 'Transferência de Extracredit',
  amount: 'Valor',
  usernameNotFound: 'Usuário não encontrado.',
  receivingUsername: 'Usuário destinatário',
  amountCantBeZero: "O valor não pode ser zero.",
  attention: 'Atenção',
  confirmTransactionExplanation: 'Ao confirmar esta transação, você concorda em pagar uma taxa de transação de',
  doYouAgree: 'Está de acordo?',
  transactionConfirmed: 'Transação concluída com sucesso! Aguarde a confirmação do destinatário.',
  transactionReceived: 'Transação recebida com sucesso!',
  sender: 'Remetente',
  accepted: 'Aceita',
  toAgreeAndReceiveInsertCode: 'Para concordar e receber a transação, por favor insira abaixo o código que foi enviado para o seu endereço de e-mail',
  seeStatement: 'Ver extrato',
  showingReceivedRequests: 'Solicitações recebidas',
  showingSentRequests: 'Solicitações enviadas',
  acceptTransaction: 'Aceitar',
  addressee: 'Destinatário',
  financialPasswordCantBeEmpty: 'A senha financeira não pode ser vazia.',
  codeCantBeEmpty: 'O código não pode ser vazio.',
}

export default extracoinTransferDictPT;
