const shopDictIT = {
  seeProduct: "Vedi prodotto",
  firstBannerText: "Il marketplace che ti premia sempre",
  secondaryBannerText: "Unisciti all'Extraconomy",
  secondaryBannerButtonText: "Scopri i nostri piani",
  secondaryBannerText2: "Aumenta il tuo potere economico",
  searchQueryText: "Tu hai cercato per: ",
  subCategoryFilter: "Sottocategorie",
  noProductsInCategory: "Non ha prodotti",
  noProductsInSearch: "Nessun prodotto trovato",
  noSubcategories: "Non ha sottocategorie",
  filters: "Filtri",
  downloadable: "Scaricabile",
  brazil: "Brasile",
  mexico: "Messico",
  italy: "Italia",
  brl: "BRL",
  mxn: "MXN",
  eur: "EUR",
  usd: "USD",
  gbp: "GBP",
  try: "TRY",
  results: "Risultati",
  result: "Risultato",
  selectStore: "Seleziona un negozio",
  store: "Negozio",
  filter: "Filtro",
  registerAsCustomerFree: "Registrati come Customer gratuitamente",
  register2: "Registrati",
};

export default shopDictIT;
