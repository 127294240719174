const planListDictES = {
  specialCustomer: "Customer",
  physicalPerson: "Persona física",
  legalPerson: "Persona jurídica",
  for: "Por",
  free: "Gratis",
  joinPlan: "Únete!",
  instantlyEarnPlanList: "Reciba ",
  forYourPurchase: " Extrabonus",
  fisrtSpecialCustomerExplanation: "Você pode comprar bens e serviços em nossa plataforma.",
  secondSpecialCustomerExplanation: "Puede comprar bienes y servicios en nuestra plataforma.",
  businessPartner: "Partner",
  taxes: "tax",
  businessPartnerExplanation: "Puedes registrar todos los perfiles",
  businessPartnerExplanationCont: "Se puede vender con escaparate",
  businessPartnerExplanationCont2: "Renovación anual de ",
  areaManager: "Manager",
  areaManagerExplanation:
    "El plan más completo y beneficioso para aumentar automáticamente tus clientes y tu poder económico a través de nuestra tecnología de punta.",
  areaManagerExplanationCont: "¡Gana tu campaña publicitaria registrándote ahora!",
  taxExplanation: "* impuesto sobre las ventas según su País.",
  sellerExplanation:
    "Abre tu tienda electrónica de productos y/o servicios de forma sencilla y empieza a hacer tus negocios rápidamente.",
  sellerExplanation2:
    "Regístrate ahora en nuestra exclusiva e innovadora plataforma sin pagos mensuales.",
  sellerExplanationRegister:
    "Abre tu tienda electrónica de productos y/o servicios de forma sencilla y empieza a hacer tus negocios rápidamente. Ideal para quienes necesitan ofrecer productos y servicios de forma rápida, práctica y sencilla a través de una potente plataforma. Por una tasa de inscripción de MXN 3500, usted disfruta de los siguientes beneficios:",
  sellerExplanationRegister2:
    "Reciba inmediatamente un crédito por valor de MXN 3.500,00 para sus futuras compras (+ MXN 1.500,00 si es uno de los primeros 1000 miembros en su país en registrar su empresa como Vendedor).",
  sellerExplanationRegister3:
    "Compra tu espacio de comercio electrónico para dar a conocer y vender tus productos y servicios.",
  knowmore: "Sepa mas",
  sellPlans: "Plan de ventas",
  silverPro1: "Reciba ",
  canSellWithEcommerce: "Puede vender con e-commerce",
  geolocalizedStore: "Tienda geolocalizada",
  allInclusive: "Todo incluido",
  noMonthlyPayments: "Sin pagos mensuales",
  unlimitedAds: "Anuncios ilimitados",
  getsPersonalCommerce: "Obtén tu tienda personal",
  openYourOnlineShop: "Abre tu tienda en línea",
  earnCommission: "Gana comisión",
  onlineShop: "Tienda en línea",
  activeGeolocation: "Geolocalización activa",
  personalEcommerce: "E-commerce personal",
  goToDropShipping: "Ir a Drop-shipping",
  onlineShowcase: "Vitrina en línea",
  activeCommission: "Comisión activa",
};

export default planListDictES;
