import localeText from "../../../../locale";
import { PLATFORM_PRODUCT_FEE } from "../../../../utilities/constants";
import { formatPrice } from "../../../../utilities/formatPrice";

const ProductAttributes = ({ attributes, currentVariation, currency, finalPrice }) => {
  if (attributes.length === 2) {
    const [attribute1, attribute2] = attributes;

    return (
      <p className="flex align-items-center font-semibold mb-0 lg:text-2xl text-xl text-primary">
        {localeText("initialPrice")}:{" "}
        {currentVariation ? (
          <>
            {currency} {formatPrice(finalPrice)}
          </>
        ) : (
          <>
            {localeText("selectTwoAttributes")} "{attribute1.attribute}" {localeText("and")} "
            {attribute2.attribute}"
          </>
        )}
      </p>
    );
  }

  const [attribute1] = attributes;

  return (
    <p className="flex font-semibold mb-0 lg:text-4xl md:text-3xl text-xl text-primary flex-wrap">
      {localeText("initialPrice") + ":"}
      {currentVariation ? (
        <span className="pl-2">
          {currency} {formatPrice(finalPrice)}
        </span>
      ) : (
        <span className="font-normal pl-2">
          {localeText("selectOneAttribute")} "{attribute1.attribute}"
        </span>
      )}
    </p>
  );
};

const ProductPrice = ({
  productInfo,
  currentVariation,
  currentAttribute1Value,
  currentAttribute2Value,
  currency,
}) => {
  const { product, productVariations, attributes } = productInfo?.data;
  const { promotionalPriceUnique, priceUnique } = product;
  const hasVariation = productVariations.length > 0;

  const hasVariationsAndOneSelected =
    currentVariation &&
    ((attributes.length === 2 && currentAttribute1Value && currentAttribute2Value) ||
      (attributes.length === 1 && currentAttribute1Value));

  const variationNormalPrice =
    currentVariation?.price + currentVariation?.price * PLATFORM_PRODUCT_FEE;

  if (hasVariation) {
    return (
      <ProductAttributes
        currency={currency}
        attributes={attributes}
        currentVariation={currentVariation}
        finalPrice={variationNormalPrice}
      />
    );
  }

  const normalPrice = product?.priceUnique + product?.priceUnique * PLATFORM_PRODUCT_FEE;

  const finalPrice =
    product?.shippingOption === "FREESENDCLOUD"
      ? normalPrice + product?.shippingPrice
      : normalPrice;

  return (
    <h4
      className={`font-semibold mb-0 text-primary ${
        promotionalPriceUnique ? "lg:text-xl text-base" : "lg:text-2xl text-xl"
      }`}
    >
      {priceUnique && ( // Não tem preço promocional
        <>
          {`${localeText("initialPrice")}: `}
          <span className={promotionalPriceUnique ? "line-through" : ""}>
            {promotionalPriceUnique ? (
              <>
                {hasVariationsAndOneSelected ? (
                  <>
                    {currency} {formatPrice(variationNormalPrice)}
                  </>
                ) : (
                  <>
                    {productInfo.data.productVariations.length === 0 ? ( // Não tem variações e tem preço promocional
                      <>
                        {currency} {formatPrice(finalPrice)}
                      </>
                    ) : (
                      <>
                        {attributes.length === 2 ? ( // Tem 2 atributos
                          <>
                            {localeText("selectTwoAttributes")} "{attributes[0].attribute}"{" "}
                            {localeText("and")} "{attributes[1].attribute}"
                          </>
                        ) : (
                          // Tem 1 atributo
                          <>
                            {localeText("selectOneAttribute")} "{attributes[0].attribute}"
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {currency} {formatPrice(finalPrice)}
              </>
            )}
          </span>
        </>
      )}
    </h4>
  );
};

export default ProductPrice;
