import localeText from "../../../../locale";
import Loading from "../../../../components/Loading/Loading";
import ProductCard from "../../../ProductInfo/components/ProductCard/ProductCard";
import SearchFilters from "../FiltersInSearch/FiltersInSearch";

const Products = ({
  isSelectedCategory,
  products,
  isLoading,
  searchMessage,
  searchQueryParam,
  searchParams,
  setSearchParams,
  visible,
  onHide,
  isLoadingStoreNames,
  storeNames,
}) => {
  if (isLoading && !searchMessage)
    return <Loading classNameContainer="w-full" classNameIcon="text-6xl" />;

  if (!searchMessage && isSelectedCategory && products?.length === 0)
    return (
      <div
        className="flex flex-column gap-2 justify-content-center align-items-center text-gray-600 w-full align-self-center"
        style={{ minHeight: "30vh" }}
      >
        <i className="pi pi-search text-6xl text-gray-500"></i>
        <strong className="lg:text-xl text-base">{localeText("noProductsInCategory")}</strong>
      </div>
    );

  return (
    <div className="flex w-full align-items-start">
      {searchMessage && searchQueryParam && (
        <SearchFilters
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          visible={visible}
          onHide={onHide}
          isLoading={isLoading}
          isLoadingStoreNames={isLoadingStoreNames}
          storeNames={storeNames}
        />
      )}

      {isLoading ? (
        <Loading classNameContainer="w-full" classNameIcon="text-6xl" />
      ) : (
        <div className="shop-products-section w-full">
          {searchQueryParam && products.length === 0 && (
            <p
              className="text-center mx-2 mt-5 align-content-center"
              style={{ height: "10vh", gridColumn: "1/-1" }}
            >
              {localeText("noProductsInSearch")}
            </p>
          )}
          {(searchQueryParam || isSelectedCategory) &&
            products.map((product) => {
              return <ProductCard key={`shop${product.id}`} product={product} />;
            })}
        </div>
      )}
    </div>
  );
};

export default Products;
