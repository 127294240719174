const extracoinTransferDictEN = {
  extracoinTransfer: 'Extracredit transfer',
  amount: 'Amount',
  usernameNotFound: 'User not found.',
  receivingUsername: 'Receiving user',
  amountCantBeZero: "Amount can't be zero.",
  attention: 'Attention',
  confirmTransactionExplanation: 'By confirming this transaction, you agree to pay a transaction tax of',
  doYouAgree: 'Do you agree?',
  transactionConfirmed: 'Transaction done successfully! Wait for recipient confirmation.',
  transactionReceived: 'Transaction received successfully!',
  sender: 'Sender',
  accepted: 'Accepted',
  toAgreeAndReceiveInsertCode: 'To agree and receive the transaction, please enter the code that was sent to your e-mail address below',
  seeStatement: 'See statement',
  showingReceivedRequests: 'Received requests',
  showingSentRequests: 'Sent requests',
  acceptTransaction: 'Accept',
  addressee: 'Addressee',
  financialPasswordCantBeEmpty: 'Financial password can\'t be empty.',
  codeCantBeEmpty: 'Code can\'t be empty.',
}

export default extracoinTransferDictEN;
