const userStoreDictIT = {
  setUpStore: "Configura il tuo negozio",
  userStoreExplanation:
    "I tuoi prodotti pubblicati verranno visualizzati nella pagina del tuo negozio. Per configurarlo, fornire un titolo, una descrizione e un logo:",
  storeSetUpSuccessfully: "Il negozio è stato configurato correttamente!",
  editYourStore: "Modifica il tuo negozio",
  storeEditedSuccessfully: "Negozio modificato con successo!",
  thisUserHasntSetUpStore: "Questo utente non ha ancora creato un negozio.",
  ratings: "valutazioni",
  rating: "valutazione",
  noRatings: "Nessuna valutazione dei clienti", //'Valutazione di clienti'
  logoWrongAspectRatio: "Il logo del negozio deve essere in formato 4:1 (ad es. 1200x300).",
  cropImage: "Ritaglia immagine",
  addCategory: "Aggiungi categoria",
  slug: "Collegamento personalizzato del negozio",
  linkStore: "Collegamento del negozio",
};

export default userStoreDictIT;
