import localeText from "../../../../locale";

const ProductQuantity = ({
  productInfo,
  currentVariation,
  currentAttribute1Value,
  currentAttribute2Value,
}) => {
  return (
    <>
      {!productInfo.data.product.downloadable && (
        <p>
          {currentVariation &&
          ((productInfo.data.attributes.length === 2 &&
            currentAttribute1Value &&
            currentAttribute2Value) ||
            (productInfo.data.attributes.length === 1 && currentAttribute1Value)) ? (
            <>
              {currentVariation.amountInStock} {localeText("available")}
            </>
          ) : (
            <>
              {productInfo.data.productVariations.length === 0 && (
                <>
                  {productInfo.data.product.amountInStock} {localeText("available")}
                </>
              )}
            </>
          )}
        </p>
      )}
    </>
  );
};

export default ProductQuantity;
