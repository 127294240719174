import Carousel from "../../../../components/Carousel/Carousel";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";
import { locale } from "../../../../utilities/locale/locale";
import ProductCard from "../../../ProductInfo/components/ProductCard/ProductCard";
import useSectionProducts from "../../hooks/useSectionProducts";
import HeaderSection from "../HeaderSection/HeaderSection";

const defaultBreakpoints = {
  350: {
    slidesPerView: 2,
    spaceBetween: 0,
  },
  520: {
    slidesPerView: 3,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 4,
    spaceBetween: 0,
  },
  1100: {
    slidesPerView: 5,
    spaceBetween: 0,
  },
  1300: {
    slidesPerView: 6,
    spaceBetween: 0,
  },
  1600: {
    slidesPerView: 7,
    spaceBetween: 0,
  },
};

const ProductTemplate = ({ product }) => {
  return (
    <div className="product-item" style={{ minWidth: "60px" }}>
      <div className="product-item-content flex justify-content-center">
        <ProductCard product={product} />
      </div>
    </div>
  );
};

const SectionProducts = ({ section }) => {
  const titleOptions = {
    en: section.sectiondescriptionEnglish,
    it: section.sectiondescriptionItalian,
    pt: section.sectiondescriptionPortuguese,
    es: section.sectiondescriptionSpanish,
  };

  const currentTitle = titleOptions[locale] ?? null;

  const { showErrorToast } = useToastContext();
  const { isLoading, products } = useSectionProducts({
    endpoint: section.endpoint,
    onError: (code) => showErrorToast({ code }),
  });

  if (isLoading) {
    return (
      <div className="text-center my-8">
        <i className="pi pi-spin pi-spinner text-8xl text-400"></i>
      </div>
    );
  }

  return (
    <section className="mx-auto lg:my-5 my-4 w-full mx-0">
      {products.length > 0 && (
        <>
          <HeaderSection>{currentTitle}</HeaderSection>
          <Carousel
            breakpoints={defaultBreakpoints}
            items={products.map((product) => (
              <ProductTemplate product={product} />
            ))}
          />
        </>
      )}
    </section>
  );
};

export default SectionProducts;
