import { BreadCrumb } from "primereact/breadcrumb";

import { useHistory } from "react-router-dom";
import useCategories from "../../../../hooks/useCategories";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";
import { locale } from "../../../../utilities/locale/locale";

const BreadCrumbProduct = ({ selectedCategory, selectedSubcategory }) => {
  const history = useHistory();
  const { showErrorToast } = useToastContext();
  const { categories } = useCategories({
    onCategoriesError: (code) => showErrorToast({ code }),
  });

  const getCurrentCategory = () => {
    return categories?.find((category) => {
      const descriptions = {
        en: category.descriptionEnglish,
        it: category.descriptionItalian,
        pt: category.descriptionPortuguese,
        es: category.descriptionSpanish,
      };

      return descriptions[locale] === selectedCategory;
    });
  };

  const getCategoryId = () => {
    const currentCategory = getCurrentCategory();
    return currentCategory.id;
  };

  const getSubcategoryId = () => {
    const currentCategory = getCurrentCategory();
    return currentCategory.subCategories.find((subcategory) => {
      const descriptions = {
        en: subcategory.descriptionEnglish,
        it: subcategory.descriptionItalian,
        pt: subcategory.descriptionPortuguese,
        es: subcategory.descriptionSpanish,
      };

      return descriptions[locale] === selectedSubcategory;
    }).id;
  };

  const items = [
    {
      label: selectedCategory,
      command: () => {
        history.push(`/shop?category=${getCategoryId()}`);
      },
    },
    {
      label: selectedSubcategory,
      command: () => {
        history.push(`/shop?category=${getCategoryId()}&subcategory=${getSubcategoryId()}`);
      },
    },
  ].filter(({ label }) => Boolean(label));

  const home = {
    icon: "pi pi-home",
    command: () => {
      history.push("/shop");
    },
  };

  return (
    <BreadCrumb
      className="border-none px-0"
      style={{ backgroundColor: "inherit" }}
      model={items}
      home={home}
    />
  );
};

export default BreadCrumbProduct;
