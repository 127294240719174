import localeText from "../../../../locale";
import "./styles.css";

const MenuItem = ({ showOnMobile, elementId, localeOption: localeOptionProp }) => {
  return (
    <li className={`${showOnMobile ? "xl:hidden" : ""}`}>
      <button
        className={`button-blank mobile-a uppercase text-sm text-white hover:text-green-600`}
        onClick={() => document.getElementById(elementId).scrollIntoView(true)}
      >
        {localeText(localeOptionProp)}
      </button>
    </li>
  );
};

const menuItems = [
  { elementId: "header", localeOption: "home" },
  { elementId: "benefits", localeOption: "benefits" },
  { elementId: "features", localeOption: "features" },
  { elementId: "pricing", localeOption: "plans" },
  { elementId: "footer", localeOption: "contact" },
];

export const MenuItems = ({ showOnMobile }) => {
  return (
    <>
      {menuItems.map(({ elementId, localeOption }) => (
        <MenuItem
          key={elementId}
          showOnMobile={showOnMobile}
          elementId={elementId}
          localeOption={localeOption}
        />
      ))}
      <li className={`${showOnMobile ? "xl:hidden" : ""}`}>
        <a
          href="https://extraconomy.blog/"
          className={`uppercase block text-sm text-white hover:text-green-600 marginLeft`}
          target="_blank"
          rel="noreferrer"
        >
          Blog
        </a>
      </li>
    </>
  );
};

const LandingCenterMenu = () => {
  return (
    <ul id="landing-menu" className="landing-menu-center xl:flex xl:align-items-center block">
      <MenuItems />
    </ul>
  );
};

export default LandingCenterMenu;
