import { AdvancedImage } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";
import DOMPurify from "dompurify";
import { Rating } from "primereact/rating";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill/lib";
import ConfirmDocument from "../../components/ConfirmDocument/ConfirmDocument";
import Navbar from "../../components/Navbar/Navbar";
import ReviewsModal from "../../components/ReviewsModal/ReviewsModal";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import useGetCommentsOfStore from "../../hooks/useGetCommentsOfStore";
import { useToggle } from "../../hooks/useToggle";
import localeText from "../../locale";
import GetUserInfoService from "../../service/GetUserInfoService";
import { cloudinaryInstance } from "../../utilities/Cloudinary/cloudinaryInstance";
import { parseProduct } from "../../utilities/parsers/product";
import ProductCard from "../ProductInfo/components/ProductCard/ProductCard";

const ProductInfo = (props) => {
  const [loading, setLoading] = useState(false);
  const [userRating, setUserRating] = useState(null);
  const [storeInfo, setStoreInfo] = useState(null);

  let user = props.user;
  let slug = props.slug;


  const toast = useRef(null);

  const getUserInfoService = new GetUserInfoService();

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      try {
        setLoading(true);

        let res = null

        if(user){
          res = await getUserInfoService.getUserShop(user);
        }
        if(slug){
          res = await getUserInfoService.getShopBySlug(slug);
          user = res.result.shopConfiguration?.user?.username
        }
        if (cancel) return;

        if (res.messageCode !== 200001) {
          toast.current.show({
            severity: "error",
            summary: localeText("error"),
            detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
            life: 6000,
          });
          if (res.messageCode === 500000) console.log(res);
          setLoading(false);
        } else {
          setStoreInfo({ ...res.result, products: res.result.products.map(parseProduct) });

          const resRating = await getUserInfoService.getUserRating(user);

          if (resRating.messageCode !== 200001) {
            toast.current.show({
              severity: "error",
              summary: localeText("error"),
              detail: localeText("data").find((msg) => msg.code === resRating.messageCode).message,
              life: 6000,
            });
            if (resRating.messageCode === 500000) console.log(resRating);
            setLoading(false);
          } else {
            setUserRating(resRating.result);
            setLoading(false);
          }
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }

    fetchData();

    return () => {
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { showErrorToast } = useToastContext();
  const { value, setAsFalse, setAsTrue } = useToggle();
  const { isLoading, commentsOfStore } = useGetCommentsOfStore({
    onError: ({ code }) => showErrorToast({ code }),
    username: user,
  });

  return (
    <>
      <ReviewsModal
        headerText={`${localeText("storeReviews")} (${commentsOfStore.length})`}
        setAsFalse={setAsFalse}
        visible={value}
        comments={commentsOfStore}
        isLoading={isLoading}
      />
      <Navbar />
      <Toast ref={toast} />

      <ConfirmDocument />

      <div className="card m-4 border-300 border-1">
        {loading ? (
          <div className="text-center my-8">
            <i className="pi pi-spin pi-spinner text-8xl text-400"></i>
          </div>
        ) : (
          <>
            {storeInfo && (
              <>
                {!storeInfo.shopConfiguration ? (
                  <div className="text-center my-8">
                    <i className="pi pi-shopping-bag text-8xl text-400"></i>
                    <h4 className="text-500">{localeText("thisUserHasntSetUpStore")}</h4>
                  </div>
                ) : (
                  <>
                    <AdvancedImage
                      cldImg={cloudinaryInstance
                        .image(storeInfo.shopConfiguration.imagelogoid)
                        .resize(fill().width(1200).height(300))
                        .format("webp")}
                      className="border-round border-1 border-300 w-full"
                    />

                    <h3>{storeInfo.shopConfiguration.title}</h3>
                    <div className="flex align-items-center">
                      <Rating
                        value={Math.ceil(userRating.rate)}
                        readOnly
                        stars={5}
                        cancel={false}
                      />
                      {userRating.rate > 0 && (
                        <label className="ml-2 text-600">
                          {parseFloat(userRating.rate.toFixed(2))}
                        </label>
                      )}
                    </div>

                    {userRating.rate > 0 ? (
                      <p
                        className="font-semibold text-xl underline text-gray-800 hover:text-green-600 transition-colors transition-duration-500 inline-block cursor-pointer"
                        onClick={setAsTrue}
                      >
                        {userRating.count}{" "}
                        {userRating.count > 1 ? localeText("ratings") : localeText("rating")}
                      </p>
                    ) : (
                      <p className="font-bold text-xl">{localeText("noRatings")}</p>
                    )}

                    <div className="mt-4">
                      <ReactQuill
                        className="react-quill-editor"
                        value={DOMPurify.sanitize(storeInfo.shopConfiguration.description)}
                        readOnly
                        theme="bubble"
                      />
                    </div>

                    {(storeInfo.shopConfiguration.instagram ||
                      storeInfo.shopConfiguration.twitter ||
                      storeInfo.shopConfiguration.facebook ||
                      storeInfo.shopConfiguration.youtube ||
                      storeInfo.shopConfiguration.website) && (
                      <div className="mt-4 mb-2">
                        <label htmlFor="socialMedia">{localeText("socialMedia")}</label>
                      </div>
                    )}

                    {storeInfo.shopConfiguration.instagram && (
                      <div className="flex align-items-center">
                        <i className="pi pi-instagram text-700"></i>
                        <a
                          className="button-blank mobile-a ml-1"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://instagram.com/${storeInfo.shopConfiguration.instagram}`}
                        >
                          {storeInfo.shopConfiguration.instagram}
                        </a>
                      </div>
                    )}
                    {storeInfo.shopConfiguration.twitter && (
                      <div className="flex align-items-center">
                        <i className="pi pi-twitter text-700"></i>
                        <a
                          className="button-blank mobile-a ml-1"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://twitter.com/${storeInfo.shopConfiguration.twitter}`}
                        >
                          {storeInfo.shopConfiguration.twitter}
                        </a>
                      </div>
                    )}
                    {storeInfo.shopConfiguration.facebook && (
                      <div className="flex align-items-center">
                        <i className="pi pi-facebook text-700"></i>
                        <a
                          className="button-blank mobile-a ml-1"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://facebook.com/${storeInfo.shopConfiguration.facebook}`}
                        >
                          {storeInfo.shopConfiguration.facebook}
                        </a>
                      </div>
                    )}
                    {storeInfo.shopConfiguration.youtube && (
                      <div className="flex align-items-center">
                        <i className="pi pi-youtube text-700"></i>
                        <a
                          className="button-blank mobile-a ml-1"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://youtube.com/${storeInfo.shopConfiguration.youtube}`}
                        >
                          {storeInfo.shopConfiguration.youtube}
                        </a>
                      </div>
                    )}

                    {storeInfo.shopConfiguration.website && (
                      <div className="my-2">
                        <label htmlFor="site">Website</label>
                        <div className="flex align-items-center">
                          <i className="pi pi-link"></i>
                          <a
                            className="button-blank mobile-a ml-1"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${storeInfo.shopConfiguration.website}`}
                          >
                            {storeInfo.shopConfiguration.website}
                          </a>
                        </div>
                      </div>
                    )}

                    {storeInfo.shopConfiguration.linkedin && (
                      <div className="my-2">
                        <label htmlFor="site">Linkedin</label>
                        <div className="flex align-items-center">
                          <i className="pi pi-linkedin"></i>
                          <a
                            className="button-blank mobile-a ml-1"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://linkedin.com/${storeInfo.shopConfiguration.linkedin}`}
                          >
                            {storeInfo.shopConfiguration.linkedin}
                          </a>
                        </div>
                      </div>
                    )}

                    {storeInfo.shopConfiguration.tiktok && (
                      <div className="my-2">
                        <label htmlFor="site">TikTok</label>
                        <div className="flex align-items-center">
                          <i className="pi pi-tiktok"></i>
                          <a
                            className="button-blank mobile-a ml-1"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://tiktok.com/@${storeInfo.shopConfiguration.tiktok}`}
                          >
                            {storeInfo.shopConfiguration.tiktok}
                          </a>
                        </div>
                      </div>
                    )}

                    <div className="mt-6">
                      <h4>{localeText("products")}</h4>
                      <hr className="my-2"></hr>

                      <div className="shop-products-section w-full">
                        {storeInfo.products.map((product) => {
                          return (
                            <div key={`store${product.id}`}>
                              <ProductCard product={product} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ProductInfo;
