const userDashboardHomeDictPT = {
  bonus: "Renda diária",
  balance: "Saldo",
  inbound: "Crédito",
  outbound: "Débito",
  every: "A cada ",
  AmountIsAutomaticallyTransferredToWallet:
    " o valor é transferido automaticamente para a carteira principal.",
  currentlyYouCan: "Atualmente você pode utilizar até ",
  ofYourExtracoin: " do seu saldo Extracredit para pagar suas compras.",
  extracoinOnlyBetweenUsers:
    "O Extracredit é somente para compras e transferências entre usuários!",
  indirects: "Indiretos",
  stats: "Estatísticas",
  plan: "Plano",
  subscription: "Assinatura",
  daysLeft: " dias restantes",
  activation: "Ativação",
  seeAllDirects: "Ver todos os diretos",
  youDontHaveAny: "Você não tem nenhum usuário direto na seu Círculo Econômico ainda",
  days: "dias",
  months: "meses",
  year: "ano",
  numberOfUsersInNetwork: "Número de usuários adicionados à seu Círculo Econômico",
  date: "Data",
  monthNames: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  quantity: "Quantidade",
  referralLinks: "Links de referimento",
  copy: "Copiar",
  copiedToClipboard: "Copiado para a área de transferência.",
  copied: "Copiado",
  notActive: "Usuário Inativo",
  balanceCommission: "comissões",
  balanceSale: "vendas",
  message: "Mensagem",
  imAware: "Estou ciente",
  alert: "Alerta",
  notEnabled:
    "Sua conta não pode enviar produtos no momento. Edite o endereço do seu perfil para um endereço válido.",
  inAnalysis: "Seu endereço de coleta de pacotes fornecido está sendo analisado.",
  enabled: "Seu endereço de coleta de pacotes fornecido está apto para enviar produtos.",
  thisIsPickupAddress:
    "Este endereço será usado pelas empresas de transporte para retirar seus pacotes",
  youDonthaveFinancialPassword: "Você não possui uma senha financeira!",
  wantCreateFinancialPassword: "Deseja criar uma senha financeira?",
  passwordCreatedSuccessfully: "Senha criada com sucesso! Por favor verifique seu email!",
  youAlreadyHaveAFinancialPassword: "Você já possui uma senha financeira!",
  youWantToCreateNewFinancialPassword: "Deseja criar uma nova senha financeira?",
  createNewFinancialPassword: "Criar nova senha financeira",
  renewSubscriptionValue: "Valor para renovar sua assinatura",
  referralLinksExplanation:
    "Use estes links para cadastrar novos usuários nos vários planos e aumentar automaticamente seu círculo econômico ganhando comissões e rendas diárias de acordo com nosso plano de compensação.",
  profile: "Perfil",
  activationRequirements: "Para a ativação é necessário 1 cadastro ou 1 compra por mês.",
  safePayment: "Pagamento seguro",
  balanceForExternalAds: "Saldo para anúncios externos",
  youCanUseThisAmountToMakeExternalAds: "Você pode utilizar este saldo para fazer anúncios externos",
};

export default userDashboardHomeDictPT;

