const payPalCheckoutButtonDictES = {
  unexpectedErrorTransaction: 'Error inesperado al registrarse.',
  invalidUserPlan: 'Plan de usuario inválido.',
  emailConfirmationTitle: 'Registrado con éxito!',
  emailConfirmationMessage: 'Revise su correo electrónico para activar su cuenta.',
  upgradedSuccessfully: 'Plan de cuenta actualizado correctamente!',
  upgradedSuccessfullyText: 'Usted deberá ingresar sus credenciales de inicio de sesión nuevamente. Se lo dirigirá a la pantalla de inicio de sesión en 20 segundos, o puede presionar el botón a continuación.',
  youWillBeRedirectedIn: 'Serás redirigido en',
  seconds: 'segundos.',
  noStoresFound: 'No se encontraron tiendas.',
}

export default payPalCheckoutButtonDictES
