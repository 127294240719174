import { AdvancedImage } from "@cloudinary/react";
import React from "react";
import Carousel from "../../../../components/Carousel/Carousel";
import Loading from "../../../../components/Loading/Loading";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { cloudinaryInstance } from "../../../../utilities/Cloudinary/cloudinaryInstance";
import { locale } from "../../../../utilities/locale/locale";

const CategoryTemplate = ({ selectedCategory, category, onClick }) => {
  const descriptions = {
    en: category.descriptionEnglish,
    it: category.descriptionItalian,
    pt: category.descriptionPortuguese,
    es: category.descriptionSpanish,
  };
  const description = descriptions[locale]?.toLowerCase() ?? "";
  const baseClass =
    "category-template flex flex-column gap-2 justify-items-center align-items-center my-auto md:py-5 py-3 md:px-2 cursor-pointer transition-colors transition-duration-500 hover:bg-green-100 border-round-lg h-full w-5rem";

  const handleSelectedCategory = () => {
    onClick({ ...category, descriptionCurrentLocale: description });
  };

  return (
    <div
      className={`${baseClass} ${selectedCategory?.id === category.id ? "bg-green-100" : ""}`}
      onClick={handleSelectedCategory}
    >
      <AdvancedImage
        className="border-round md:h-3rem h-2rem md:w-3rem w-2rem"
        cldImg={cloudinaryInstance.image(category.imageid).format("webp")}
      />
      <p className="text-primary md:text-base text-xs text-center">{description}</p>
    </div>
  );
};

const SectionCategories = ({
  categories,
  loadingCategories,
  selectedCategory,
  handleSelectedCategory,
}) => {
  if (loadingCategories)
    return (
      <Loading
        classNameContainer="surface-0 h-13rem flex align-items-center justify-content-center"
        classNameIcon="text-4xl"
        disableMargin
      />
    );

  return (
    <div className="surface-0">
      <Wrapper classnames="h-13rem surface-0 flex justify-content-center align-items-center">
        <Carousel
          items={categories.map((category) => (
            <CategoryTemplate
              selectedCategory={selectedCategory}
              category={category}
              onClick={handleSelectedCategory}
            />
          ))}
        />
      </Wrapper>
    </div>
  );
};

export default SectionCategories;
