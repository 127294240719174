import { RadioButton } from "primereact/radiobutton";
import localeText from "../../../../locale";
import { locale } from '../../../../utilities/locale/locale';

const SubCategoryRadioButton = ({ subcategory, selectedSubcategory, onSelectedSubcategory }) => {
  const descriptions = {
    en: subcategory.descriptionEnglish,
    it: subcategory.descriptionItalian,
    pt: subcategory.descriptionPortuguese,
    es: subcategory.descriptionSpanish,
  };
  const description = descriptions[locale]?.toLowerCase() ?? "";

  return (
    <div className="flex align-items-center">
      <RadioButton
        className="my-2"
        inputId={subcategory.id}
        value={subcategory}
        checked={selectedSubcategory?.id === subcategory.id}
        onChange={() => onSelectedSubcategory(subcategory)}
      />
      <label htmlFor={subcategory.id} className="ml-2">
        {description}
      </label>
    </div>
  );
};

const SubcategoriesFilter = ({ subcategories, selectedSubcategory, onSelectedSubcategory }) => {
  return (
    <div className="lg:block hidden lg:ml-3 py-4 bg-white border-gray-300 border-solid border-1 border-round justify-self-start min-width">
      <h5 className="px-4">{localeText("subCategoryFilter")}</h5>
      <hr />
      <div className="px-4">
        {subcategories?.length === 0 && <p>{localeText("noSubcategories")}</p>}
        {subcategories?.map((subcategory) => (
          <SubCategoryRadioButton
            key={`subcategory-${subcategory.id}`}
            subcategory={subcategory}
            selectedSubcategory={selectedSubcategory}
            onSelectedSubcategory={onSelectedSubcategory}
          />
        ))}
      </div>
    </div>
  );
};

export default SubcategoriesFilter;
