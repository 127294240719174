export const hasPhoneNumber = (mensagem) => {
  const regexNumeroDeTelefone = /(\+\d{1,3}[\s-]?)?\(?\d{2,4}\)?([\s-]?\d{4,5}){2,}/;
  const regexNumerosConsecutivos = /\d{4,}/;
  const regexNumerosEspacados = /(\d[\s-]?){4,}/;
  // remover todos os espaços e traços
  const formatedMensagem = mensagem.replace(/[\s-]/g, "");

  return (
    regexNumeroDeTelefone.test(formatedMensagem) ||
    regexNumerosConsecutivos.test(formatedMensagem) ||
    regexNumerosEspacados.test(formatedMensagem)
  );
};
