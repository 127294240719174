const productInfoDictES = {
  price: "Precio",
  initialPrice: "Precio inicial",
  percentualOfCredit: "Porcentaje de crédito: ",
  minimumPurchaseAmount: "Valor mínimo de compra",
  noDescription: "No fue registrado por el vendedor o no lo tiene.",
  promocionalPrice: "Precio promocional: ",
  comparePrices: "Comparar precios",
  descriptionProduct: "Descripción",
  characteristics: "Características",
  productReviews: "Reseñas del producto",
  storeReviews: "Reseñas de la tienda",
  showMore: "Ver más",
  thisProductIsYours: "Este producto es tuyo",
  errorThisProductIsYours: "No puedes comprar tu propio producto",
  questionsAndAnswers: "Preguntas y respuestas",
  question: "Pregunta",
  doQuestion: "Haz una pregunta",
  doQuestionToSeller: "Pregunta al vendedor",
  sendQuestion: "Enviar pregunta",
  askQuestionSuccess: "¡Pregunta enviada con éxito!",
  answerQuestionSuccess: "¡Respuesta enviada con éxito!",
  reply: "Responder",
  askQuestionTooLong: "La pregunta debe tener un máximo de 200 caracteres",
  askQuestionEmpty: "La pregunta no puede estar vacía",
  answerQuestionTooLong: "La respuesta debe tener un máximo de 200 caracteres",
  answerQuestionEmpty: "La respuesta no puede estar vacía",
  waitingResponseFromSeller: "Esperando respuesta del vendedor",
  askQuestionHasPhoneNumber: "La pregunta no puede contener número de teléfono",
  answerQuestionHasPhoneNumber: "La respuesta no puede contener número de teléfono",
  freeToItaly: "Envío gratis a Italia",
};

export default productInfoDictES;
