import React from "react";
import localeText from "../../../../../../locale";

const BoxExtracoin = ({ title, description }) => {
  return (
    <div className="flex flex-row gap-3 align-items-start">
      <i className="pi pi-gift bg-white p-3 border-round text-green-600 text-lg shadow-4" />
      <div className="flex flex-column ">
        <h5 className="w-full text-left text-base md:text-lg xl:text-xl text-gray-900 font-bold">
          {localeText(title)}
        </h5>
        <p className="w-full text-base md:text-lg xl:text-xl text-left text-gray-900">
          {localeText(description)}
        </p>
      </div>
    </div>
  );
};

export default BoxExtracoin;
