import React from "react";
import localeText from "../../../../locale";
import { formatDate } from "../../../../utilities/formatDate";

const ErrorCheckout = ({ order, idTransaction }) => {
  return (
    <div className="flex flex-column align-items-center mx-auto" style={{ maxWidth: "400px" }}>
      <h1 className="text-center text-xl mb-4">{localeText("errorCheckout")}</h1>

      <strong>support@extraconomyglobal.com</strong>

      <h4 className="text-lg">{localeText("orderInformation")}</h4>
      <p className="mb-0">
        {localeText("orderId")}: {order?.id}
      </p>
      <p className="mb-0">
        {localeText("orderDate")}: {formatDate(order?.date)}
      </p>
      {idTransaction && <p className="mb-0">TransactionId: {idTransaction}</p>}

      <a href="mailto:support@extraconomyglobal.com" className="underline">
        {localeText("contactEmail")}
      </a>
    </div>
  );
};

export default ErrorCheckout;
