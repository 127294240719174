const planIntroTextDictPT = {
  // Partner Intro
  registerAsBusinessPartner: "Registrar como Partner",
  businessPartnerDescriptionPt1:
    "Um Partner pode oferecer bens e serviços em nossa plataforma. Por uma taxa de inscrição de ",
  businessPartnerDescriptionPt2:
    ", torne-se um Partner e trabalhe conosco para receber os seguintes benefícios:",
  instantlyEarn: "Ganhe instantaneamente ",
  worthOfExtracredit: " de créditos Extracredit (+ ",
  listBusinessPartner1:
    "se você foi um dos primeiros 1000 membros em seu país a se registrar como Partner)",
  listBusinessPartner2: "Obtenha sua vitrine pessoal para oferecer ou receber produtos e serviços",
  businessPartnerLearnMore: " sobre os benefícios de ser um Partner",
  alreadyABusinessPartnerPt1: "Você possui uma empresa? Considere o plano de ",
  alreadyABusinessPartnerPt2: " mais adequado para pessoas jurídicas.",
  // Manager Intro
  registerAsAreaManager: "Registrar como Manager",
  textManager1: "Plano de vendas adquirido: GeoGold",
  textManager2: "Adequado para pessoas jurídicas e grandes empresas",
  textManager3:
    "Com este perfil você estará habilitado a oferecer consultoria e vender e/ou adquirir bens e serviços em nossa plataforma.",
  textManager4: "PERFIL GEOLOCALIZADO",
  textManager5: "Ganha de imediato ",
  textManager6:
    "  de Extrabonus para utilizar como desconto nas diversas ações de marketing que poderá realizar na plataforma para aumentar a sua visibilidade e vendas.",
  textManager7:
    "Obtenha sua própria loja online geolocalizada para oferecer produtos e serviços com anúncios ilimitados",
  textManager8: "Você pode inserir vídeos em seus anúncios de vendas",
  textManager9:
    "Você pode criar automaticamente seu círculo econômico de clientes, vendedores e promotores através de vendas e geolocalização",
  textManager10:
    "Você receberá recompensas por cada atividade realizada no seu círculo econômico e baseada no desenvolvimento territorial",
  textManager11: "Por uma inscrição de apenas",
  textManager12: "você receberá os seguintes benefícios:",
  areaManagerDescriptionPt1:
    "Manager é o plano mais vantajoso e rentável oferecido pela nossa plataforma. Por uma taxa de inscrição de ",
  areaManagerDescriptionPt2:
    ", pode registar a sua empresa para trabalhar conosco e receber os seguintes benefícios:",
  listAreaManager1:
    "se você for um dos primeiros 1000 membros em seu país a registrar sua empresa como Manager)",
  listAreaManager2:
    "Adquira o seu espaço de e-commerce para dar a conhecer e vender os seus produtos e serviços",
  listAreaManager3: "Receba automaticamente novos clientes em seu Círculo Econômico",
  areaManagerLearnMore: "  sobre as muitas vantagens de ser um Manager",
  // Customer Intro
  registerAsSpecialCustomer: "Registrar como Customer",
  specialCustomerDescription: "Comece a comprar bens e serviços enquanto recebe sempre cashback.",
  specialCustomerDescription2:
    "Utilize seus créditos para novas compras ou converta-os em dinheiro ou serviços através do nosso programa de fidelidade Extrapay.",
  specialCustomerDescription3:
    "Baixe nosso aplicativo para se inscrever e receba imediatamente um crédito promocional para usar em suas primeiras compras.",
  listSpecialCustomer1: "Você pode vender!",
  listSpecialCustomer2: "Envie seus primeiros anúncios gratuitos e comece a ganhar!",
  listSpecialCustomer3Pt1: "Baixe nosso App oficial para se cadastrar e ganhar ",
  listSpecialCustomer3Pt2: "de créditos Extracredit gratuitamente.",
  alreadyASpecialCustomer: "Já é um Customer? Considere atualizar seu plano para ",
  or: " ou ",
  taxExplanation: "* Imposto sobre vendas de acordo com seu País.",
  // Promoter Intro
  registerAsSalePromoter: "Registrar como Promoter",
  customerLearnMore: "sobre o Customer",
  // Seller Intro
  registerAsSeller: "Registrar como Seller",
  acquiredSalesPlanStartSeller: "Plano de vendas adquirido: StartSeller",
  desc1Seller:
    "Adequado para pessoas singulares/coletivas, serviços, médias empresas e dropshippers",
  desc2Seller:
    "Com este perfil você poderá vender e/ou adquirir bens e serviços em nossa plataforma.",
  desc3Seller: "Por uma inscrição de apenas",
  desc4Seller: "você receberá os seguintes benefícios:",
  desc5Seller: "Ganha de imediato",
  desc6Seller:
    "de Extrabonus para utilizar como desconto nas diversas ações de marketing que poderá realizar na plataforma para aumentar a sua visibilidade e vendas.",
  desc7Seller:
    "Obtenha seu próprio e-commerce pessoal para oferecer produtos e serviços com anúncios ilimitados",
  desc8Seller: "Você tem um negócio? Considere o perfil",
  desc9Seller: "mais adequado para pessoas jurídicas e grandes empresas.",
  desc10Seller:
    "Você pode criar seu círculo econômico de clientes, vendedores e promotores através de sua atividade de venda e aconselhamento.",
  desc11Seller:
    "A recompensa será recompensada por cada atividade voltada para seu círculo econômico",
  desc12Seller:
    "Este é um PERFIL GEOLOCALIZADO e receberá novos clientes automaticamente. Vir? Cada usuário que se inscrever baixando o aplicativo aumentará o círculo econômico do gerente cada vez mais, aumentando a possibilidade de obter recompensas e acréscimos de crédito.",
  alreadyAsSeller: "Já é um Seller? Considere atualizar seu plano para ",
  AcquiredSalesPlan: "Adquirir um plano de vendas",
  discorverPlus: "sobre todas as oportunidades de ser um Manager!",
  suitableForIndividualsServicesAndSmallBusinesses:
    "Adequado para pessoas físicas, serviços e pequenas empresas",
  descPartner:
    "Com este perfil você estará habilitado a oferecer consultoria e vender e/ou adquirir bens e serviços em nossa plataforma.",
  register1:
    "de Extrabonus para utilizar como desconto nas diversas ações de marketing que poderá realizar na plataforma para aumentar a sua visibilidade e vendas.",
  register2:
    " de Extrabonus para utilizar como desconto nas diversas ações de marketing que poderá realizar na plataforma para aumentar a sua visibilidade e vendas.",
  register3:
    "de Extrabonus para utilizar como desconto nas diversas ações de marketing que poderá realizar na plataforma para aumentar a sua visibilidade e vendas",
  textpartner2:
    "Obtenha sua vitrine pessoal para oferecer produtos e serviços em até 50 anúncios por ano",
  textpartner3:
    "Você pode criar seu próprio círculo econômico de clientes, vendedores e promotores através de suas atividades de vendas e consultoria",
  textpartner4: "Você receberá recompensas por cada atividade realizada em seu círculo econômico",
  NoMonthlyPaymentRequired: "Sem pagamentos mensais",
  AnnualRenewal: "Renovação anual",
  // promoter
  suitableFor:
    "Adequado para pessoas físicas, consultores e afiliados comerciais, pessoas jurídicas",
  promoterResgisterText1:
    "Com este perfil você estará habilitado a oferecer consultoria e desenvolver a rede de empresas e pessoas físicas.",
  promoterResgisterText2: "Na assinatura gratuita você receberá:",
  promoterResgisterText3_1: "Imediatamente",
  promoterResgisterText3_2:
    "de Extracrédito para utilizar como desconto nas suas compras na plataforma.",
  promoterResgisterText4: "Um contrato de representante de vendas.",
  promoterResgisterText5:
    "Você pode criar seu próprio círculo econômico de clientes e vendedores por meio de suas atividades de consultoria",
  promoterResgisterText6:
    "Receba comissões em dinheiro e créditos por cada atividade direta e indireta realizada no seu círculo econômico",
  promoterResgisterText7: "É necessária mensalidade de treinamentos e atividades.",
  promoterResgisterText8: "Não é necessária renovação anual.",
  promoterResgisterText9: "Saiba mais sobre as vantagens de ser um Promotor",
  promoterResgisterText10: "Quer vender também? Considere planos de",
};

export default planIntroTextDictPT;
