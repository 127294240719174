import { Button } from "primereact/button";
import React, { useState } from "react";
import localeText from "../../locale";
import SearchInput from "./Components/SearchInput/SearchInput";

const SearchProducts = ({ isLoading, onSearch, setAsTrue }) => {
  const [expression, setExpression] = useState("");

  return (
    <>
      <div
        className="h-14rem flex flex-row justify-content-center align-items-center px-2"
        style={{ background: "linear-gradient(to right, #ebf4e3, #c0f6e2)" }}
      >
        <div className="flex justify-content-center align-items-center">
          <SearchInput
            expression={expression}
            isLoading={isLoading}
            onSearch={onSearch}
            setExpression={setExpression}
          />
          <Button
            icon={<i className="pi pi-filter sm:p-0 p-1 text-sm sm:mr-1"></i>}
            className="p-3"
            onClick={setAsTrue}
          >
            <span className="sm:inline-block hidden">{localeText("filters")}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default SearchProducts;
