import { useEffect, useState } from "react";
import GetInfoService from "../../../service/GetInfoService";
import handleCurrency from "../../../utilities/CurrencySwitch";

const usePlanPrices = () => {
  const getInfoService = new GetInfoService();

  const [priceBusinessPartner, setPriceBusinessPartner] = useState(0);
  const [priceAreaManager, setPriceAreaManager] = useState(0);
  const [priceSeller, setPriceSeller] = useState(0);

  const [extracoinBusinessPartner, setExtracoinBusinessPartner] = useState(0);
  const [extracoinAreaManager, setExtracoinAreaManager] = useState(0);
  const [extracoinSeller, setExtracoinSeller] = useState(0);

  const [currency, setCurrency] = useState("$");

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      const userCurrency = await getInfoService.getUserCurrency();
      if (cancel) return;

      const currencyDisplay = handleCurrency(userCurrency);
      setCurrency(currencyDisplay);

      const res = await getInfoService.getPlanListByCurrency(currencyDisplay);

      setPriceBusinessPartner(res.result.find((plan) => plan.id === 1).price);
      setPriceAreaManager(res.result.find((plan) => plan.id === 2).price);
      setPriceSeller(parseFloat(res.result.find((plan) => plan.id === 4).price));
      setExtracoinBusinessPartner(
        res.result.find((plan) => plan.id === 1).rewardExtraCoinForRegister
      );
      setExtracoinAreaManager(res.result.find((plan) => plan.id === 2).rewardExtraCoinForRegister);
      setExtracoinSeller(res.result.find((plan) => plan.id === 4).rewardExtraCoinForRegister);
    }

    fetchData();

    return () => {
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    priceBusinessPartner,
    priceAreaManager,
    priceSeller,
    extracoinBusinessPartner,
    extracoinAreaManager,
    extracoinSeller,
    currency,
  };
};

export default usePlanPrices;
