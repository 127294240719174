const confirmDocumentDictPT = {
  confirmID: 'Confirmar documento de identidade',
  confirmDocumentPic: "Considere enviar uma foto do seu documento de identidade para que possamos confirmar sua conta.",
  confirmDocumentPicNow: "Carregue uma foto do seu documento de identidade para desbloquear o site e confirmar sua conta.",
  documentUploadedSuccessfully: 'Foto do documento enviada com sucesso!',
  youCanCloseThisWindow: 'Você pode fechar esta janela agora.',
  photo: 'Foto',
  required: 'obrigatório',
}

export default confirmDocumentDictPT;