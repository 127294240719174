import axios from "axios";
import apiURI from "./Api";

export default class OrderService {
  async postCreateOrder(
    token,
    buyerUsername,
    sellerUsername,
    productId,
    productQty,
    productPercentExtracoin,
    productIdVariation,
    shareToken = null
  ) {
    const bodyJson = {
      buyer: { username: buyerUsername },
      items: [
        {
          product: { id: productId },
          quantity: productQty,
          percentualExtraCoin: productPercentExtracoin,
          idvariation: productIdVariation,
          shareToken: shareToken,
        },
      ],
      seller: { username: sellerUsername },
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/create`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postAddItem(
    token,
    username,
    orderId,
    productId,
    productQty,
    productPercentExtracoin,
    productIdVariation,
    shareToken = null
  ) {
    const bodyJson = {
      buyer: { username: username },
      item: {
        product: { id: productId },
        quantity: productQty,
        percentualExtraCoin: productPercentExtracoin,
        idvariation: productIdVariation,
        shareToken: shareToken,
      },
      orderid: orderId,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/additem`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postDeleteItem(token, username, productId) {
    const bodyJson = {
      buyer: { username: username },
      iditem: productId,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/deleteitem`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postEditItem(token, username, productId, productPercentExtracoin, productQty) {
    const bodyJson = {
      buyer: { username: username },
      iditemorder: productId,
      percentualExtraCoin: productPercentExtracoin,
      quantity: productQty,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/edititem`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  toFixed(num, precision) {
    return (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(precision);
  }

  async chatSendMessage({ userTo, userFrom, idorder, message }) {
    const bodyJson = {
      userTo: { username: userTo },
      userFrom: { username: userFrom },
      idorder: idorder,
      message: message,
    };

    const token = localStorage.getItem("__extraconomy_token");

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/chat/send`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async getChatMessages({ idorder }) {
    const username = localStorage.getItem("__extraconomy_username");

    const response = await axios({
      method: "get",
      url: `${apiURI}/order/chat/getmessagesbyorder/${idorder}/username/${username}`,
      headers: { Authorization: "Bearer " + localStorage.getItem("__extraconomy_token") },
    });

    return response.data;
  }

  async setMessagesViewed({ idchatmessage, userTo }) {
    const bodyJson = {
      idchatmessage,
      userTo: { username: userTo },
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/chat/setmessageasviewed`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + localStorage.getItem("__extraconomy_token") },
    });

    return response.data;
  }

  async setBankTransferReceipt(banktransferReceipt) {
    let data = new FormData();
    data.append("image", banktransferReceipt);

    const response = await axios({
      method: "post",
      url: `${apiURI}/upload/uploadfile`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async getPendingTransfers() {
    const response = await axios({
      method: "get",
      url: `${apiURI}/admin/getpendingregisters`,
      headers: { Authorization: "Bearer " + localStorage.getItem("__extraconomy_token") },
    });

    return response.data;
  }

  async confirmTransferRegisterUser({ username, idorder }) {
    const bodyJson = {
      admin: { username: username },
      idpayment: idorder,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/admin/confirmrregisterbanktransfer`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + localStorage.getItem("__extraconomy_token") },
    });

    return response.data;
  }

  async validateBeforeFinish(
    token,
    username,
    address,
    amountCash,
    amountWallet,
    amountWalletExtracoin,
    amountBankTransfer,
    bankTransferReceipt,
    city,
    country,
    district,
    orderId,
    number,
    postalCode,
    state,
    transactionId
  ) {
    const bodyJson = {
      address: address,
      amountCash: Number(this.toFixed(amountCash, 2)),
      amountWallet: amountWallet,
      amountWalletExtracoin: amountWalletExtracoin,
      amountCryptoExtraCoin: 0,
      amountBankTransfer: parseFloat(amountBankTransfer),
      bankTransferReceipt: bankTransferReceipt,
      buyer: { username: username },
      city: city,
      country: country,
      district: district,
      idorder: orderId,
      number: number,
      postalCode: postalCode,
      state: state,
      transactionid: transactionId,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/validatebeforefinish`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postFinishOrder(
    token,
    username,
    address,
    amountCash,
    amountWallet,
    amountWalletExtracoin,
    amountBankTransfer,
    bankTransferReceipt,
    city,
    country,
    district,
    orderId,
    number,
    postalCode,
    state,
    transactionId
  ) {
    const bodyJson = {
      address: address,
      amountCash: Number(this.toFixed(amountCash, 2)),
      amountWallet: amountWallet,
      amountWalletExtracoin: amountWalletExtracoin,
      amountCryptoExtraCoin: 0,
      amountBankTransfer: parseFloat(amountBankTransfer),
      banktransferReceipt: bankTransferReceipt,
      buyer: { username: username },
      city: city,
      country: country,
      district: district,
      idorder: orderId,
      number: number,
      postalCode: postalCode,
      state: state,
      transactionid: transactionId,
    };
    console.log(bodyJson);
    const response = await axios({
      method: "post",
      url: `${apiURI}/order/finish`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async getPurchases(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/order/getallbybuyer/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async getSales(token, username) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/order/getallbyseller/${username}`,
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }

  async postReceiveOrder(token, username, orderId) {
    const bodyJson = {
      buyer: { username: username },
      idorder: orderId,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/receive`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postSendOrder(token, username, orderId, shippingCompany, trackingObject) {
    const bodyJson = {
      idorder: orderId,
      seller: { username: username },
      shippingcompany: shippingCompany,
      trackingobject: trackingObject,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/send`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async getDownloadLinks(token, username, orderId) {
    const response = await axios({
      method: "get",
      url: `${apiURI}/order/getdownloadable/${orderId}/username/${username}`,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postRateOrder(
    token,
    username,
    comment,
    commentproductorservice,
    orderId,
    ratingProduct,
    ratingSeller
  ) {
    const bodyJson = {
      buyer: { username: username },
      comment: comment,
      commentproductorservice,
      idorder: orderId,
      rateproduct: ratingProduct,
      rateseller: ratingSeller,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/rate`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postAddShipping(
    address,
    city,
    country,
    district,
    house_number,
    postal_code,
    state,
    username,
    orderId,
    sendcloudMethod,
    token,
    priceShipping
  ) {
    const bodyJson = {
      addressJSON: { address, city, country, district, house_number, postal_code, state },
      buyer: { username },
      idorder: orderId,
      idsendcloudmethod: sendcloudMethod,
      priceShipping,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/addshipping`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }

  async postAddPickup(
    address,
    city,
    country,
    district,
    house_number,
    postal_code,
    state,
    username,
    orderId,
    sendcloudMethod,
    token
  ) {
    const bodyJson = {
      address: { address, city, country, district, house_number, postal_code, state },
      buyer: { username },
      idorder: orderId,
      idsendcloudmethod: sendcloudMethod,
    };

    const response = await axios({
      method: "post",
      url: `${apiURI}/order/addpickup`,
      data: bodyJson,
      headers: { Authorization: "Bearer " + token },
    });

    return response.data;
  }
}
