import { useEffect, useState } from "react";
import ProductService from "../service/ProductService";
import useApiStatus from "./useApiStatus";

const useGetCommentsOfProduct = ({ productId, onError }) => {
  const { isLoading, setIsLoading, hasError, setHasError } = useApiStatus();
  const [commentsOfProduct, setCommentsOfProduct] = useState([]);

  const productService = new ProductService();

  const loadComments = async (productId) => {
    try {
      setIsLoading(true);
      const res = await productService.getCommentsOfProduct(productId);
      if (res.messageCode !== 200001) {
        setHasError(true);
        onError(res.messageCode);
      } else {
        setHasError(false);
        setCommentsOfProduct(res.result);
      }
    } catch (e) {
      setHasError(true);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (productId) loadComments(productId); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return {
    isLoading,
    commentsOfProduct,
    hasError,
  };
};

export default useGetCommentsOfProduct;
