const appTopbarDictPT = {
  welcome: "Bem-vindo",
  editProfile: "Editar Perfil",
  logout: "Sair",
  cancelAccount: "Cancelar Conta",
  writeCancelToConfirm: 'Escreva "CANCELAR" para confirmar',
  confirmYourPassword: "Confirme sua senha",
  accountCanceledSuccessfully: "Conta cancelada com sucesso! Você será desconectado em breve.",
  changePassword: "Mudar Senha",
  currentPassword: "Senha atual",
  newPassword: "Senha nova",
  passwordChangedSuccessfully: "Senha atualizada com sucesso!",
  upgradeAccount: "Atualizar Plano",
  upgradeTo: "Atualizar para",
  learnMoreAboutThePlan: "Saiba mais sobre o plano",
  upgradeWithVoucher: "Atualizar plano com voucher",
  including: "incluindo",
};

export default appTopbarDictPT;

