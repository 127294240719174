import { createContext, useContext, useMemo, useState } from "react";

export const TutorialContext = createContext(null);

const initialState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export function TutorialProvider(props) {
  const [tutorialState, setTutorialState] = useState(initialState);

  const value = useMemo(
    () => ({
      tutorialState,
      setTutorialState,
    }),
    [setTutorialState, tutorialState]
  );

  return <TutorialContext.Provider value={value} {...props} />;
}

export function useTutorialContext() {
  const context = useContext(TutorialContext);

  if (!context) {
    throw new Error("useTutorialContext must be used within a AppProvider");
  }

  return context;
}
