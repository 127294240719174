/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { useToggle } from "../../../../hooks/useToggle";
import BoxExtracoin from "./components/BoxExtracoin/BoxExtracoin";
import localeText from "../../../../locale";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";
import voucherService from "../../../../service/VoucherService";
import useApiStatus from "../../../../hooks/useApiStatus";
import { useHistory } from "react-router-dom";
import LandingLaunchPad from "../LandingLaunchPad";

const BENEFITS_TO_BUY_EXTRACOIN = [
  {
    title: "extracoinBenefitTitle1",
    description: "extracoinBenefitDescription1",
  },
  {
    title: "extracoinBenefitTitle2",
    description: "extracoinBenefitDescription2",
  },
  {
    title: "extracoinBenefitTitle3",
    description: "extracoinBenefitDescription3",
  },
];

const LandingExtracoin = () => {
  const {
    value: isVisibleVoucherInput,
    setAsFalse: setNotVisibleVoucherInput,
    setAsTrue: setVisibleVoucherInput,
  } = useToggle();
  const history = useHistory();
  const { isLoading, setIsLoading } = useApiStatus();
  const { showErrorToast } = useToastContext();
  const [voucher, setVoucher] = useState("");

  const handleSubmit = () => {
    if (!voucher) showErrorToast({ detail: "voucherEmptyError" });
    setIsLoading(true);
    voucherService
      .getTypeOfVoucher(voucher)
      .then((res) => {
        if (res?.messageCode !== 200001) {
          showErrorToast({ detail: "extracoinVoucherError" });
          setIsLoading(false);
          return;
        }
        const plan = res?.result?.plan?.description?.toLowerCase();
        setIsLoading(false);

        if (plan.includes("partner"))
          history.push({ pathname: "/businesspartner", state: { voucher } });
        else if (plan.includes("manager"))
          history.push({ pathname: "/areamanager", state: { voucher } });
        else if (plan.includes("seller")) history.push({ pathname: "/seller", state: { voucher } });
        else showErrorToast({ detail: "extracoinVoucherError" });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isVisibleVoucherInput && (
        <Dialog
          header={"Voucher"}
          visible={isVisibleVoucherInput}
          onHide={setNotVisibleVoucherInput}
          breakpoints={{ "960px": "75vw" }}
          draggable={false}
          className="w-30rem mx-2"
        >
          <div className="flex gap-2">
            <InputText
              value={voucher}
              onChange={(e) => setVoucher(e.target.value)}
              className="flex-grow-1"
            />
            <Button
              label={localeText("register")}
              type="submit"
              onClick={handleSubmit}
              loading={isLoading}
            />
          </div>
        </Dialog>
      )}

      <div className="bg-green-100">
        <Wrapper id="extracoinSection" classnames="bg-green-100 pt-6 pb-8 text-center">
          <h1 className="text-4xl lg:text-5xl font-bold text-primary">Extracoin</h1>
          <p className="text-lg lg:text-2xl text-gray-500">
            {localeText("benefitsExtracoinSection")}
          </p>

          <div
            className="flex flex-column lg:flex-row justify-content-center align-items-start gap-6 lg:gap-3 pt-6 mx-auto px-3 md:px-7"
            style={{ maxWidth: 1850 }}
          >
            {BENEFITS_TO_BUY_EXTRACOIN.map((benefit, index) => (
              <BoxExtracoin key={index} title={benefit.title} description={benefit.description} />
            ))}
          </div>

          <LandingLaunchPad />

          <div className="mt-4 mx-4 lg:mt-8 mb-5">
            <a href="#footer" className="hidden text-gray-900" />
            <p className="text-lg lg:text-xl mx-auto mb-4 lg:mb-5">
              {localeText("voucherExtracoinText1")} voucher?{" "}
              <span
                className="text-green-600 font-bold cursor-pointer hover:underline"
                onClick={setVisibleVoucherInput}
              >
                {localeText("clickHere")}
              </span>{" "}
              {localeText("andRegister")}.
            </p>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default LandingExtracoin;
