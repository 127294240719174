const productInfoDictEN = {
  price: "Price",
  initialPrice: "Initial price",
  percentualOfCredit: "Credit percentage: ",
  minimumPurchaseAmount: "Minimum purchase amount",
  noDescription: "It was not registered by the seller or does not have it.",
  promocionalPrice: "Promotional price: ",
  comparePrices: "Compare prices",
  descriptionProduct: "Description",
  characteristics: "Characteristics",
  productReviews: "Product reviews",
  storeReviews: "Store reviews",
  showMore: "Show more",
  thisProductIsYours: "This product is yours",
  errorThisProductIsYours: "You cannot buy your own product",
  questionsAndAnswers: "Questions and answers",
  question: "Question",
  doQuestion: "Ask a question",
  doQuestionToSeller: "Ask the seller",
  sendQuestion: "Send question",
  askQuestionSuccess: "Question sent successfully!",
  answerQuestionSuccess: "Answer sent successfully!",
  reply: "Reply",
  askQuestionTooLong: "The question must be a maximum of 200 characters",
  askQuestionEmpty: "The question cannot be empty",
  answerQuestionTooLong: "The answer must be a maximum of 200 characters",
  answerQuestionEmpty: "The answer cannot be empty",
  waitingResponseFromSeller: "Waiting for seller response",
  askQuestionHasPhoneNumber: "The question cannot contain phone number",
  answerQuestionHasPhoneNumber: "The answer cannot contain phone number",
  freeToItaly: "Free shipping to Italy",
};

export default productInfoDictEN;
