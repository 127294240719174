const registerUserDictPT = {
  referralUsername: "Nome de usuário do referidor",
  nameOfUserRefferalCodeInvalid: "Nome de usuário do referidor inválido.",
  doYouHave: "Você tem um referidor?",
  yes: "Sim",
  no: "Não",
  voucher: "Código do voucher",
  doYouHaveVoucher: "Você tem um voucher?",
  username: "Nome de usuário",
  password: "Senha",
  confirmPassword: "Confirme a senha",
  firstName: "Nome",
  surname: "Sobrenome",
  documentNumber: "CPF",
  documentNumberPJ: "CNPJ",
  selectCurrency: "Selecione uma moeda",
  terms: "Eu aceito os termos e condições.",
  marketingEmail: "Receber emails sobre atualizações e promoções?",
  recaptcha: "Reverificar ReCaptcha",
  signUp: "Registrar",
  proceedToPayment: "Prosseguir para o Pagamento",
  payWithExtracoin: "Pagar com extracoin",
  coupon: "Cupom de registro",
  uploadDocPic: "Carregar foto do documento",
  on: "em",
  salePromoterRefExplanation:
    "Só se pode registrar como Promoter através de um link de referimento de um Manager.",
  // toast
  referralUserNotFound: "Usuário referidor não encontrado.",
  invalidCoupon: "Cupom de crédito inválido.",
  couponDoesNotBelong: "O cupom não pertence a esse usuário referidor.",
  paymentCheckout: "Pagamento",
  phoneConfirmation: "Confirmação por telefone",
  phoneConfirmCodeExplanationPt1: "Enviamos uma mensagem via SMS para",
  phoneConfirmCodeExplanationPt2:
    "com seu código de confirmação, por favor insira o código abaixo para finalizar seu cadastro.",
  phoneConfirmCode: "Código de confirmação",
  pleaseUploadDocPic: "Carregue uma foto do seu documento de identidade",
  shippingRules: "Atenção: Não é possível utilizar a cotação de frete!",
  shippingRulesExplanation:
    "Nosso serviço de envios está disponível apenas usuários que remetem de endereços da Itália!",
  iKnow: "Estou ciente",
  alreadyHaveAccount: "Já tem uma conta?",
  emailCanBeSpam: "Verifique sua caixa de spam, o email pode estar lá.",
  doubts: "Dúvidas?",
  payNow: "Pagar agora",
};

export default registerUserDictPT;
